import { getUserRole } from '../../utils';

export const CircleComponent = ({ contractNumber }: { contractNumber: string }) => {
  const role = getUserRole(contractNumber);
  const circleStyle = {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: role === 'buyer' ? '#e3f2fd' : '#ede7f6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: role === 'buyer' ? '#2196F3' : '#673AB7',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 12,
  };

  return <div style={circleStyle}>{role === 'buyer' ? 'B' : 'S'}</div>;
};
