import ConfirmationDialog from '@common/components/dialog/confirmationDialog';
import { HedgeType, HedgeTypeStringMap } from '../../helpers';

interface HedgeRequestModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  hendgeNumber?: string;
  hedgeType?: HedgeType;
}

export const HedgeRequestModal: React.FC<HedgeRequestModalProps> = ({
  open,
  onClose,
  onConfirm,
  hendgeNumber,
  hedgeType = HedgeType.ACM,
}) => {
  const title = 'Hedge request';
  const content =
    hedgeType === HedgeType.ACM
      ? 'By clicking the "Request a Quote" button, you will not be able to exit the hedging menu. We will send you a price indication within 5 minutes. You need to accept or decline the price indication to finalize the price fixation.'
      : 'After clicking "Place order" button, we will submit your order to the Exchange and notify you.';

  const buttonText = hedgeType === HedgeType.ACM ? 'Request a quote' : 'Place an order';

  const subtitleItems = [
    { key: 'Hedge No.', value: hendgeNumber },
    { key: HedgeTypeStringMap[hedgeType] },
  ];

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={title}
      subtitleItems={subtitleItems}
      content={content}
      buttonText={buttonText}
      onConfirm={onConfirm}
    />
  );
};
