import {
  renderDateOrNone,
  renderEstimatedOrFinalWeight,
  renderHedgingDetails,
  renderValueOrNone,
} from '@app/utils';
import { hedgeSortComparator } from '@app/utils/sortComparators';
import { ShipmentStatusIndicator, StyledTooltip } from '@common/components';
import { OpenInNewOutlined, SaveAltOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';

interface IGenereateShipmentColumns {
  onHandleFileIconClick: (event: React.MouseEvent, shipmentId: string) => void;
  onHandleHedgeIconClick: (event: React.MouseEvent, hedgeId: string) => void;
  hoveredRowId: string | null;
}

export const generateShipmentColumns = ({
  onHandleFileIconClick,
  onHandleHedgeIconClick,
  hoveredRowId,
}: IGenereateShipmentColumns) => {
  const columns: GridColDef[] = [
    {
      field: 'shipment_status',
      headerName: 'Status',
      width: 132,
      renderCell: (params) => <ShipmentStatusIndicator status={params.value} />,
    },
    {
      field: 'ordinal_number',
      headerName: 'Ordinal No.',
      width: 96,
    },

    {
      field: 'shipment_number',
      headerName: 'Shipment No.',
      width: 140,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            width: '132px',
            gap: '5px',
          }}
        >
          <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}>
            {renderValueOrNone(params.value)}
          </Typography>
          {hoveredRowId === params.row.id && params.row.has_files && (
            <StyledTooltip title="Download all documents">
              <IconButton onClick={(event) => onHandleFileIconClick(event, params.row.id)}>
                <SaveAltOutlined fontSize="small" color="secondary" cursor="pointer" />
              </IconButton>
            </StyledTooltip>
          )}
        </Box>
      ),
    },
    {
      field: 'company_hedge',
      headerName: 'Hedging',
      width: 200,
      valueGetter: (params) => params?.row?.company_hedge?.hedge_number,
      sortComparator: hedgeSortComparator,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            width: '200px',
            gap: '4px',
          }}
          onClick={(event) =>
            params.value && onHandleHedgeIconClick(event, params.row.company_hedge.id)
          }
        >
          <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}>
            {renderHedgingDetails(params?.row?.company_hedge)}
          </Typography>
          {hoveredRowId === params.row.id && params.value && (
            <IconButton
              onClick={(event) => onHandleHedgeIconClick(event, params.row.company_hedge.id)}
            >
              <OpenInNewOutlined fontSize="small" color="secondary" />
            </IconButton>
          )}
        </Box>
      ),
    },
    {
      field: 'eta',
      headerName: 'ETA',
      width: 120,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => renderDateOrNone(params.value),
    },
    {
      field: 'company_limit_fixation_date',
      headerName: 'Limit Fix.Date',
      width: 120,
      renderCell: (params) => renderDateOrNone(params.value),
    },

    {
      field: 'final_weight',
      headerName: 'Final Weight, mt',
      width: 120,
      valueGetter: (params) => {
        return params.value ?? params.row.estimated_weight;
      },
      renderCell: (params) => {
        return renderEstimatedOrFinalWeight(params.row.final_weight, params.row.estimated_weight);
      },
    },
    {
      field: 'fixed_exchange',
      headerName: 'Fixed LME, USD/mt',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderValueOrNone(params.value),
    },
    {
      field: 'final_product_price',
      headerName: 'Final Unit Price, USD/mt',
      width: 172,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderValueOrNone(params.value),
    },
    {
      field: 'shipment_value',
      headerName: 'Shipment Value, USD',
      width: 160,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => renderValueOrNone(params.value),
    },
    {
      field: 'claims',
      headerName: 'Claims',
      width: 100,
      renderCell: (params) => renderValueOrNone(params.value),
    },
  ];

  return { columns };
};
