import { Layout } from '@common/components';
import { Outlet } from 'react-router';

export const AccountManager = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
