import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import httpClient from '../../../app/client/httpClient';
import {
  IAutheticationWithEmailPasswordRequest,
  IAutheticationWithEmailPasswordResponseBody,
  IAutheticationWithPhoneCodePutRequest,
} from '../../../app/types/api/authentication';
import { IFetchAuthParams } from '../../../app/types/auth';
import { fetchAuthRole } from '../auth/thunks';
import { setIsFetching } from './slice';

export const fetchAuthWithEmail = createAsyncThunk(
  'fetchAuthWithEmail',
  async (
    {
      data,
      navigate,
      setErrors,
      setSubmitting,
    }: IFetchAuthParams<IAutheticationWithEmailPasswordRequest>,
    { dispatch },
  ) => {
    try {
      dispatch(setIsFetching(true));
      await httpClient.post<IAutheticationWithEmailPasswordResponseBody>('api/signin/email', data);
      await dispatch(fetchAuthRole({ navigate }));
    } catch (e: unknown) {
      const errors = e as AxiosError;

      setErrors({ password: 'Invalid credentials' });
    } finally {
      setSubmitting(false);
    }

    dispatch(setIsFetching(false));
  },
);

export const fetchLogInSendSmsCode = createAsyncThunk(
  'fetchLogInSendSmsCode',
  async (
    {
      data,
      setErrors,
      makeNext,
    }: {
      data: IAutheticationWithPhoneCodePutRequest;
      setErrors: any;
      makeNext?: any;
    },
    { dispatch },
  ) => {
    try {
      dispatch(setIsFetching(true));
      await httpClient.put('api/signin/phone', data);
      if (makeNext) {
        makeNext();
      }
    } catch (error) {
      console.log(error);
      setErrors();
    } finally {
      dispatch(setIsFetching(false));
    }
  },
);
