import { Box, Button, List } from '@mui/material';
import { IShipment } from '@app/types';
import {
  DataListItem,
  DrawerFooter,
  LoadingSkeleton,
  ScrapexSidebarTextH1,
} from '@common/components';
import { AddButton } from '@common/components/display/buttons/addButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import {
  getShipmentsThunk,
  updateShipmentThunk,
} from 'src/accountManager/reducer/shipments/thunks';
import { StatusField } from './statusField';
import { EditInputField } from './editInputField';
import { EditFinalWeight } from './editInputField/EditFinalWeight';
import { FileSelector } from './fileSelector';
import { ConnaissementField } from './connaissementField';
import { PdfFileSelector } from './pdfFileSelector';
import { ShipmentInfoTabs } from './shipmentInfoTabs';
import { PickerWithButtonField } from './limitFixationDate';
import { useGetCurrentShipment } from '../table/hooks/useGetCurrentShipment';
import { getContractThunk } from 'src/accountManager/reducer/contracts/thunks';
import { useState } from 'react';

interface IShipmentInfoDrawerProps {
  onClose: () => void;
  shipmentId: string;
}

const documentsItems: { field: string; title: string }[] = [
  { field: 'quality_certificate', title: 'Quality Certificate' },
  { field: 'packing_list', title: 'Packing List' },
  { field: 'insurance_certificate', title: 'Insurance Certificate' },
  { field: 'survey_report', title: 'Survey Report' },
];

export const ShipmentInfoDrawer = ({ onClose, shipmentId }: IShipmentInfoDrawerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedTab, setSelectedTab] = useState(0);
  const contract = useSelector(
    (state: RootState) => state.accountManager.contracts.currentContract,
  );
  const { shipment, isShipmentLoading } = useGetCurrentShipment(shipmentId);

  const handleUpdateShipment = async (value: string | string[] | number, field: string) => {
    if (contract && shipment) {
      await dispatch(updateShipmentThunk({ shipmentId: shipment.id, data: { [field]: value } }));
      dispatch(getShipmentsThunk(contract.id));

      //temporary solution for updating completed shipments field in the contract header
      if (field === 'shipment_status') {
        dispatch(getContractThunk({ id: shipment.deal_id }));
      }
    }
  };

  return (
    <>
      {isShipmentLoading && <LoadingSkeleton />}
      {!isShipmentLoading && shipment && (
        <>
          <Box padding="0 40px 100px 40px">
            <ScrapexSidebarTextH1>Shipment Info</ScrapexSidebarTextH1>
            <DataListItem title="Contract No.">{contract?.seller_contract_number}</DataListItem>
            <List sx={{ mb: '20px' }}>
              <DataListItem title="Shipment Status">
                <StatusField
                  initialStatus={shipment.shipment_status}
                  onHandleUpdateShipment={(value) => {
                    handleUpdateShipment(value, 'shipment_status');
                  }}
                />
              </DataListItem>
              <DataListItem title="Ordinal No.">{shipment.ordinal_number}</DataListItem>
              <DataListItem title="Shipment No.">
                <EditInputField
                  value={shipment?.shipment_number}
                  onSubmit={(newValue) => handleUpdateShipment(newValue, 'shipment_number')}
                />
              </DataListItem>

              <DataListItem title="Final Weight, mt">
                <EditFinalWeight
                  isFinalWeight={!!shipment?.final_weight}
                  estimatedWeight={shipment?.estimated_weight}
                  value={
                    shipment?.final_weight ? shipment?.final_weight : shipment?.estimated_weight
                  }
                  onSubmit={(newValue) => handleUpdateShipment(newValue, 'final_weight')}
                />
              </DataListItem>
              <DataListItem title="ETA">
                <PickerWithButtonField
                  initialDate={shipment?.eta}
                  onHandleUpdateShipment={(date) => {
                    handleUpdateShipment(date, 'eta');
                  }}
                />
              </DataListItem>
              <DataListItem title="Photos" hideDivider>
                <FileSelector
                  key="photos"
                  dealId={contract?.id}
                  folder={`${shipment.id}-photos`}
                  title="Photos"
                  type="photo"
                  fileIdsProp={shipment?.photos}
                  onHandleUpdateShipment={(filesIds) => {
                    handleUpdateShipment(filesIds, 'photos');
                  }}
                />
              </DataListItem>
              <ScrapexSidebarTextH1>Claims</ScrapexSidebarTextH1>
              <DataListItem title="Claim" hideDivider>
                <AddButton />
              </DataListItem>
              <ScrapexSidebarTextH1>Documents</ScrapexSidebarTextH1>
              <ConnaissementField
                shipmentId={shipment.id}
                contractId={contract?.id}
                initialConnaissementType={shipment.connaissement_type}
                connaissement={shipment.connaissement}
                onHandleUpdateShipment={(value, field) => {
                  handleUpdateShipment(value, field);
                }}
              />
              {documentsItems.map((item, index) => (
                <DataListItem key={index} title={item.title}>
                  <PdfFileSelector
                    onPdfIdSelect={(pdfId) => {
                      handleUpdateShipment(pdfId, item.field);
                    }}
                    dealId={contract?.id}
                    folder={`${shipment.id}-docs`}
                    fileIdProp={shipment[item.field as keyof IShipment] as string}
                  />
                </DataListItem>
              ))}
              <DataListItem title="Other" hideDivider>
                <FileSelector
                  key="other"
                  dealId={contract?.id}
                  folder={`${shipment.id}-other`}
                  title="Other"
                  type="files"
                  fileIdsProp={shipment?.other_files}
                  onHandleUpdateShipment={(filesIds) => {
                    handleUpdateShipment(filesIds, 'other_files');
                  }}
                />
              </DataListItem>
              <ShipmentInfoTabs
                onHandleUpdateShipment={(filesIds, field) => {
                  handleUpdateShipment(filesIds, field);
                }}
                shipment={shipment}
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
              />
            </List>
          </Box>
          <DrawerFooter>
            <Button variant="contained" onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </>
      )}
    </>
  );
};
