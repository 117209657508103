import { combineReducers } from 'redux';
import { reducer as companyReducer } from './company/slice';
import contracts from './contracts/slice';
import shipments from './shipments/slice';
import hedging from './hedging/slice';
import notifications from './notifications/slice';
import market from './market/slice';

export const reducer = combineReducers({
  companyReducer,
  contracts,
  shipments,
  hedging,
  notifications,
  market,
});
