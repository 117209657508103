import React from 'react';
import { Box, Button } from '@mui/material';
import { ScrapexGrayText } from '@common/components';
import { NotificationItem } from '../notificationItem';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  readAllHedgeNotificationsThunk,
  readAllOtherNotificationsThunk,
} from 'src/account/reducer/notifications/thunk';
import { INotification } from '@app/types/api';

interface NotificationTabsProps {
  tabValue: number;
  hedgeNotifications: INotification[];
  otherNotifications: INotification[];
  notificationsHistory: INotification[];
}

export const NotificationTabsContent: React.FC<NotificationTabsProps> = ({
  tabValue,
  hedgeNotifications,
  otherNotifications,
  notificationsHistory,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleReadAllHedge = () => {
    dispatch(readAllHedgeNotificationsThunk());
  };
  const handleReadAllOther = () => {
    dispatch(readAllOtherNotificationsThunk());
  };

  const EmptyQueue = () => {
    return (
      <Box m="50% auto" textAlign="center">
        Your queue is empty! You are all set
      </Box>
    );
  };

  const tabs = [
    {
      content: (
        <>
          {!hedgeNotifications.length ? (
            <EmptyQueue />
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  m: '16px 0',
                  alignItems: 'center',
                  p: '0 16px',
                }}
              >
                <ScrapexGrayText>UNREAD</ScrapexGrayText>
                <Button variant="text" onClick={handleReadAllHedge}>
                  <ScrapexGrayText>Mark all as read</ScrapexGrayText>
                </Button>
              </Box>
              {hedgeNotifications.map((notification) => (
                <NotificationItem notification={notification} key={notification.id} />
              ))}
            </>
          )}
        </>
      ),
    },
    {
      content: (
        <>
          {!otherNotifications.length ? (
            <EmptyQueue />
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  m: '16px 0',
                  alignItems: 'center',
                  p: '0 16px',
                }}
              >
                <ScrapexGrayText>UNREAD</ScrapexGrayText>
                <Button variant="text" onClick={handleReadAllOther}>
                  <ScrapexGrayText>Mark all as read</ScrapexGrayText>
                </Button>
              </Box>
              {otherNotifications.map((notification) => (
                <NotificationItem notification={notification} key={notification.id} />
              ))}
            </>
          )}
        </>
      ),
    },
    {
      content: (
        <>
          {notificationsHistory.map((notification) => (
            <NotificationItem notification={notification} key={notification.id} isHistory={true} />
          ))}
        </>
      ),
    },
  ];

  return (
    <Box
      sx={{
        height: '620px',
        width: '540px',
        overflowY: 'auto',
      }}
    >
      {tabs[tabValue].content}
    </Box>
  );
};
