import { SidebarContent } from './components/content';
import classes from './styles.module.scss';

export const Sidebar = () => {
  return (
    <aside className={classes.sidebar}>
      <SidebarContent />
    </aside>
  );
};
