import { Box, Button, List, OutlinedInput, SxProps } from '@mui/material';
import { CompanyStatus, ICompany, ICompanyInfo } from '@app/types';
import {
  DataListItem,
  ScrapexSidebarTextH1,
  DrawerFooter,
  outlinedInputStyles,
} from '@common/components';
import { CompanyStatusIndicator } from '../status';
import { useState } from 'react';
import DeleteCompanyModal from '../confirmationModals/deleteCompanyModal';
import ResetPasswordModal from '../confirmationModals/resetPasswordModal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  deleteCompanyThunk,
  getCompaniesThunk,
  updateCompanyThunk,
} from 'src/accountManager/reducer/companies/thunks';
import { useFormik } from 'formik';
import { FormErrorSubtitle } from '@common/components/drawer/formErrorSubtitle';
import { validationSchema } from '../addCompanyDrawer/validationSchema';
import { useGetCompanyInfo } from '../companyInfoDrawer/hooks/useGetCompanyInfo';

const formValuesMap = {
  name: 'name',
  email: 'email',
};
interface EditCompanyDrawerProps {
  onClose: () => void;
  companyId: string;
}

export const EditCompanyDrawer = ({ onClose, companyId }: EditCompanyDrawerProps) => {
  const { currentCompany, isCompanyLoading } = useGetCompanyInfo(companyId);
  const [deleteCompanyModalOpen, setDeleteCompanyModalOpen] = useState(false);
  const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  if (!currentCompany) return null;

  const { company, deals } = currentCompany;

  const initialValues: ICompanyInfo = {
    name: company.name,
    email: company.email,
    invitation_date: '',
  };

  const hasContracts = deals?.length > 0;

  const handleDeleteCompany = async () => {
    try {
      await dispatch(deleteCompanyThunk(company.id));

      dispatch(getCompaniesThunk());

      setDeleteCompanyModalOpen(false);
      onClose();
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };

  const handleResetPassword = () => {
    setResetPasswordModalOpen(false);
  };

  const generatePasswordHandler = () => {};

  const onSubmit = async (data: ICompanyInfo) => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0];
      const hasEmailChanged = data.email !== company.email;

      const completeData = {
        ...data,
        invitation_date: hasEmailChanged ? formattedDate : company.invitation_date,
        id: company.id,
        company_status: company.company_status,
      };
      await dispatch(updateCompanyThunk({ id: company.id, updatedData: completeData }));

      await dispatch(getCompaniesThunk());
      onClose();
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <>
      {company && (
        <>
          <form onSubmit={handleSubmit} id="company">
            <Box padding="0 40px 0 40px">
              <ScrapexSidebarTextH1>Edit Company Info</ScrapexSidebarTextH1>
              <List>
                <DataListItem title="Company Status">
                  <CompanyStatusIndicator status={company.company_status} />
                </DataListItem>
                <DataListItem
                  title="Company Name*"
                  subtitle={<FormErrorSubtitle errors={errors.name} touched={touched.name} />}
                >
                  <OutlinedInput
                    placeholder="Enter company name"
                    sx={outlinedInputStyles(!!errors.name && !!touched.name)}
                    name={formValuesMap.name}
                    value={values.name}
                    onChange={handleChange}
                  />
                </DataListItem>
                <DataListItem
                  title="Company Email*"
                  subtitle={<FormErrorSubtitle errors={errors.email} touched={touched.email} />}
                >
                  <OutlinedInput
                    placeholder="Enter company email"
                    sx={outlinedInputStyles(!!errors.email && !!touched.email)}
                    type="email"
                    name={formValuesMap.email}
                    value={values.email}
                    onChange={handleChange}
                    // disabled
                  />
                </DataListItem>
                <DataListItem hideDivider title="Password" alignChildren="end">
                  <Button
                    variant="outlined"
                    color="warning"
                    sx={{
                      lineHeight: '24px',
                      padding: '5px 16px',
                      height: 'auto',
                      fontSize: '14px',
                      fontWeight: 600,
                    }}
                    onClick={() => setResetPasswordModalOpen(true)}
                    disabled
                  >
                    RESET PASSWORD
                  </Button>
                </DataListItem>
              </List>
            </Box>
          </form>
          <DrawerFooter>
            <Button
              variant="outlined"
              color="error"
              sx={{ marginRight: '16px' }}
              onClick={() => setDeleteCompanyModalOpen(true)}
              disabled={company.company_status !== CompanyStatus.Frozen || hasContracts}
            >
              REMOVE COMPANY
            </Button>
            <Button variant="contained" type="submit" form="company">
              Save
            </Button>
          </DrawerFooter>
          <DeleteCompanyModal
            open={deleteCompanyModalOpen}
            onClose={() => setDeleteCompanyModalOpen(false)}
            onConfirm={handleDeleteCompany}
            companyName={company.name}
          />
          <ResetPasswordModal
            open={resetPasswordModalOpen}
            onClose={() => setResetPasswordModalOpen(false)}
            onConfirm={handleResetPassword}
            companyName={company.name}
          />
        </>
      )}
    </>
  );
};
