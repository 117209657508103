import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assocPath, pipe } from 'ramda';
import type { RootState } from '../../../app/store';
import { IFetchStateSettings } from '../../../app/types/api/fetchSettings';
import { ISignUpForm, ISignupState, SignUpNavigation } from '../../../app/types/auth';

// Define a type for the slice state

// Define the initial state using that type

const initialState: ISignupState & IFetchStateSettings = {
  form: {
    email: '',
    password: '',
    firstname: '',
    lastname: '',
  },
  phone: '',
  stateView: SignUpNavigation.PERSONAL_INFORMATION,
  isPassword: false,
  fetchError: null,
  isFetching: false,
};

export const enum MakeStateView {
  NEXT = 'NEXT',
  BACK = 'BACK',
}

export const { actions, reducer } = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setStateView(state, { payload }: PayloadAction<MakeStateView>): void {
      if (payload === MakeStateView.NEXT) {
        state.stateView += 1;
      } else if (payload === MakeStateView.BACK) {
        state.stateView -= 1;
      }
    },
    setPasswordState(state): void {
      state.isPassword = !state.isPassword;
    },
    setPhone(state, { payload }: PayloadAction<string>) {
      state.phone = payload;
    },
    setInitialForm(state, { payload }: PayloadAction<ISignUpForm>) {
      state.form = pipe<any, ISignUpForm, ISignUpForm, ISignUpForm>(
        assocPath(['email'], payload.email),
        assocPath(['firstname'], payload.firstname),
        assocPath(['lastname'], payload.lastname),
      )(state.form);
    },
    setIsFetching(state, { payload }: PayloadAction<boolean>) {
      state.isFetching = payload;
    },
    reset(): ISignupState & IFetchStateSettings {
      return initialState;
    },
  },
});

const objectSlice = {
  actions,
  reducer,
};

export const { reset, setInitialForm, setIsFetching, setPasswordState, setPhone, setStateView } =
  objectSlice.actions;

export default objectSlice;
