import { Tooltip, TooltipProps } from '@mui/material';

export const StyledTooltip = ({ title, children }: TooltipProps) => {
  return (
    <Tooltip
      title={title}
      placement="top"
      componentsProps={{ tooltip: { sx: { backgroundColor: '#0C2452', color: '#fff' } } }}
    >
      {children}
    </Tooltip>
  );
};
