import { AccountManagerRoutes } from '@app/types/navigation';
import { OpenInNewOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { BaseSyntheticEvent } from 'react';
import { useNavigate } from 'react-router';

export const NavigateIconButton = ({ id }: { id: string }) => {
  const navigate = useNavigate();
  const handleNavigateToContract = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    navigate(
      `/${AccountManagerRoutes.ACCOUNT_MANAGER}/${AccountManagerRoutes.CONTRACTS_DASHBOARD}/${id}`,
    );
  };
  return (
    <IconButton onClick={handleNavigateToContract} sx={{ padding: '4px' }}>
      <OpenInNewOutlined sx={{ fontSize: '18px' }} />
    </IconButton>
  );
};
