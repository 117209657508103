import { IContractAdmin } from '@app/types';

export const contractsRowData = (contracts: IContractAdmin[]) => {
  const rows: any = contracts.map((contract) => ({
    ...contract,
    id: contract.id,
  }));

  return { rows };
};
