import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { HedgingStatus, IHedge } from '@app/types';
import { ArrowForward } from '@mui/icons-material';
import { CountdownTimer } from '@common/components';
import { formatDate, formatTime } from '@app/utils';
import { HedgeTabType, HedgeType } from '../../helpers';
import dayjs, { Dayjs } from 'dayjs';

interface HedgeRequestDrawerFooterProps {
  hedge: IHedge | null;
  selectedTab: HedgeTabType;
  tpExchangeLevel: string;
  orderType: string;
  onClose?: () => void;
  setHedgeRequestModalOpen: (open: boolean) => void;
  setHedgeRejectModalOpen: (open: boolean) => void;
  handleTimerExpired: () => void;
  handleQuotationAccept: () => void;
  ringDate: Dayjs | null;
}

export const HedgeRequestDrawerFooter: React.FC<HedgeRequestDrawerFooterProps> = ({
  hedge,
  selectedTab,
  orderType,
  tpExchangeLevel,
  ringDate,
  onClose,
  setHedgeRequestModalOpen,
  setHedgeRejectModalOpen,
  handleTimerExpired,
  handleQuotationAccept,
}) => {
  const isPlaceOrderDisabled =
    orderType === HedgeType.TakeProfit
      ? parseFloat(tpExchangeLevel) <= 0 || tpExchangeLevel === ''
      : !ringDate || !ringDate.isValid() || ringDate.isBefore(dayjs(), 'day');

  switch (hedge?.hedge_status) {
    case HedgingStatus.None:
      return (
        <Box display="flex" gap={2}>
          <Button variant="outlined" color="error" onClick={onClose}>
            Cancel
          </Button>
          {selectedTab === HedgeTabType.ACM ? (
            <Button
              variant="contained"
              onClick={() => setHedgeRequestModalOpen(true)}
              endIcon={<ArrowForward />}
            >
              Request a quote
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => setHedgeRequestModalOpen(true)}
              endIcon={<ArrowForward />}
              disabled={isPlaceOrderDisabled}
            >
              Place an order
            </Button>
          )}
        </Box>
      );
    case HedgingStatus.Waiting:
      return (
        <Box display="flex" width="100%" justifyContent="flex-start">
          <Typography>
            Waiting for a quote{' '}
            <CountdownTimer
              deadline={hedge.quotation_deadline}
              onTimerExpired={handleTimerExpired}
            />
          </Typography>
        </Box>
      );
    case HedgingStatus.WaitingTP:
    case HedgingStatus.WaitingOLME:
      return (
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button variant="outlined" color="error" onClick={() => setHedgeRejectModalOpen(true)}>
            Reject Order
          </Button>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Box>
      );
    case HedgingStatus.AtWorkOLME:
    case HedgingStatus.AtWork:
      return (
        <Box display="flex" width="100%" justifyContent="space-between">
          <Button variant="outlined" color="error" onClick={() => setHedgeRejectModalOpen(true)}>
            Reject Order
          </Button>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Box>
      );
    case HedgingStatus.Quotation:
      return (
        <Box display="flex" alignItems={'center'} gap={7}>
          <Box>
            <Typography>
              You have{' '}
              <CountdownTimer deadline={hedge.deadline} onTimerExpired={handleTimerExpired} /> to
              make a decision
            </Typography>
          </Box>
          <Box display="flex" gap={2}>
            <Button variant="outlined" color="error" onClick={() => setHedgeRejectModalOpen(true)}>
              Reject
            </Button>
            <Button variant="contained" onClick={handleQuotationAccept} endIcon={<ArrowForward />}>
              Confirm
            </Button>
          </Box>
        </Box>
      );
    case HedgingStatus.Confirmed:
      return (
        <Box display="flex" alignItems={'center'} width="100%" justifyContent="space-between">
          <Box width="70%">
            <Typography>
              You've confirmed the Сurrent LME Offer, we'll notify you with the final fixation
              price.
            </Typography>
          </Box>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Box>
      );
    case HedgingStatus.Filled:
    case HedgingStatus.FilledTP:
    case HedgingStatus.FilledOLME:
      return (
        <Box display="flex" alignItems={'center'} width="100%" justifyContent="space-between">
          <Typography>
            You completed the hedge on {formatDate(hedge.filled_time)} at{' '}
            {formatTime(hedge.filled_time)}
          </Typography>
          <Button variant="contained" onClick={onClose}>
            Done
          </Button>
        </Box>
      );
    default:
      return (
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      );
  }
};
