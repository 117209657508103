import { RootState } from '@app/store';
import { Person } from '@mui/icons-material';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';

export const LayoutProfile = () => {
  const profileInfo = useSelector((state: RootState) => state.auth.auth);
  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        sx={{
          mr: 2,
          width: 40,
          height: 40,
          borderRadius: '50%',
          bgcolor: '#bdbdbd',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Person />
      </Box>
      <Box>
        {profileInfo.company_name}
        <br />
        {profileInfo.email}
      </Box>
    </Box>
  );
};
