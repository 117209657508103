import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { PdfFileSelector } from 'src/accountManager/contract/components/shipments/components/shipmentInfoDrawer/pdfFileSelector';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  deleteInvoiceThunk,
  getShipmentThunk,
  updateInvoiceThunk,
} from 'src/accountManager/reducer/shipments/thunks';
import { InvoiceInputLabel } from '../shared/InvoiceInputLabel';
import { IShipmentInvoice } from '@app/types';
import { DialogTitleModal, FormErrorSubtitle, OutlinedSelect } from '@common/components';

interface EditInvoiceModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  dealId: string;
  shipmentId: string;
  invoice: IShipmentInvoice;
}

const outlinedInputStyles = () => {
  return {
    input: {
      lineHeight: '20px',
      height: 'auto',
      padding: '8px !important',
    },
    fieldset: {
      border: `1px solid 'var(--other-300)'`,
    },
  };
};

const menuList = [
  { value: 'invoice', label: 'Invoice' },
  { value: 'proforma', label: 'Proforma' },
  { value: 'provisional', label: 'Provisional' },
  { value: 'final', label: 'Final' },
  { value: 'debit_note', label: 'Debit note' },
  { value: 'credit_note', label: 'Credit note' },
];

export const EditInvoiceModal: React.FC<EditInvoiceModalProps> = ({
  open,
  onClose,
  dealId,
  shipmentId,
  invoice,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const initialValues = {
    type: invoice?.type,
    date: invoice?.date,
    doc: invoice?.doc,
    id: invoice?.id,
  };

  const validationSchema = Yup.object({
    type: Yup.string().required('Required'),
    date: Yup.date().required('Required'),
    doc: Yup.string().required('Required'),
  });

  const onSubmit = async (values: IShipmentInvoice) => {
    try {
      const updatedData = {
        type: values.type,
        date: values.date,
        doc: values.doc,
        id: invoice.id,
      };

      await dispatch(updateInvoiceThunk({ invoiceId: invoice.id, invoiceData: updatedData }));

      await dispatch(getShipmentThunk({ shipmentId }));

      onClose();
    } catch (err) {
      console.error('Update failed:', err);
    }
  };

  const handleDeleteInvoice = async () => {
    try {
      await dispatch(deleteInvoiceThunk(invoice.id));
      await dispatch(getShipmentThunk({ shipmentId }));
      onClose();
    } catch (error) {
      console.error('Error deleting invoice:', error);
    }
  };

  const { handleSubmit, handleChange, handleBlur, values, setFieldValue, errors, touched } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit,
    });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 588,
          width: 588,
          minHeight: 360,
        },
      }}
    >
      <DialogTitleModal title="Edit Invoice" onClose={onClose} />

      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ paddingTop: 0 }}>
          <InvoiceInputLabel text="Invoice Type" />
          <OutlinedSelect
            sx={outlinedInputStyles}
            placeholder="Select type"
            menuList={menuList}
            id={''}
            label={''}
            name={'type'}
            onBlur={handleBlur}
            value={values.type}
            onChange={handleChange}
          />
          <FormErrorSubtitle errors={errors.type} touched={touched.type} />

          <InvoiceInputLabel text="Invoice Date" />
          <TextField
            sx={outlinedInputStyles}
            type="date"
            name="date"
            fullWidth
            value={values.date}
            onChange={handleChange}
            onBlur={handleBlur}
            InputLabelProps={{ shrink: true }}
          />
          <FormErrorSubtitle errors={errors.type} touched={touched.type} />

          <InvoiceInputLabel text="Selected Document" />
          <PdfFileSelector
            onPdfIdSelect={(pdfId) => {
              setFieldValue('doc', pdfId);
            }}
            dealId={dealId}
            folder={`${shipmentId}-invoices`}
            hasRemoveFile={false}
            fileIdProp={invoice?.doc}
          />
        </DialogContent>

        <DialogActions sx={{ p: '24px' }}>
          <Button type="submit" variant="outlined" color="error" onClick={handleDeleteInvoice}>
            Remove Invoice
          </Button>
          <Button type="submit" variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
