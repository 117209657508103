import { IContractAdmin } from './contracts';

export interface IAddress {
  country: string;
  state: string;
  city: string;
  postal: string;
  address1: string;
  address2?: string;
}

export interface IContact {
  email: string;
  phone: string;
  phone2: string;
}

export enum IColleagueRole {
  TRADER = 'trader',
  BACKOFFICE = 'backoffice',
  CHOSE = 'chose',
}

export interface IColleague {
  email: string;
  role: IColleagueRole;
}

export interface ITeam {
  members: IColleague[];
  documents: string;
}

export interface IBankDetails {
  name: string;
  country: string;
  address: IAddress;
  account_number: string;
  details: string;
}

export interface IMainInfo {
  name: string;
  registration_code: string;
  vat_id: string;
  address: IAddress;
  contact: IContact;
}

export interface ICompanyRegistrationState {
  mainInfoForm: IMainInfo;
  bankDetailsForm: IBankDetails;
  teamForm: ITeam;
  emailTeamInput: string;
  KYC: string[];
}

export interface IAddressWithCountryName {
  country: {
    code?: string;
    name?: string;
  };
  state: string;
  city: string;
  postal: string;
  address1: string;
  address2: string;
}

export interface ICompanyToDisplayState {
  id: string;
  name: string;
  registration_code: string;
  vat_id: string;
  bank_details: {
    name: string;
    country: string;
    address: IAddressWithCountryName;
    account_number: string;
    details: string;
  };
  draft: boolean;
  kyc: string[];
  team: {
    members: IColleague[];
    documents: string;
  };
  address: IAddressWithCountryName;
}

export interface ICompanyRegistrationResponse extends IMainInfo {
  id: string;
  bank_details: IBankDetails;
  team: { members: IColleague[]; documents: string };
  kyc: string[];
  draft: boolean;
}

export interface ICompanyResponseWithId {
  id: string;
  name: string;
  registration_code: string;
  vat_id: string;
  address: IAddress;
}

export interface ICompanyInfo {
  name: string;
  email: string;
  invitation_date: string;
}

export enum CompanyStatus {
  Invited = 'invited',
  Active = 'active',
  Frozen = 'frozen',
  none = 'none',
}

export interface ICompany extends ICompanyInfo {
  company_status: CompanyStatus;
  invitation_date: string;
  registration_date: string;
  number_of_contracts: number;
  id: string;
}

export interface IGetCompanyByIdResponse {
  company: ICompany;
  deals: IGetCompanyDeal[];
}

interface IGetCompanyDeal {
  contract_number: string;
  id: string;
}
export interface ICreateCompanyResponse {
  company: ICompany;
  password: string;
}
