import { Box, TextField } from '@mui/material';
import { BaseSyntheticEvent } from 'react';
import { InputContainer } from '../components/inputContainer';

interface InputGeneralProps {
  name: string;
  value?: string;
  onChange?: (value: BaseSyntheticEvent) => void;
  label?: string;
  error?: string;
  touched?: boolean;
  fullWidth?: boolean;
  type?: string;
}

export const InputControlled = ({
  name,
  label,
  value,
  onChange,
  touched,
  error,
  type = 'text',
  fullWidth = false,
}: InputGeneralProps) => {
  return (
    <InputContainer>
      <label htmlFor={name}>{label}</label>
      <TextField
        type={type}
        id={name}
        name={name}
        onChange={onChange}
        value={value}
        fullWidth={fullWidth}
      />
      {touched && error && <p>{error}</p>}
    </InputContainer>
  );
};
