import { Link } from 'react-router-dom';
import logo from '@app/assets/img/logo-mono-color.png';
import classes from './styles.module.scss';
import { SidebarNavigation } from '../navigation';
import { Box, Typography } from '@mui/material';

export const SidebarContent = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className={classes.content}>
      <Box>
        <Typography sx={{ fontSize: 24, color: '#fff', fontWeight: 700, lineHeight: '24px' }}>
          Scrapex
        </Typography>
      </Box>
      <SidebarNavigation />
    </div>
  );
};
