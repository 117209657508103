import httpClient from '@app/client/httpClient';
import { useState } from 'react';

interface UseUploadResult {
  loading: boolean;
  error: Error | null;
  upload: (file: File) => Promise<string>;
}

export const useUploadFiles = (): UseUploadResult => {
  //TODO: refactor this hook for uploading shipments documents too.
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const upload = async (file: File): Promise<string> => {
    try {
      setLoading(true);

      const data = new FormData();
      data.append('upload', file);

      const response = await httpClient.post<{ id: string }>('api/upload', data);
      const uploadedId = response.data.id;

      return uploadedId;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, upload };
};
