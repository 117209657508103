import { RootState } from '@app/store';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import { Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';

const styles = {
  widget: {
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
  },
  headerRow: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 8,
  },
  dataRow: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 4,
  },
  label: {
    fontSize: 12,
    color: '#8592A8',
  },
  value: {
    fontWeight: 500,
  },
};

export const ScrapexWidget = () => {
  const { previousMarketData, marketData } = useSelector(
    (state: RootState) => state.account.market,
  );

  const prices = marketData?.prices ?? [];
  const previousPrices = previousMarketData?.prices ?? [];

  const priceData = prices[0] || {};
  const prevPriceData = previousPrices[0] || {};

  const { ask, bid, metal } = priceData;
  const { ask: prevAsk, bid: prevBid } = prevPriceData;

  const bidChange = (bid ?? 0) - (prevBid ?? 0);
  const askChange = (ask ?? 0) - (prevAsk ?? 0);

  return (
    <Box sx={styles.widget}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection={'column'}>
          <Typography variant="body2" sx={styles.label}>
            Exchange
          </Typography>
          <Typography variant="body2" sx={styles.value}>
            LME <span style={{ color: '#8592A8' }}>(3-month)</span>
          </Typography>
        </Box>
        <Box display="flex" flexDirection={'column'}>
          <Typography variant="body2" sx={styles.label}>
            Metal
          </Typography>
          <Typography variant="body2" sx={styles.value}>
            {metal ?? '-'}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="end">
          <Typography variant="body2" sx={styles.label}>
            Bid, USD/mt
          </Typography>
          <Box display="flex" alignItems="end">
            <Typography variant="body2" sx={styles.value}>
              {bid ?? '-'}
            </Typography>
            {bidChange > 0 && <TrendingUp color="success" fontSize="small" />}
            {bidChange < 0 && <TrendingDown color="error" fontSize="small" />}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="end">
          <Typography variant="body2" sx={styles.label}>
            Ask, USD/mt
          </Typography>

          <Box display="flex">
            <Typography variant="body2" sx={styles.value}>
              {ask ?? '-'}
            </Typography>
            {askChange > 0 && <TrendingUp color="success" fontSize="small" />}
            {askChange < 0 && <TrendingDown color="error" fontSize="small" />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
