import { createAsyncThunk } from '@reduxjs/toolkit';
import { IContractAdmin, ICreateContract } from '../../../app/types/contracts';
import {
  getContracts,
  createContract,
  getContract,
  deleteContract,
  updateContract,
} from '../../../app/api/contracts';

export const getContractsThunk = createAsyncThunk('getContracts', async () => {
  try {
    const res = await getContracts();
    return { contracts: res.data };
  } catch (error) {
    console.log(error);
  }
});

export const getContractThunk = createAsyncThunk(
  'contract/getContract',
  async ({ id }: { id: string }) => {
    try {
      const response = await getContract(id);
      return response.data;
    } catch (error) {
      console.error(`Error fetching contract with ID ${id}:`, error);
      throw error;
    }
  },
);

export const addContractAsync = createAsyncThunk<IContractAdmin, IContractAdmin>(
  'contracts/addContract',
  async (contract: IContractAdmin) => {
    return contract;
  },
);

export const createContractThunk = createAsyncThunk(
  'createContract',
  async (contractData: ICreateContract) => {
    try {
      const response = await createContract(contractData);
      console.log('Contract created:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error creating contract:', error);
      throw error;
    }
  },
);

export const updateContractThunk = createAsyncThunk(
  'updateContract',
  async ({ id, updatedData }: { id: string; updatedData: ICreateContract }) => {
    try {
      const res = await updateContract(id, updatedData);
      return res.data;
    } catch (error) {
      console.error('Error updating contract:', error);
      throw error;
    }
  },
);

export const deleteContractThunk = createAsyncThunk(
  'deleteContract',
  async (contractId: string) => {
    try {
      await deleteContract(contractId);
    } catch (error) {
      console.error('Error deleting contract:', error);
      throw error;
    }
  },
);
