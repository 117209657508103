import { IHedge } from '@app/types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ISocketSlice {
  isEstablishingConnection: boolean;
  isConnected: boolean;
  initHedgeResponse: string[];
  initHedgeRequest: IHedge | null;
}

const initialState: ISocketSlice = {
  isEstablishingConnection: false,
  isConnected: false,
  initHedgeResponse: [],
  initHedgeRequest: null,
};

const socketSlice = createSlice({
  name: 'socket',
  initialState,
  reducers: {
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.isEstablishingConnection = true;
    },
    stopConnecting: (state) => {
      state.isConnected = false;
      state.isEstablishingConnection = false;
    },
  },
});

export const { startConnecting, connectionEstablished, stopConnecting } = socketSlice.actions;
export default socketSlice.reducer;
