import { Box, Button, List, OutlinedInput, SxProps, TextField } from '@mui/material';
import {
  CollapsableDataListItem,
  DataListItem,
  DrawerFooter,
  ScrapexSidebarTextH1,
  outlinedInputStyles,
} from '@common/components';
import { OutlinedSelect } from '@common/components/input/OutlinedSelect';
import { useFormik } from 'formik';
import { ContractStatus, ICreateContract } from '@app/types/contracts';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  createContractThunk,
  getContractsThunk,
} from 'src/accountManager/reducer/contracts/thunks';
import { useGetCompanies } from 'src/accountManager/companies/components/companiesTable/table/hooks/useGetCompanies';
import { calculateEstimatedShipmentWeight } from 'src/accountManager/contract/utlis/calculateEstimatedShipmentWeight';
import { FormErrorSubtitle } from '@common/components/drawer/formErrorSubtitle';
import { validationSchema } from './validationSchema';
import { createCompaniesMenuList } from '../../utlis/createCompaniesMenu';
import { productList } from '../../utlis/productList';
import { PdfFileSelector } from './pdfFileSelector';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';

interface CreateContractDrawerProps {
  onClose: () => void;
}

const formValuesMap: Record<string, keyof ICreateContract> = {
  sellerContract: 'seller_contract',
  sellerContractNumberInput: 'seller_contract_number',
  buyerContract: 'buyer_contract',
  buyerContractNumberInput: 'buyer_contract_number',
  sellerInput: 'seller',
  buyerInput: 'buyer',
  contractDateInput: 'contract_date',
  productInput: 'product',
  metalInput: 'metal',
  formulaInput: 'formula',
  productWeightInput: 'product_weight',
  shipmentsInput: 'total_shipments',
};

export const CreateContractDrawer = ({ onClose }: CreateContractDrawerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { companies } = useGetCompanies();

  const menuList = createCompaniesMenuList(companies);

  const initialValues: ICreateContract = {
    deal_status: ContractStatus.Active,
    seller: '',
    buyer: '',
    buyer_contract: '00000000-0000-0000-0000-000000000000',
    buyer_contract_number: '',
    seller_contract: '00000000-0000-0000-0000-000000000000',
    seller_contract_number: '',
    contract_date: null,
    product: '',
    metal: 'Copper',
    formula: '',
    product_weight: '',
    total_shipments: 0,
  };

  const onSubmit = async (data: ICreateContract) => {
    await dispatch(
      createContractThunk({ ...data, product_weight: parseFloat(data.product_weight as string) }),
    );
    await dispatch(getContractsThunk());
    onClose();
  };

  const { handleSubmit, handleChange, values, errors, touched, setFieldValue, setValues } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      onSubmit,
      validationSchema,
    });

  return (
    <>
      <form onSubmit={handleSubmit} id="contract">
        <Box padding="0 40px 100px 40px">
          <ScrapexSidebarTextH1>Create new contract</ScrapexSidebarTextH1>
          <List>
            <CollapsableDataListItem
              input={
                <OutlinedInput
                  placeholder="Enter contract no."
                  sx={outlinedInputStyles(
                    !!errors.seller_contract_number && !!touched.seller_contract_number,
                  )}
                  onChange={(e) => {
                    const contractNumber = e.target.value;
                    handleChange(e);
                    setFieldValue(formValuesMap.buyerContractNumberInput, `${contractNumber}B`);
                  }}
                  name={formValuesMap.sellerContractNumberInput}
                  value={values.seller_contract_number}
                />
              }
              title={'Contract No.'}
              subtitle={
                <FormErrorSubtitle
                  errors={errors.seller_contract_number}
                  touched={touched.seller_contract_number}
                />
              }
            >
              <DataListItem title="Seller Contract No.*">
                <OutlinedInput
                  sx={outlinedInputStyles()}
                  placeholder="None"
                  value={values.seller_contract_number}
                  disabled
                />
              </DataListItem>
              <DataListItem title="Buyer Contract No.*">
                <OutlinedInput
                  sx={outlinedInputStyles()}
                  placeholder="None"
                  name={formValuesMap.buyerContractNumberInput}
                  value={values.buyer_contract_number}
                  disabled
                />
              </DataListItem>
            </CollapsableDataListItem>

            <DataListItem
              title="Seller*"
              subtitle={<FormErrorSubtitle errors={errors?.seller} touched={touched?.seller} />}
            >
              <OutlinedSelect
                sx={outlinedInputStyles(!!errors.seller && !!touched.seller)}
                placeholder="Select seller"
                menuList={menuList}
                id={''}
                label={''}
                name={formValuesMap.sellerInput}
                value={values.seller}
                onChange={handleChange}
              ></OutlinedSelect>
            </DataListItem>

            <DataListItem
              title="Buyer*"
              subtitle={<FormErrorSubtitle errors={errors?.buyer} touched={touched?.buyer} />}
            >
              <OutlinedSelect
                sx={outlinedInputStyles(!!errors.buyer && !!touched.buyer)}
                placeholder="Select buyer"
                menuList={menuList}
                id={''}
                label={''}
                name={formValuesMap.buyerInput}
                value={values.buyer}
                onChange={handleChange}
              ></OutlinedSelect>
            </DataListItem>

            <DataListItem
              title="Contract Date*"
              subtitle={
                <FormErrorSubtitle errors={errors.contract_date} touched={touched.contract_date} />
              }
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={outlinedInputStyles(!!errors.contract_date && !!touched.contract_date)}
                  value={values.contract_date}
                  format="DD/MM/YYYY"
                  name={formValuesMap.contractDateInput}
                  disableFuture
                  onChange={(date: Dayjs | null) => {
                    const formattedDate = date?.format('YYYY-MM-DD');
                    setFieldValue(formValuesMap.contractDateInput, formattedDate);
                  }}
                  slotProps={{ textField: { placeholder: 'Enter your date', error: false } }}
                />
              </LocalizationProvider>
            </DataListItem>

            <DataListItem
              title="Product*"
              subtitle={<FormErrorSubtitle errors={errors.product} touched={touched.product} />}
            >
              <OutlinedSelect
                sx={outlinedInputStyles(!!errors.product && !!touched.product)}
                placeholder="Select product"
                menuList={productList}
                id={''}
                label={''}
                name={formValuesMap.productInput}
                value={values.product}
                onChange={handleChange}
              ></OutlinedSelect>
            </DataListItem>

            <DataListItem
              title="Metal*"
              subtitle={<FormErrorSubtitle errors={errors.metal} touched={touched.metal} />}
            >
              <OutlinedInput
                sx={outlinedInputStyles(!!errors.metal && !!touched.metal)}
                placeholder="Copper"
                disabled
                name={formValuesMap.metalInput}
                value={values.metal}
                onChange={handleChange}
              />
            </DataListItem>

            <DataListItem
              title="Formula*"
              subtitle={<FormErrorSubtitle errors={errors.formula} touched={touched.formula} />}
            >
              <OutlinedInput
                sx={outlinedInputStyles(!!errors.formula && !!touched.formula)}
                placeholder="Enter formula"
                name={formValuesMap.formulaInput}
                value={values.formula}
                onChange={(e) => {
                  const { value } = e.target;
                  const replacedValue = value.replace(/,/g, '.'); // Replace comma with dot
                  setFieldValue(formValuesMap.formulaInput, replacedValue);
                }}
              />
            </DataListItem>

            <DataListItem
              title="Product Weight*, mt"
              subtitle={
                <FormErrorSubtitle
                  errors={errors.product_weight}
                  touched={touched.product_weight}
                />
              }
            >
              <OutlinedInput
                sx={outlinedInputStyles(!!errors.product_weight && !!touched.product_weight)}
                type="text"
                placeholder="Enter weight"
                onChange={(e) => {
                  const replacedValue = e.target.value
                    .replace(/,/g, '.')
                    .replace(/^\.+|[^0-9.]|\.(?=.*\.)/g, '') // Allow only digits and one dot
                    .replace(/^(\d*\.\d{0,3}).*$/g, '$1'); // Allow up to two digits after dot
                  setFieldValue(formValuesMap.productWeightInput, replacedValue);
                }}
                name={formValuesMap.productWeightInput}
                value={values.product_weight}
              />
            </DataListItem>

            <DataListItem
              title="Number of Shipments*"
              subtitle={
                <FormErrorSubtitle
                  errors={errors.total_shipments}
                  touched={touched.total_shipments}
                />
              }
            >
              <OutlinedInput
                sx={outlinedInputStyles(!!errors.total_shipments && !!touched.total_shipments)}
                type="number"
                placeholder="Enter number of shipments*"
                onChange={handleChange}
                name={formValuesMap.shipmentsInput}
                value={values.total_shipments}
                onKeyDown={(e) => {
                  if (e.key === ',' || e.key === '.' || e.key === 'e' || e.key === 'E') {
                    e.preventDefault();
                  }
                }}
                inputProps={{ min: 0, max: 50 }}
              />
            </DataListItem>

            <DataListItem title="Estimated Shipment Weight">
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                  inputProps: {
                    style: { textAlign: 'right' },
                  },
                }}
                value={calculateEstimatedShipmentWeight(
                  parseFloat(values.product_weight as string),
                  values.total_shipments,
                )}
              />
            </DataListItem>
            <DataListItem title="PDF Seller Contract">
              <PdfFileSelector
                onPdfIdSelect={(pdfId) => setFieldValue(formValuesMap.sellerContract, pdfId)}
              />
            </DataListItem>
            <DataListItem title="PDF Buyer Contract">
              <PdfFileSelector
                onPdfIdSelect={(pdfId) => setFieldValue(formValuesMap.buyerContract, pdfId)}
              />
            </DataListItem>
          </List>
        </Box>
      </form>
      <DrawerFooter>
        <Button variant="contained" type="submit" form="contract">
          Create Contract
        </Button>
      </DrawerFooter>
    </>
  );
};
