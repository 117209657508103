import { IHedge } from '@app/types';
import { GridRowsProp } from '@mui/x-data-grid-premium';

export const generateHedgeHistoryRows = (hedges: IHedge[]) => {
  const rows: GridRowsProp = hedges.map((hedge) => ({
    ...hedge,
    id: hedge.id,
  }));

  return { rows };
};
