import { Box } from '@mui/material';
import { ContractShipmentsTable } from './components/table';

export const ContractShipments = () => {
  return (
    <Box sx={{ mt: '20px', borderRadius: '8px' }}>
      <ContractShipmentsTable />
    </Box>
  );
};
