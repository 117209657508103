import { Container } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';
import classes from './styles.module.scss';
import { reset } from '../../auth/reducer/company_registration/slice';
import { HeaderEditCompany } from './HeaderEditCompany';

export const CompanyEdit = () => {
  const dispatch = useDispatch<AppDispatch>();
  // useEffect(() => {
  //   dispatch(reset());
  // }, []);
  return (
    <Container className={classes.main}>
      <HeaderEditCompany />
    </Container>
  );
};
