import {
  getHedgeNotifications,
  getNotificationsHistory,
  getOtherNotifications,
  markAllAsRead,
  toggleRead,
} from '@api/notifications';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getOtherNotificationsThunk = createAsyncThunk(
  'notifications/getOtherNotifications',
  async () => {
    try {
      const res = await getOtherNotifications();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getHedgeNotificationsThunk = createAsyncThunk(
  'notifications/getHedgeNotifications',
  async () => {
    try {
      const res = await getHedgeNotifications();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getNotificationsHistoryThunk = createAsyncThunk(
  'notifications/getNotificationsHistory',
  async () => {
    try {
      const res = await getNotificationsHistory();
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const toggleReadThunk = createAsyncThunk(
  'notifications/toggleRead',
  async (notificationId: string, thunkAPI) => {
    try {
      const res = await toggleRead(notificationId);
      thunkAPI.dispatch(getHedgeNotificationsThunk());
      thunkAPI.dispatch(getOtherNotificationsThunk());
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);
export const readAllHedgeNotificationsThunk = createAsyncThunk(
  'notifications/readAllHedgeNotifications',
  async () => {
    try {
      const res = await markAllAsRead('hedge');
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);
export const readAllOtherNotificationsThunk = createAsyncThunk(
  'notifications/readAllOtherNotifications',
  async () => {
    try {
      const res = await markAllAsRead('backoffice');
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);
