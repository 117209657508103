/* eslint-disable react/jsx-no-useless-fragment */
import { CheckCircle } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import { useEffect, useState } from 'react';

export enum EndAdornmentEnum {
  HIDE = 'HIDE',
  CHECKED = 'CHECKED',
  SHOW = 'SHOW',
}

interface IScrapexOutlinedInputProps {
  label: string;
  id: string;
  helperText?: string;
  icon?: EndAdornmentEnum;
}

export const ScrapexOutlinedInput = (props: IScrapexOutlinedInputProps & OutlinedInputProps) => {
  const { label, id, icon, helperText, ...currentProps } = props;

  const [inputAdornmentValue, setInputAdornmentValue] = useState(<div />);

  useEffect(() => {
    switch (icon) {
      case EndAdornmentEnum.HIDE:
        setInputAdornmentValue(<div>Hide</div>);
        break;
      case EndAdornmentEnum.CHECKED:
        setInputAdornmentValue(<CheckCircle color="success" />);
        break;
      case EndAdornmentEnum.SHOW:
        setInputAdornmentValue(<div>Show</div>);
        break;
      default:
        setInputAdornmentValue(<></>);
    }
  }, [icon]);

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <OutlinedInput
        id={id}
        endAdornment={<InputAdornment position="end">{inputAdornmentValue}</InputAdornment>}
        label={label}
        fullWidth
        {...currentProps}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
