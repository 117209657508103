import React from 'react';
import { ScrapexColorEnum } from '../../../colors';
import { Typography } from '@mui/material';

interface ITextProps {
  children: React.ReactNode | string;
  color?: ScrapexColorEnum;
}
export const ScrapexTitleH1 = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 700,
        fontSize: '32px',
        lineHeight: ' 38px',
        letterSpacing: '0.15px',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexTitleH2 = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 500,
        fontSize: '24px',
        lineHeight: '28px',
        letterSpacing: '0.15px',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexTitleH3 = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexSubitle = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexSubitleUppercase = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.15px',
        textTransform: 'uppercase',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexBodyTextRegular = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexBodyTextMedium = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.17px',
      }}
    >
      {children}
    </span>
  );
};
export const ScrapexBodyText = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20.8px',
        letterSpacing: '0.17px',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexBodyTextItalicHight = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
        fontStyle: 'italic',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexBodyTextItalicLow = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '0.17px',
        fontStyle: 'italic',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexBodyTextLow = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.17px',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexInputText = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexSidebarTextH1 = ({ children, color }: ITextProps) => {
  return (
    <Typography
      sx={{
        my: '20px',
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '23px',
        letterSpacing: '0.15px',
      }}
    >
      {children}
    </Typography>
  );
};

export const ScrapexSidebarTextH2 = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        color: color || ScrapexColorEnum.MAIN_TEXT_COLOR,
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexSidebarMediumTextH2 = ({ children, color }: ITextProps) => {
  return (
    <span
      style={{
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.15px',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexGrayText = ({ children }: ITextProps) => {
  return (
    <span
      style={{
        color: ScrapexColorEnum.FILL_TEXT_COLOR,
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '18px',
      }}
    >
      {children}
    </span>
  );
};

export const ScrapexGrayTextRegular = ({ children }: ITextProps) => {
  return (
    <span
      style={{
        color: ScrapexColorEnum.FILL_TEXT_COLOR,
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '20px',
      }}
    >
      {children}
    </span>
  );
};
