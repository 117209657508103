import httpClient from '../../client/httpClient';
import {
  IShipment,
  ICreateShipmentInvoice,
  IUpdateShipment,
  IShipmentInvoice,
  IShipmentUser,
} from '@app/types';

export const getShipments = (contractId: string) => {
  const API_ADMIN_SHIPMENTS = `/api/admin/deals/${contractId}/shipments`;

  return httpClient.get<IShipment[]>(API_ADMIN_SHIPMENTS);
};

export const getShipment = (shipmentId: string) => {
  const API_ADMIN_SHIPMENT = `/api/admin/shipments/${shipmentId}/`;
  return httpClient.get<IShipment>(API_ADMIN_SHIPMENT);
};

export const getUserShipments = (contractId: string) => {
  const API_USER_SHIPMENTS = `/api/user/deals/${contractId}/shipments`;

  return httpClient.get<IShipmentUser[]>(API_USER_SHIPMENTS);
};
export const getUserShipmentById = (shipmentId: string) => {
  const API_USER_SHIPMENT = `/api/user/shipments/${shipmentId}`;

  return httpClient.get<IShipmentUser>(API_USER_SHIPMENT);
};

export const updateShipment = ({ shipmentId, data }: IUpdateShipment) => {
  return httpClient.patch<IShipment>(`/api/admin/shipments/${shipmentId}`, data);
};

export const addInvoice = (invoiceData: ICreateShipmentInvoice) => {
  const API_ADMIN_INVOICES = `/api/admin/invoices`;

  return httpClient.post(API_ADMIN_INVOICES, invoiceData);
};

export const updateInvoice = ({
  invoiceId,
  invoiceData,
}: {
  invoiceId: string;
  invoiceData: IShipmentInvoice;
}) => {
  const API_ADMIN_INVOICES = `/api/admin/invoices/${invoiceId}`;

  return httpClient.put(API_ADMIN_INVOICES, invoiceData);
};

export const deleteInvoice = (invoiceId: string) => {
  const API_ADMIN_INVOICES = `/api/admin/invoices/${invoiceId}`;

  return httpClient.delete(API_ADMIN_INVOICES);
};
