import { AppDispatch, RootState } from '@app/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { actions } from 'src/accountManager/reducer/companies/slice';
import { getCompanyByIdThunk } from 'src/accountManager/reducer/companies/thunks';

export const useGetCompanyInfo = (id: string) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (id) dispatch(getCompanyByIdThunk({ id }));
    return () => {
      // dispatch(actions.resetCurrentCompany());
    };
  }, [id]);

  const { currentCompany, isCompanyLoading } = useSelector(
    (state: RootState) => state.accountManager.companies,
  );

  return { currentCompany, isCompanyLoading };
};
