import { Box } from '@mui/material';
import classes from './styles.module.scss';
import { CompanyStatus } from '@app/types';

export const CompanyStatusIndicator = ({ status }: { status: CompanyStatus }) => {
  const companyStatusMap: { [key in CompanyStatus]: string } = {
    active: 'Active',
    frozen: 'Frozen',
    invited: 'Invited',
    none: 'No Status',
  };

  return (
    <Box className={`${classes[status]} ${classes.status}`}>
      {companyStatusMap[status as CompanyStatus]}
    </Box>
  );
};
