import React from 'react';
import { Box, Button, ButtonGroup, CircularProgress, IconButton, SxProps } from '@mui/material';
import { RestartAlt, DeleteOutlineOutlined, DownloadingOutlined } from '@mui/icons-material';
import { ScrapexGrayText } from '@common/components';
import { useGetFileName } from '@app/hooks/useGetFileName';
import { getFileUrl } from '@app/utils';

interface FileRowProps {
  fileId: string;
  dealId?: string;
  onReuploadClick?: () => void;
  onClearClick?: () => void;
  hasDownloadFile?: boolean;
  hasRemoveFile?: boolean;
  hasReplaceFile?: boolean;
  sx?: SxProps;
}

//TODO: FileRow, useGetFileNameAndLink, useUploadFiles and pdfFileSelector for shipments and contracts have nearly identical logic with only minor differences.
//It's worth considering the creation of common, reusable components

export const FileRow: React.FC<FileRowProps> = ({
  fileId,
  dealId = '',
  onReuploadClick,
  onClearClick,
  hasDownloadFile,
  hasRemoveFile = true,
  hasReplaceFile = true,
  sx,
}) => {
  const { fileName, loading, error } = useGetFileName(dealId, fileId, 'admin');

  const handleDownloadFile = async () => {
    const link = await getFileUrl(dealId, fileId, 'admin');
    window.open(link, '_self');
  };

  return (
    <>
      {error && <p>Error: {error.message}</p>}
      {!error && (
        <>
          {fileId ? (
            <Box display="flex" alignItems="center" sx={sx} justifyContent="space-between">
              {loading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button onClick={handleDownloadFile}>{fileName}</Button>
                  <ButtonGroup>
                    {hasDownloadFile && (
                      <IconButton onClick={handleDownloadFile}>
                        <DownloadingOutlined fontSize="small" />
                      </IconButton>
                    )}
                    {hasReplaceFile && (
                      <IconButton onClick={onReuploadClick}>
                        <RestartAlt fontSize="small" />
                      </IconButton>
                    )}
                    {hasRemoveFile && (
                      <IconButton onClick={onClearClick}>
                        <DeleteOutlineOutlined color="error" fontSize="small" />
                      </IconButton>
                    )}
                  </ButtonGroup>
                </>
              )}
            </Box>
          ) : (
            <Box>
              <ScrapexGrayText>None</ScrapexGrayText>
            </Box>
          )}
        </>
      )}
    </>
  );
};
