import httpClient from '@app/client/httpClient';
import { IContractAdmin, IUpdateContract } from '@app/types';

interface IUpdateContractProps {
  contractId?: string;
  contractType: string;
  contractFile?: string;
}
//TODO: rewrite adding contract PDFs logic using shipment update
export const updateContractWithFiles = async ({
  contractId,
  contractType,
  contractFile,
}: IUpdateContractProps): Promise<void> => {
  const updatedFields: Partial<IUpdateContract> = {};

  if (contractType === 'buyer') {
    updatedFields.buyer_contract = contractFile;
  } else if (contractType === 'seller') {
    updatedFields.seller_contract = contractFile;
  }

  try {
    await httpClient.patch<IUpdateContract>(`/api/admin/deals/${contractId}`, updatedFields);
    console.log('Contract updated successfully!');
  } catch (error) {
    console.error('Failed to update contract:', error);
    throw error;
  }
};
