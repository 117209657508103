import { getUserContract, getUserContracts } from '@api/contracts';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getContractsThunk = createAsyncThunk('contracts/getContracts', async () => {
  try {
    const res = await getUserContracts();
    return { contracts: res.data };
  } catch (error) {
    console.log(error);
  }
});

export const getContractThunk = createAsyncThunk(
  'contract/getContract',
  async ({ id }: { id: string }) => {
    try {
      const res = await getUserContract(id);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);
