import { TableContainer } from '@mui/material';
import { TableHeader } from 'src/accountManager/common/tableHeader';
import { DrawerGeneral, LoadingSkeleton } from '@common/components';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { ScrapexColorEnum } from '@common/colors';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';

import { generateUnhedgedShipmentColumns, generateUnhedgedShipmentRows } from './data';
import { HedgeCreateDrawer } from '../hedgeCreateDrawer';
import { getUnhedgedThunk } from 'src/hedgeAdmin/reducer/hedging/thunk';
import { useHedgeDrawers } from './hooks/useHedgeDrawers';
import { HedgeRequestDrawer } from 'src/hedgeAdmin/hedging/hedgeRequests/components/hedgeRequestDrawer';
import { IHedge } from '@app/types';
import { filterPanelProps } from 'src/hedgeAdmin/hedging/shared';

export const UnhedgedTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const shipments = useSelector((state: RootState) => state.hedgingAdmin.hedging.unhedged);
  const isLoading = useSelector((state: RootState) => state.hedgingAdmin.hedging.isLoading);
  const { hedgeCreateDrawer, hedgeRequestDrawer, closeAllHandler } = useHedgeDrawers();

  const [selectedShipment, setSelectedShipment] = useState<string | null>(null);
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [selectedHedge, setSelectedHedge] = useState<IHedge | null>(null);

  const unhedgeHeaderConfig = {
    title: `Unhedged (${shipments?.length})`,
    withExport: true,
  };

  useEffect(() => {
    dispatch(getUnhedgedThunk());
  }, []);

  const handleOpenHedgeCreateDrawer = (shipmentId: string, companyId: string) => {
    setSelectedShipment(shipmentId);
    setCompanyId(companyId);
    hedgeCreateDrawer.open();
  };

  const handleOpenHedgeRequestDrawer = (hedge: IHedge) => {
    setSelectedHedge(hedge);
    hedgeRequestDrawer.open();
  };

  const { columns } = generateUnhedgedShipmentColumns({
    onHedgeCreateDrawer: handleOpenHedgeCreateDrawer,
    onHedgeRequestDrawer: handleOpenHedgeRequestDrawer,
  });
  const { rows } = generateUnhedgedShipmentRows(shipments);

  return (
    <>
      <TableContainer sx={{ background: ScrapexColorEnum.WHITE_COLOR }}>
        <div style={{ width: '1540px', overflowX: 'auto', height: '100vh' }}>
          {isLoading && <LoadingSkeleton />}
          {shipments && !isLoading && (
            <DataGridPremium
              sx={{
                background: '#fff',
                border: 'none',
                '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                  color: ScrapexColorEnum.FILL_TEXT_COLOR,
                  border: 'none',
                },
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
              }}
              columnHeaderHeight={40}
              rows={rows}
              columns={columns}
              initialState={{
                pinnedColumns: {
                  left: ['contract_number', 'shipment_number', 'shipment_status', 'ordinal_number'],
                },
                sorting: {
                  sortModel: [{ field: 'contract_number', sort: 'asc' }],
                },
              }}
              disableColumnFilter
              hideFooter
              columnBuffer={2}
              disableColumnMenu
              sortingOrder={['desc', 'asc']}
              // disableRowSelectionOnClick
              slots={{
                toolbar: TableHeader,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  ...unhedgeHeaderConfig,
                },
                filterPanel: {
                  ...filterPanelProps,
                },
              }}
              loading={isLoading}
            />
          )}
        </div>
        <DrawerGeneral
          onClose={closeAllHandler}
          open={hedgeCreateDrawer.isOpen || hedgeRequestDrawer.isOpen}
        >
          {hedgeCreateDrawer.isOpen && selectedShipment && companyId && (
            <HedgeCreateDrawer
              shipmentId={selectedShipment}
              companyId={companyId}
              onClose={closeAllHandler}
            />
          )}
          {hedgeRequestDrawer.isOpen && selectedHedge && (
            <HedgeRequestDrawer hedge={selectedHedge} onClose={closeAllHandler} />
          )}
        </DrawerGeneral>
      </TableContainer>
    </>
  );
};
