import { ICompany } from '@app/types';

export const createCompaniesMenuList = (companies: ICompany[]) => {
  const sortedCompanies = [...companies].sort((a, b) => a.name.localeCompare(b.name));
  return sortedCompanies
    ? sortedCompanies
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((company) => ({
          value: company.id,
          label: company.name,
        }))
    : [];
};
