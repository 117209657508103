import { GridColDef } from '@mui/x-data-grid-premium';
import { CompanyStatusIndicator } from '../../../status';
import { formatDate, renderDateOrNone } from '@app/utils';
import { dateSortComparator } from '@app/utils/sortComparators';

export const generateCompaniesColumns = () => {
  const columns: GridColDef[] = [
    {
      field: 'company_status',
      headerName: 'Status',
      renderCell: (params) => <CompanyStatusIndicator status={params.value} />,
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Company Name',
      minWidth: 200,
      flex: 3,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 200,
      flex: 3,
    },
    {
      field: 'invitation_date',
      headerName: 'Invitation date',
      valueGetter: (params) => {
        return formatDate(params.value);
      },
      renderCell: (params) => renderDateOrNone(params.value),
      sortComparator: dateSortComparator,
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'registration_date',
      headerName: 'Registration date',
      align: 'right',
      headerAlign: 'right',
      valueGetter: (params) => {
        return formatDate(params.row.invitation_date);
      },
      renderCell: (params) => renderDateOrNone(params.row.invitation_date),
      sortComparator: dateSortComparator,
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'number_of_contracts',
      headerName: 'Number of contracts',
      align: 'right',
      headerAlign: 'right',
      minWidth: 170,
      flex: 1.5,
    },
  ];

  return { columns };
};
