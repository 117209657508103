import {
  addInvoice,
  deleteInvoice,
  getShipment,
  getShipments,
  updateInvoice,
  updateShipment,
} from '@api/shipments';
import { IShipment, ICreateShipmentInvoice, IUpdateShipment, IShipmentInvoice } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getShipmentsThunk = createAsyncThunk(
  'shipments/getShipments',
  async (contractId: string) => {
    try {
      const res = await getShipments(contractId);
      return { shipments: res.data };
    } catch (error) {
      console.log(error);
    }
  },
);

export const getShipmentThunk = createAsyncThunk(
  'shipments/getShipment',
  async ({ shipmentId }: { shipmentId: string }) => {
    try {
      const response = await getShipment(shipmentId);
      return response.data;
    } catch (error) {
      console.error(`Error fetching shipment with ID ${shipmentId}:`, error);
      throw error;
    }
  },
);

export const updateShipmentThunk = createAsyncThunk(
  'shipments/updateShipment',
  async ({ shipmentId, data }: IUpdateShipment) => {
    try {
      const response = await updateShipment({ shipmentId, data });
      return response.data;
    } catch (error) {
      console.error(`Error updating shipment with ID ${shipmentId}:`, error);
      throw error;
    }
  },
);

export const addInvoiceThunk = createAsyncThunk(
  'invoices/addInvoice',
  async (invoiceData: ICreateShipmentInvoice) => {
    try {
      await addInvoice(invoiceData);
    } catch (error) {
      console.error('Failed to add invoice:', error);
      throw error;
    }
  },
);

export const updateInvoiceThunk = createAsyncThunk(
  'invoices/updateInvoice',
  async ({ invoiceId, invoiceData }: { invoiceId: string; invoiceData: IShipmentInvoice }) => {
    try {
      await updateInvoice({ invoiceId, invoiceData });
    } catch (error) {
      console.error('Failed to update invoice:', error);
      throw error;
    }
  },
);

export const deleteInvoiceThunk = createAsyncThunk(
  'invoices/deleteInvoice',
  async (invoiceId: string) => {
    try {
      await deleteInvoice(invoiceId);
    } catch (error) {
      console.error('Failed to delete invoice:', error);
      throw error;
    }
  },
);
