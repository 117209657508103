import { GridColDef } from '@mui/x-data-grid-premium';
import { HedgingStatusIndicator, hedgeStatusMap } from '@common/components/display/hedging/status';
import { formatDate, formatTime } from '@app/utils';
import { TextWithSubtitle } from '@common/components/display/text';
import { renderDateOrNone, renderValueOrNone } from '@app/utils';
import { CircleComponent } from 'src/hedgeAdmin/hedging/shared/table/circleComponent';
import { ScrapexGrayText } from '@common/components';

import {
  currentLMESortComparator,
  dateSortComparator,
  hedgeStatusSortComparator,
} from '@app/utils/sortComparators';
import { getUserRole } from 'src/hedgeAdmin/hedging/shared/utils';
import { CurrentLMEHeader, operator } from 'src/hedgeAdmin/hedging/shared';
import { HedgingStatus } from '@app/types';
import { getDateValue } from '@app/utils/getDateValue';
// import classes from '../styles.module.scss';

export const generateHedgeRequestsColumns = () => {
  const columns: GridColDef[] = [
    {
      field: 'hedge_status',
      headerName: 'Status',
      width: 160,
      valueGetter: (params) => {
        return hedgeStatusMap[params.value as HedgingStatus];
      },
      renderCell: (params) => <HedgingStatusIndicator status={params.row.hedge_status} />,
      sortComparator: hedgeStatusSortComparator,
    },
    {
      field: 'hedge_type',
      headerName: 'Type',
      width: 116,
      renderCell: (params) => params.value.toUpperCase(),
    },
    {
      field: 'request_time',
      headerName: 'Date/Time',
      width: 140,

      valueGetter: (params) => {
        return getDateValue(params.value);
      },
      renderCell: (params) => (
        <TextWithSubtitle
          title={params.value.displayDate}
          subtitle={formatTime(params.row.request_time)}
        />
      ),
      sortComparator: (a, b) => {
        return dateSortComparator(a.isoDate, b.isoDate);
      },
    },
    {
      field: 'role',
      headerName: '',
      width: 68,
      align: 'center',
      valueGetter: (params) => getUserRole(params.row.contract_number),
      renderCell: (params) => <CircleComponent contractNumber={params.row.contract_number} />,
    },
    {
      field: 'company_name',
      headerName: 'Company',
      width: 148,
      valueGetter: (params) => `${params.row.company_name} ${params.row.hedge_number}`,
      renderCell: (params) => (
        <TextWithSubtitle title={params.row.company_name} subtitle={params.row.hedge_number} />
      ),
    },
    {
      field: 'product',
      headerName: 'Metal/Product',
      width: 148,
      renderCell: (params) => <TextWithSubtitle title={params.row.metal} subtitle={params.value} />,
    },
    {
      field: 'pure_metal',
      headerName: 'Pure Metal, mt',
      width: 148,
      renderCell: (params) => renderValueOrNone(params.value),
    },

    {
      field: 'formula',
      headerName: 'Formula',
      width: 148,
    },
    {
      field: 'quotation',
      headerName: 'Current LME Request',
      width: 230,
      renderHeader: (params) => <CurrentLMEHeader {...params} />,
      valueGetter: (params) => {
        const { tp_exchange_level, olme_date, quotation } = params.row;
        if (olme_date) {
          return formatDate(olme_date);
        } else {
          return tp_exchange_level || quotation || null;
        }
      },
      filterOperators: [operator],
      renderCell: (params) => {
        const { tp_exchange_level } = params.row;

        return (
          <span>
            {renderValueOrNone(params.value)}
            {tp_exchange_level && <ScrapexGrayText> TP</ScrapexGrayText>}
          </span>
        );
      },
      sortComparator: currentLMESortComparator,
    },
    {
      field: 'eta',
      headerName: 'ETA',
      width: 140,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },
      renderCell: (params) => renderDateOrNone(params.value),
      sortComparator: dateSortComparator,
    },
    {
      field: 'limit_fixation_date',
      headerName: 'Limit Fixation Date',
      width: 140,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },
      renderCell: (params) => renderDateOrNone(params.value),
      sortComparator: dateSortComparator,
    },
  ];

  return { columns };
};
