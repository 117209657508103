export const calculateEstimatedShipmentWeight = (
  productWeight: number | null | undefined,
  numberOfShipments: number | null | undefined,
): string | null => {
  if (
    productWeight !== undefined &&
    productWeight !== null &&
    !isNaN(productWeight) &&
    numberOfShipments !== undefined &&
    numberOfShipments !== null &&
    !isNaN(numberOfShipments) &&
    numberOfShipments !== 0
  ) {
    const estimatedValue = productWeight / numberOfShipments;
    if (!isNaN(estimatedValue) && isFinite(estimatedValue)) {
      const roundedValue = Math.round(estimatedValue * 1000) / 1000;
      const fixedValue =
        roundedValue % 1 === 0
          ? roundedValue.toFixed(0)
          : roundedValue.toFixed(3).replace(/\.?0+$/, '');

      return `${fixedValue} mt`;
    }
  }
  return null;
};
