import httpClient from '@app/client/httpClient';
import { IGetsFileInfo } from '@app/types/api';
import { noFileId } from '@app/utils/isFileExists';
import { useState } from 'react';

//for contract pdf. discuss with backend is needed to remove this hook
export const useGetFileNameAndLink = (id: string | undefined) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const hostname =
    window.location.hostname === 'localhost' ? 'dev.scrapex.app' : window.location.hostname;

  const link = id && id !== noFileId ? `https://${hostname}/api/upload/${id}` : null;

  const getFileName = async () => {
    try {
      setLoading(true);
      const res = await httpClient.get<IGetsFileInfo>(`api/upload/${id}/info`);
      return res.data.filename;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { getFileName, loading, error, link };
};
