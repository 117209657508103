import { AppDispatch, RootState } from '@app/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateContractsColumns, contractsRowData } from '../data';
import { getContractsThunk } from 'src/account/reducer/contracts/thunks';
import { useNavigate } from 'react-router';
import { AccountRoutes } from '@app/types/navigation';

export const useContractsTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const contracts = useSelector((state: RootState) => {
    return state.account.contracts.contracts;
  });

  useEffect(() => {
    dispatch(getContractsThunk());
  }, []);

  const handleOpenContractShipments = (id: string) => {
    navigate(`/${AccountRoutes.COMPANY_CONTRACTS}/${id}`);
  };

  const { columns } = generateContractsColumns();
  const { rows } = contractsRowData(contracts);

  return { contracts, columns, rows, handleOpenContractShipments };
};
