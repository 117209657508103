/* eslint-disable no-debugger */
import axios from 'axios';

import type { AxiosInstance, AxiosResponse } from 'axios';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

class HttpClient {
  private axiosInstance: AxiosInstance;

  public constructor() {
    this.axiosInstance = axios.create({
      baseURL: '/',
    });

    this.axiosInstance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('jwt');
        if (
          history.location.pathname.includes('/signup') &&
          history.location.pathname.includes('/login') &&
          history.location.pathname === '/'
        ) {
          history.push('/signup');
          document.location.reload();
        } else if (token && config && config.headers) {
          config.headers.Authorization = token;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );

    this.axiosInstance.interceptors.response.use(
      (response) => {
        if (response.headers.authorization) {
          localStorage.setItem('jwt', response.headers.authorization);
        }
        return response;
      },
      function (error) {
        if (
          error.response.status === 401 &&
          !history.location.pathname.includes('/login') &&
          !history.location.pathname.includes('/signup') &&
          history.location.pathname !== '/'
        ) {
          history.push('/login');
          document.location.reload();
          return Promise.reject(error);
        }
        return Promise.reject(error);
      },
    );
  }

  public get<T>(endpoint: string, data?: any): Promise<AxiosResponse<T>> {
    return this.axiosInstance.get(endpoint, { params: data });
  }

  public post<T>(endpoint: string, data?: any): Promise<AxiosResponse<T>> {
    return this.axiosInstance.post(endpoint, data);
  }

  public put<T>(endpoint: string, data?: any): Promise<AxiosResponse<T>> {
    return this.axiosInstance.put(endpoint, data);
  }

  public patch<T>(endpoint: string, data?: any): Promise<AxiosResponse<T>> {
    return this.axiosInstance.patch(endpoint, data);
  }

  public delete<T>(endpoint: string): Promise<AxiosResponse<T>> {
    return this.axiosInstance.delete(endpoint);
  }

  public head<T>(endpoint: string): Promise<AxiosResponse<T>> {
    return this.axiosInstance.head(endpoint);
  }
}

const httpClient = new HttpClient();

export default httpClient;
