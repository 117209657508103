import { IMenuItem } from '@common/components/input/OutlinedSelect';

export const productList: IMenuItem[] = [
  { value: 'Copper ingot', label: 'Copper ingot' },
  { value: 'Copper rod', label: 'Copper rod' },
  { value: 'Copper sow', label: 'Copper sow' },
  { value: 'Mill Berry', label: 'Mill Berry' },
  { value: 'Candy Berry', label: 'Candy Berry' },
  { value: 'Birch Cliff', label: 'Birch Cliff' },
  { value: 'Clove', label: 'Clove' },
  { value: 'Cobra', label: 'Cobra' },
  { value: '99%', label: '99%' },
  { value: 'Other copper alloy', label: 'Other copper alloy' },
  { value: 'Brass', label: 'Brass' },
  { value: 'Brass ingot', label: 'Brass ingot' },
  { value: 'Brass rod', label: 'Brass rod' },
  { value: 'Brass sow', label: 'Brass sow' },
  { value: 'Honey', label: 'Honey' },
  { value: 'Ocean', label: 'Ocean' },
  { value: 'Nomad', label: 'Nomad' },
  { value: 'L 70', label: 'L 70' },
  { value: 'L 71', label: 'L 71' },
  { value: 'L 72', label: 'L 72' },
  { value: 'L 73', label: 'L 73' },
  { value: 'L 74', label: 'L 74' },
  { value: 'L 75', label: 'L 75' },
  { value: 'LOS 57', label: 'LOS 57' },
  { value: 'LOS 58', label: 'LOS 58' },
  { value: 'LOS 59', label: 'LOS 59' },
  { value: 'LOS 60', label: 'LOS 60' },
  { value: 'LOS 61', label: 'LOS 61' },
  { value: 'LOS 62', label: 'LOS 62' },
  { value: 'LOS 63', label: 'LOS 63' },
  { value: 'LOS 64', label: 'LOS 64' },
  { value: 'LOS 65', label: 'LOS 65' },
  { value: 'LOS 66', label: 'LOS 66' },
  { value: 'LOS 67', label: 'LOS 67' },
  { value: 'LOS 68', label: 'LOS 68' },
  { value: 'LOS 69', label: 'LOS 69' },
  { value: 'LOS 70', label: 'LOS 70' },
  { value: 'LOS 71', label: 'LOS 71' },
  { value: 'LOS 72', label: 'LOS 72' },
  { value: 'LOS 73', label: 'LOS 73' },
  { value: 'LOS 74', label: 'LOS 74' },
  { value: 'LOS 75', label: 'LOS 75' },
  { value: 'LOS 76', label: 'LOS 76' },
  { value: 'LOS 77', label: 'LOS 77' },
  { value: 'LK 70', label: 'LK 70' },
  { value: 'LK 71', label: 'LK 71' },
  { value: 'LK 72', label: 'LK 72' },
  { value: 'LK 73', label: 'LK 73' },
  { value: 'LK 74', label: 'LK 74' },
  { value: 'LK 75', label: 'LK 75' },
  { value: 'LS', label: 'LS' },
  { value: 'LS 58', label: 'LS 58' },
  { value: 'Other brass alloy', label: 'Other brass alloy' },
  { value: 'Bronze', label: 'Bronze' },
  { value: 'Bronze sow', label: 'Bronze sow' },
  { value: 'Bronze rod', label: 'Bronze rod' },
  { value: 'Ebony', label: 'Ebony' },
  { value: 'Tin bronze', label: 'Tin bronze' },
  { value: 'CuNi bronze', label: 'CuNi bronze' },
  { value: 'Bronze ingot', label: 'Bronze ingot' },
  { value: 'Other bronze alloy', label: 'Other bronze alloy' },
];
