import React, { useState } from 'react';
import { Box, MenuItem, SelectChangeEvent, Popover } from '@mui/material';
import { ArrowDropDown, DoneAllOutlined } from '@mui/icons-material';
import { ShipmentStatusIndicator } from '@common/components';
import { ShipmentStatus } from '@app/types';

interface StatusFieldProps {
  initialStatus?: string;
  onHandleUpdateShipment: (value: string) => void;
}

interface StatusTypeItem {
  value: ShipmentStatus;
  label: string;
}

const statusTypeItems: StatusTypeItem[] = [
  { value: ShipmentStatus.None, label: 'None' },
  { value: ShipmentStatus.Loading, label: 'Loading' },
  { value: ShipmentStatus.InDelivery, label: 'In Delivery' },
  { value: ShipmentStatus.Delivered, label: 'Delivered' },
  { value: ShipmentStatus.Completed, label: 'Completed' },
];

export const StatusField: React.FC<StatusFieldProps> = ({
  initialStatus = 'none',
  onHandleUpdateShipment,
}) => {
  const [status, setStatus] = useState(initialStatus);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleStatusTypeChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setStatus(newValue);
    onHandleUpdateShipment(newValue);
    setAnchorEl(null);
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box onClick={openMenu} display="flex" sx={{ cursor: 'pointer', justifyContent: 'end' }}>
        <ShipmentStatusIndicator status={status as ShipmentStatus} />
        <ArrowDropDown color="action" />
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box width="230px" p={1}>
          {statusTypeItems.map((type) => (
            <MenuItem
              key={type.value}
              value={type.value}
              selected={type.value === status}
              onClick={() => {
                handleStatusTypeChange({
                  target: { value: type.value },
                } as SelectChangeEvent<string>);
              }}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '8px',
              }}
            >
              <Box>
                <ShipmentStatusIndicator status={type.value as ShipmentStatus} />
              </Box>
              {type.value === status && (
                <DoneAllOutlined color="success" style={{ marginLeft: '8px' }} />
              )}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </>
  );
};
