import classes from './styles.module.scss';
import mission from './assets/mission.png';
import principles from './assets/principles.png';

export const AboutUs = () => {
  return (
    <section id="about">
      <main className={`${classes.about} `}>
        <div className={classes.wrapper}>
          <img src={mission} width={400} />
          <div className={classes.textWrapper}>
            <h3>Our Mission</h3>
            <p>
              Our mission is to democratize metal trading market by offering hedging services to
              companies facing challenges under current industry rules and regulations. Through
              innovative technology and a user-friendly platform, we aim to streamline the hedging
              process, offering real-time solutions that enhance market participation and financial
              security for our clients.
            </p>
          </div>
        </div>
        <div className={classes.wrapper}>
          <div className={classes.textWrapper}>
            <h3>Our Core Principles</h3>
            <p>
              <p>
                <strong>Transparency</strong> <br />
                Clear communication and honesty with clients and data
              </p>{' '}
              <p>
                <strong>Trust and Integrity</strong> <br />
                Building reliable relationships and ethical practices
              </p>{' '}
              <p>
                <strong>Innovate</strong> <br />
                Using cutting-edge technology for efficiency and competitiveness
              </p>{' '}
              <p>
                <strong>Compliance</strong> <br />
                Compliance Adhering to regulations and industry standards
              </p>{' '}
              <p></p>
            </p>
          </div>
          <img src={principles} width={400} />
        </div>
      </main>
    </section>
  );
};
