import { Button, TableContainer } from '@mui/material';
import { generateShipmentColumns, generateShipmentRows } from './data';
import { TableHeader } from 'src/accountManager/common/tableHeader';
import { DrawerGeneral, LoadingSkeleton } from '@common/components';
// import { HedgeRequestDrawer } from '../hedgeRequestDrawer';
import { useOpenDrawer } from '@app/hooks';
import { useGetShipments } from './hooks/useGetShipments';
import { DataGridPremium, GridRowParams } from '@mui/x-data-grid-premium';
import { useState } from 'react';
import { IContractAdmin, IShipment } from '@app/types';
import { ScrapexColorEnum } from '@common/colors';
import { ShipmentInfoDrawer } from '../shipmentInfoDrawer';
import { useParams } from 'react-router-dom';
import { useGetCurrentContract } from 'src/accountManager/contract/hooks/useGetCurrentContract';
import { handleExport } from './export';
import { SaveAlt } from '@mui/icons-material';

export const ContractShipmentsTable = () => {
  const { contractId } = useParams();
  const { shipments, isLoading } = useGetShipments(contractId);
  const { contract } = useGetCurrentContract(contractId);

  const { columns } = generateShipmentColumns();
  const { rows } = generateShipmentRows(shipments);
  const [selectedShipment, setSelectedShipment] = useState<IShipment | null>(null);

  const { isOpen, open, close } = useOpenDrawer();

  const handleRowClick = (params: GridRowParams) => {
    setSelectedShipment(params.row);
    open();
  };
  return (
    <>
      {isLoading && <LoadingSkeleton />}
      {!isLoading && columns && (
        <TableContainer sx={{ background: ScrapexColorEnum.WHITE_COLOR }}>
          <div style={{ width: '1540px', overflowX: 'auto', height: '65vh' }}>
            <DataGridPremium
              sx={{
                background: '#fff',
                border: 'none',
                '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                  color: ScrapexColorEnum.FILL_TEXT_COLOR,
                  border: 'none',
                },
                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
              }}
              columnHeaderHeight={40}
              rows={rows}
              columns={columns}
              initialState={{
                pinnedColumns: {
                  left: ['ordinal_number', 'shipment_number', 'shipment_status', 'claims'],
                },
                sorting: {
                  sortModel: [{ field: 'ordinal_number', sort: 'asc' }],
                },
              }}
              sortingOrder={['desc', 'asc']}
              slots={{ toolbar: TableHeader }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  title: 'Shipments',
                  withExport: true,
                  exportTrigger: (
                    <Button
                      size="small"
                      startIcon={<SaveAlt fontSize="small" />}
                      onClick={() => handleExport(contract as IContractAdmin, shipments)}
                    >
                      Export
                    </Button>
                  ),
                },
              }}
              disableColumnFilter
              hideFooter
              onRowClick={handleRowClick}
              disableColumnMenu
            />
          </div>
        </TableContainer>
      )}
      <DrawerGeneral onClose={close} title="Shipment Info" open={isOpen}>
        {isOpen && selectedShipment && (
          <ShipmentInfoDrawer
            key={selectedShipment.id}
            shipmentId={selectedShipment.id}
            onClose={close}
          />
        )}
      </DrawerGeneral>
    </>
  );
};
