import { AppDispatch, RootState } from '@app/store';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getContractThunk } from 'src/accountManager/reducer/contracts/thunks';

export const useGetCurrentContract = (id?: string) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (id) {
      dispatch(getContractThunk({ id }));
    }
  }, [id]);

  const contract = useSelector(
    (state: RootState) => state.accountManager.contracts.currentContract,
  );
  const isLoading = useSelector((state: RootState) => state.accountManager.contracts.isLoading);

  return { contract, isLoading };
};
