import { IHedge } from '@app/types';

import { HedgeRequestDrawerACM } from './components/hedgeRequestDrawerACM';
import { HedgeRequestDrawerTP } from './components/hedgeRequestDrawerTP';
import { HedgeType } from './helpers';
import { HedgeRequestDrawerOLME } from './components/hedgeRequestDrawerOLME';

interface IHedgeRequestDrawerProps {
  hedge: IHedge;
  onClose: () => void;
}

export const HedgeRequestDrawer = ({ hedge, onClose }: IHedgeRequestDrawerProps) => {
  return (
    <>
      {hedge && (
        <>
          {hedge.hedge_type === HedgeType.ACM && (
            <HedgeRequestDrawerACM hedgeId={hedge.id} onClose={onClose} />
          )}
          {hedge.hedge_type === HedgeType.TakeProfit && (
            <HedgeRequestDrawerTP hedgeId={hedge.id} onClose={onClose} />
          )}
          {hedge.hedge_type === HedgeType.OLME && (
            <HedgeRequestDrawerOLME hedgeId={hedge.id} onClose={onClose} />
          )}
        </>
      )}
    </>
  );
};
