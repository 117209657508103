import { useGetShipmentFiles } from '@app/hooks/useGetShipmentFiles';
import { ArrowBack, ArrowForward, Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box } from '@mui/system';
import Carousel from 'nuka-carousel';
import { useEffect, useState } from 'react';

interface PhotoSliderProps {
  isOpen: boolean;
  onClose: () => void;
  images: string[];
  dealId: string;
  currentImageIndex: number;
}

export const PhotoSlider: React.FC<PhotoSliderProps> = ({
  isOpen,
  onClose,
  images,
  dealId,
  currentImageIndex,
}) => {
  const [currentSlide, setCurrentSlide] = useState(currentImageIndex);
  const [fileName, setFileName] = useState('');
  const { getFileName } = useGetShipmentFiles(dealId, images[currentSlide]);

  const getFullUrl = (filePath: string) => {
    const hostname =
      window.location.hostname === 'localhost' ? 'dev.scrapex.app' : window.location.hostname;
    return `https://${hostname}/api/admin/deals/${dealId}/files/${filePath}`;
  };
  const fullImageUrls = images.map((fileId) => getFullUrl(fileId));

  useEffect(() => {
    getFileName().then((name) => setFileName(name));
  }, [currentSlide]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{ style: { maxWidth: '1040px', width: '100%' } }}
    >
      <DialogContent sx={{ p: 0 }}>
        <Carousel
          slideIndex={currentImageIndex}
          afterSlide={(currentSlide) => setCurrentSlide(currentSlide)}
          wrapAround={true}
          renderBottomCenterControls={() => null}
          renderCenterLeftControls={() => null}
          renderCenterRightControls={() => null}
          renderTopLeftControls={() => (
            <Box
              sx={{
                height: '40px',
                width: '1040px',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {/* Box below for semi-transparent overlay on top of the slider */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'black',
                  opacity: 0.64,
                }}
              />
              <Box sx={{ zIndex: 1, maxWidth: 300, ml: 3 }}>
                <Typography
                  noWrap
                  sx={{ color: '#fff', textOverflow: 'ellipsis', overflow: 'hidden', fontSize: 16 }}
                >
                  {fileName}
                </Typography>
              </Box>
            </Box>
          )}
          renderTopCenterControls={({ currentSlide, slideCount }) => (
            <Box sx={{ height: '40px', display: 'flex', alignItems: 'center' }}>
              <span style={{ color: 'white', margin: '10px' }}>
                {currentSlide + 1}/{slideCount}
              </span>
            </Box>
          )}
          renderTopRightControls={({ nextSlide, previousSlide }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <IconButton onClick={previousSlide}>
                <ArrowBack style={{ color: 'white' }} />
              </IconButton>
              <IconButton onClick={nextSlide}>
                <ArrowForward style={{ color: 'white' }} />
              </IconButton>
              <IconButton onClick={onClose}>
                <Close style={{ color: 'white' }} />
              </IconButton>
            </Box>
          )}
          style={{ background: 'black' }}
        >
          {fullImageUrls.map((image, index) => (
            <div key={index} style={{ height: '777px', width: '1040px' }}>
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            </div>
          ))}
        </Carousel>
      </DialogContent>
    </Dialog>
  );
};
