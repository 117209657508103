import { Header } from './components/header';
import { Sidebar } from './components/sidebar';

import classes from './styles.module.scss';

export interface ILayout {
  children: React.ReactNode | React.ReactNode[];
}

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={classes.accountLayout}>
      <div className={classes.header}>
        <Header />
      </div>
      <div className={classes.sidebar}>
        <Sidebar />
      </div>
      <div className={classes.children}>{children}</div>
    </div>
  );
};
