import { Box, IconButton, InputAdornment, Link, OutlinedInput, TextField } from '@mui/material';
import { BaseSyntheticEvent, useState } from 'react';
import { InputContainer } from '../components/inputContainer';

interface PasswordInputProps {
  name: string;
  value?: string;
  onChange?: (value: BaseSyntheticEvent) => void;
  label?: string;
  error?: string;
  touched?: boolean;
  fullWidth?: boolean;
  onClickForgotPassword?: () => void;
}

export const PassowrdInputControlled = ({
  name,
  label,
  value,
  onChange,
  touched,
  error,
  fullWidth = false,
  onClickForgotPassword,
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: BaseSyntheticEvent) => {
    event.preventDefault();
  };
  return (
    <InputContainer>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <label htmlFor={name}>{label}</label>
        {onClickForgotPassword && (
          <Link sx={{ mb: '8px' }} onClick={onClickForgotPassword}>
            Forgot password?
          </Link>
        )}
      </Box>
      <OutlinedInput
        id={name}
        name={name}
        onChange={onChange}
        value={value}
        fullWidth={fullWidth}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              sx={{ fontSize: '16px', borderRadius: '4px', height: 'auto' }}
            >
              {showPassword ? 'Hide' : 'Show'}
            </IconButton>
          </InputAdornment>
        }
      />
      {touched && error && <p>{error}</p>}
    </InputContainer>
  );
};
