import httpClient from '@app/client/httpClient';

export const getToken = async (role: string) => {
  try {
    const response = await httpClient.get<{ token: string }>(`api/upload/${role}/token`);
    return response.data.token;
  } catch (error) {
    console.error('Error fetching token:', error);
    throw error;
  }
};

export const getFilesUrl = async (shipmentId: string, dealId: string, role: string) => {
  try {
    const hostname =
      window.location.hostname === 'localhost' ? 'dev.scrapex.app' : window.location.hostname;

    const token = await getToken(role);

    const filesUrl = `https://${hostname}/api/upload/${role}/deals/${dealId}/shipments/${shipmentId}/files/allfiles?auth_token=${token}`;

    return filesUrl;
  } catch (err) {
    throw err;
  }
};

export const getFileUrl = async (dealId: string, fileId: string, role: string) => {
  try {
    const hostname =
      window.location.hostname === 'localhost' ? 'dev.scrapex.app' : window.location.hostname;

    const token = await getToken(role);

    const link = `https://${hostname}/api/upload/${role}/deals/${dealId}/files/${fileId}?auth_token=${token}`;
    return link;
  } catch (err) {
    throw err;
  }
};
