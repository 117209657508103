import classes from './styles.module.scss';
import { WhyChooseScrapex } from './WhyChooseScrapex';
import { FAQ } from './FAQ';
import { Numbers } from './Numbers';
import { Benefits } from './Benefits';
import { Header } from './Header';
import { Hero } from './Hero';
import { HowItWorks } from './HowItWorks';
import { Experience } from './Experience';
import { AboutUs } from './AboutUs';
import { Contact } from './Contact';
import { Footer } from './Footer';

export const Landing = () => {
  return (
    <div className={classes.landing}>
      <Header />
      <Hero />
      <WhyChooseScrapex />
      <HowItWorks />
      <Benefits />
      <Experience />
      <AboutUs />
      <Numbers />
      <FAQ />
      <Contact />
      <Footer />
    </div>
  );
};
