import classes from './styles.module.scss';

export const Contact = () => {
  return (
    <section id="contact" className={`${classes.contact} `}>
      <main>
        <div className={classes.title}>
          <h2>Get In Touch</h2>
        </div>
        <div className={classes.wrapper}>
          <div className={classes.info}>
            <p className={classes.phone}>+971 58 558 4862</p>
            <p className={classes.email}>info@scrapex.ae</p>
          </div>
          <div>
            {' '}
            <p className={classes.address}>
              SCRAPEX DMCC
              <br />
              2906, HDS Tower, Cluster F, JLT
              <br />
              Dubai, United Arab Emirates
              <br />
              Licence: DMCC-898393
              <br />
              Registration number: DMCC197766
            </p>
          </div>
          {/* <div className={classes.form}>
            <form>
              <input type="email" placeholder="Your Email" required />
              <input type="text" placeholder="Your Full Name" required />
              <textarea placeholder="Your comments" required></textarea>
              <button type="submit">Send</button>
            </form>
          </div> */}
        </div>
      </main>
    </section>
  );
};
