import { Alert, Avatar, Badge, Box, Button, IconButton } from '@mui/material';
import { ScrapexBodyText, ScrapexBodyTextMedium, ScrapexGrayText } from '@common/components';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import { toggleReadThunk } from 'src/account/reducer/notifications/thunk';
import { INotification } from '@app/types/api';
import { formatDate, formatTimeWithoutGMT } from '@app/utils';
import { ScrapexColorEnum } from '@common/colors';

interface INotificationItemProps {
  notification: INotification;
  isHistory?: boolean;
}

export const NotificationItem = ({ notification, isHistory = false }: INotificationItemProps) => {
  const { header, message, type, title, sub_title, created_at } = notification;
  const dispatch = useDispatch<AppDispatch>();

  const handleRead = () => {
    dispatch(toggleReadThunk(notification.id));
  };

  return (
    <Alert
      icon={
        <Box display="flex" alignItems="center">
          <Avatar sx={{ backgroundColor: '#F5F5F5', color: '#0C2452' }}>
            {type.substring(0, 1).toUpperCase() || ''}
          </Avatar>
          {!isHistory && (
            <Badge
              variant="dot"
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '& .MuiBadge-badge': {
                  borderRadius: '100%',
                  height: 10,
                  width: 10,
                  backgroundColor: '#FF0000',
                  mt: '-17px',
                  mr: '5px',
                },
              }}
            />
          )}
        </Box>
      }
      sx={{
        boxShadow: 'none',
        borderRadius: 'unset',
        backgroundColor: 'transparent',
        '.MuiAlert-message': {
          width: '100%',
        },
      }}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column" width="100%">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center" gap={1}>
                <ScrapexBodyTextMedium>{title}</ScrapexBodyTextMedium>
                <span
                  style={{
                    textAlign: 'center',
                    fontSize: '14px',

                    color: ScrapexColorEnum.FILL_TEXT_COLOR,
                  }}
                >
                  •
                </span>
                <ScrapexGrayText>
                  {formatDate(created_at)} {formatTimeWithoutGMT(created_at)}{' '}
                </ScrapexGrayText>
              </Box>
              {!isHistory && (
                <Button variant="text" onClick={handleRead} sx={{ textTransform: 'none' }}>
                  <ScrapexGrayText>Mark as read</ScrapexGrayText>
                </Button>
              )}
            </Box>
            <ScrapexGrayText>{sub_title}</ScrapexGrayText>
          </Box>
        </Box>
        <ScrapexBodyText> {header}</ScrapexBodyText>
        <span
          style={{
            color: ScrapexColorEnum.MAIN_TEXT_COLOR,
            lineHeight: '18px',
            whiteSpace: 'pre-line', // for newline from backend
          }}
        >
          {message}
        </span>
      </Box>
    </Alert>
  );
};
