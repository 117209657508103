import { Layout } from '@common/components';
import { ContractHeader } from './components/contractHeader';
import { Box } from '@mui/material';
import { ContractShipments } from './components/shipments';
import { HedgeFooter } from './components/shipments/hedging/components/hedgeFooter';

export const Contract = () => {
  return (
    <Layout>
      <Box>
        <Box sx={{ padding: '20px 40px' }}>
          <ContractHeader />
          <ContractShipments />
        </Box>
        <Box sx={{ position: 'sticky', bottom: 0 }}>
          <HedgeFooter />
        </Box>
      </Box>
    </Layout>
  );
};
