import { Box } from '@mui/material';
import { HedgeRequestsTable } from '../hedgeRequests/components/table';

export const HedgeRequestsTest = () => {
  return (
    <Box sx={{ padding: '20px 40px' }}>
      <HedgeRequestsTable test />
    </Box>
  );
};
