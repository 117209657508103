import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, IconButton, Popover } from '@mui/material';

import { actions as auth } from 'src/auth/reducer/auth/slice';
import { actions as company } from 'src/account/reducer/company/slice';
import { actions as layout } from '@app/layout/reducer/slice/slice';

import { AppDispatch } from '@app/store';

import { AuthRoutes } from '@app/types/navigation';

export const LayoutSettings = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'settings' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExit = () => {
    dispatch(auth.reset());
    dispatch(company.reset());
    dispatch(layout.reset());

    localStorage.setItem('jwt', '');
    navigate(AuthRoutes.LOGIN);
  };
  return (
    <>
      <IconButton
        sx={{ color: '#fff' }}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        aria-describedby={id}
      >
        <ArrowDropDownIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        id={id}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Button onClick={handleExit}>Exit</Button>
      </Popover>
    </>
  );
};
