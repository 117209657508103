import { useOpenDrawer } from '@app/hooks';

export const useHedgeDrawers = () => {
  const hedgeCreateDrawer = useOpenDrawer();
  const hedgeRequestDrawer = useOpenDrawer();

  const closeAllHandler = () => {
    hedgeCreateDrawer.close();
    hedgeRequestDrawer.close();
  };

  return {
    hedgeCreateDrawer,
    hedgeRequestDrawer,
    closeAllHandler,
  };
};
