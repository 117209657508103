import { Box } from '@mui/system';
import classes from './styles.module.scss';
import { NavigateIconButton } from 'src/accountManager/common/navigateIconButton';
import { Typography } from '@mui/material';

export const ContractNumber = ({
  contractNumber,
  contractId,
}: {
  contractNumber: string;
  contractId: string;
}) => {
  return (
    <Box className={classes.number}>
      <Typography noWrap sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: 14 }}>
        {contractNumber}
      </Typography>
      <NavigateIconButton id={contractId} />
    </Box>
  );
};
