import { IHedge } from '@app/types';
import { createSlice } from '@reduxjs/toolkit';
import {
  getActiveHedgeThunk,
  getHedgeByIdThunk,
  getQuotationThunk,
  initHedgeThunk,
  createHedgeThunk,
  setAnswerToQuotationThunk,
  rejectOrderThunk,
  getHedgeHistoryThunk,
} from './thunk';

interface IHedgingSlice {
  selectedShipments: string[] | null;
  isSelectMode: boolean;
  currentHedge: IHedge | null;
  hedgeHistory: IHedge[];
  isLoading: boolean;
  marketToken: null | string;
}

const initialState: IHedgingSlice = {
  selectedShipments: null,
  isSelectMode: false,
  currentHedge: null,
  hedgeHistory: [],
  isLoading: false,
  marketToken: null,
};

const hedgingSlice = createSlice({
  name: 'userHedgingSlice',
  initialState,
  reducers: {
    reset(state) {
      return {
        ...initialState,
        marketToken: state.marketToken, // keep current marketToken
      };
    },
    resetCurrentHedge(state) {
      state.currentHedge = null;
    },
    selectShipments: (state, action) => {
      state.selectedShipments = action.payload;
    },

    enableSelect: (state) => {
      state.isSelectMode = true;
    },
    disableSelect: (state) => {
      state.isSelectMode = false;
      state.selectedShipments = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initHedgeThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentHedge = payload;
        state.isSelectMode = false;
      }
    });

    builder.addCase(createHedgeThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentHedge = payload;
      }
    });
    builder.addCase(getActiveHedgeThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentHedge = payload;
      }
    });
    builder.addCase(getQuotationThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentHedge = payload;
      }
    });
    builder.addCase(setAnswerToQuotationThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentHedge = payload;
      }
    });
    builder.addCase(rejectOrderThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentHedge = payload;
      }
    });

    builder.addCase(getHedgeByIdThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentHedge = payload;
      }
    });
    builder.addCase(getHedgeHistoryThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.hedgeHistory = payload.hedges;
      }
    });
    builder.addCase(getHedgeHistoryThunk.pending, (state, { payload }) => {
      state.isLoading = true;
    });
  },
});

export const { actions, reducer } = hedgingSlice;
export default hedgingSlice.reducer;
