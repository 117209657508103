import { Box, Typography } from '@mui/material';

interface ITabTitleProps {
  title: string;
  count: number;
}
export const TabTitle = ({ title, count }: ITabTitleProps) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography fontSize={14}>{title}</Typography>
      {!!count && (
        <Box
          sx={{
            width: 'auto',
            height: 16,
            borderRadius: '80px',
            backgroundColor: '#F44336',
            color: '#fff',
            fontSize: 12,
            overflow: 'hidden',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 4px',
          }}
        >
          {count}
        </Box>
      )}
    </Box>
  );
};
