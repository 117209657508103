// import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { fetchStartedAuthRole } from '../../auth/reducer/auth/thunks';
import { AppDispatch } from '../store';
// import { HttpClient } from "./httpClient";

interface IHttpProvider {
  children: JSX.Element;
}

export const HttpProvider = ({ children }: IHttpProvider) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchStartedAuthRole({ navigate }));
  }, []);

  return children;
};
