import { Box } from '@mui/material';
import { HedgeAtWorkTable } from './components/table';

export const HedgeAtWork = () => {
  return (
    <Box sx={{ padding: '20px 40px' }}>
      <HedgeAtWorkTable />
    </Box>
  );
};
