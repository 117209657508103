import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../reducer/auditCompanies/slice';
import CompaniesTable from './components/companiesTable';

export const Companies = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.reset());
  }, []);

  return (
    <Box p="20px 40px">
      <CompaniesTable />
    </Box>
  );
};
