import * as React from 'react';
import dayjs, { type Dayjs } from 'dayjs';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from '@mui/x-date-pickers/models';
import { formatDate } from '@app/utils';

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;

  return (
    <Button
      variant="outlined"
      color="warning"
      id={id}
      disabled={disabled}
      ref={ref}
      size="small"
      sx={{ borderRadius: '800px' }}
      aria-label={ariaLabel}
      onClick={(e) => {
        e.stopPropagation();
        setOpen?.((prev) => !prev);
      }}
    >
      {label ? label : 'Pick a date'}
    </Button>
  );
}

export const ButtonDatePicker = (
  props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>,
) => {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } as any }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
};

interface PickerWithButtonFieldProps {
  onHandleUpdateShipment: (date: string) => void;
  initialDate?: string;
}
export const LimitFixationDate = ({
  onHandleUpdateShipment,
  initialDate,
}: PickerWithButtonFieldProps) => {
  const [value, setValue] = React.useState<Dayjs | null>(initialDate ? dayjs(initialDate) : null);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ButtonDatePicker
        label={value == null ? null : formatDate(value.toString())}
        value={value}
        onChange={(newValue: Dayjs | null) => {
          setValue(newValue);
          const formattedDate = newValue?.format('YYYY-MM-DD');
          onHandleUpdateShipment(formattedDate as string);
        }}
        // disablePast
      />
    </LocalizationProvider>
  );
};
