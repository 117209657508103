import { IMarketMessage } from '@app/types';
import { createSlice } from '@reduxjs/toolkit';

interface IMarketData {
  marketData: null | IMarketMessage;
  previousMarketData: null | IMarketMessage;
}

const initialState: IMarketData = {
  marketData: null,
  previousMarketData: null,
};

const marketSlice = createSlice({
  name: 'marketSlice',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    marketDataUpdate(state, action) {
      state.previousMarketData = state.marketData;
      state.marketData = action.payload;
    },
  },
});

export const { marketDataUpdate } = marketSlice.actions;
export default marketSlice.reducer;
