import { Box, Typography } from '@mui/material';
import React from 'react';

interface INotificationUnreadInfoProps {
  bg: string;
  quantity: number;
  type: string;
}

export const NotificationUnreadInfo = ({ bg, quantity, type }: INotificationUnreadInfoProps) => {
  return (
    <Box
      sx={{
        width: 51,
        height: 16,
        borderRadius: '80px',
        backgroundColor: bg,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography fontSize={8}>
        {quantity} {type}
      </Typography>
    </Box>
  );
};
