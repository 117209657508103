import classes from './styles.module.scss';

const businessOptions = [
  {
    title: 'Scrap Suppliers',
    points: [
      {
        title: 'Access to LME Hedging',
        description: 'Provides hedging for small and medium-sized scrap suppliers',
      },
      {
        title: 'Business Safety',
        description: 'Saves deal margin, regardless of market fluctuation',
      },
      {
        title: 'Margin Growth',
        description: 'Risk-free access to high-margin formula deals',
      },
      {
        title: 'Volume Growth',
        description: 'Helps increase the volume of deals because of added formula-based deals',
      },
      {
        title: 'Cost efficiency and flexibility',
        description: 'Min hedging lot is 1MT',
      },
      {
        title: 'Digital Hedging Platform',
        description:
          'All fixation requests, orders, and confirmations are in one place. No phone, WeChat, WhatsApp requests to fix the price',
      },
    ],
    highlight: 'Suppliers',
  },
  {
    title: 'Scrap Consumers',
    points: [
      {
        title: 'LME Hedging',
        description: 'Access to hedging through LME for companies across the Globe',
      },
      {
        title: 'Hedging Limits Growth',
        description: 'Additional hedging quota',
      },
      {
        title: 'Margin Growth',
        description: 'Risk-free access to high-margin SPOT deals',
      },
      {
        title: 'Volume Growth',
        description: 'Helps increase the volume of business because the additional SPOT deals',
      },
      {
        title: 'Cost efficiency and flexibility',
        description: 'Min hedging lot is 1MT',
      },
      {
        title: 'Digital Hedging Platform',
        description:
          'All fixation requests, orders, and confirmations are in one place. No phone, WeChat, WhatsApp requests to fix the price',
      },
      {
        title: 'Business Safety',
        description: 'Saves deal margin regardless of market fluctuation.',
      },
    ],
    highlight: 'Consumers',
  },
  {
    title: 'Scrap Traders',
    points: [
      {
        title: 'Digital Hedging Platform',
        description:
          'All fixation requests, orders, and confirmations are in one place. No phone, WeChat, WhatsApp requests to fix the price',
      },
      {
        title: 'Margin Growth',
        description: 'Risk-free access to more marginal SPOT-Formula deals',
      },
      {
        title: 'Volume Growth',
        description:
          'Helps increase the volume of business because of the additional SPOT-formula deals',
      },
      {
        title: 'Hedging Limits Increase',
        description: 'Additional hedging quota',
      },
      {
        title: 'Cost efficiency and flexibility',
        description: 'Min hedging lot is 1MT',
      },
      {
        title: 'Business Safety',
        description: 'Saves deal margin regardless of market fluctuation.',
      },
    ],
    highlight: 'Traders',
  },
];

export const WhyChooseScrapex = () => {
  return (
    <section className={classes.whyChoose} id="why">
      <h2 className={classes.title}>
        Why businesses choose Scrap<span className={classes.highlight}>EX</span>
      </h2>
      <div className={classes.columns}>
        {businessOptions.map((option, index) => (
          <div
            className={`${classes.column} ${index === 1 ? classes.tallerColumn : ''}`}
            key={index}
          >
            <h3>
              Scrap <span className={classes.highlight}>{option.highlight}</span>
            </h3>
            <ul>
              {option.points.map((point, idx) => (
                <li key={idx}>
                  <strong>{point.title}:</strong> {point.description}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </section>
  );
};
