import { Box, Drawer, SxProps, IconButton } from '@mui/material';
import { ReactNode } from 'react';
import { ArrowBackOutlined } from '@mui/icons-material';

interface DrawerGeneralProps {
  title?: string;
  open: boolean;
  onClose?: () => void;
  children: ReactNode | ReactNode[];
  hideBackdrop?: boolean;
  isBackButton?: boolean;
}

const buttonStyles: SxProps = {
  position: 'absolute',
  top: '30px',
};

export const DrawerGeneral = ({
  open,
  onClose,
  children,
  title = '',
  isBackButton = false,
  hideBackdrop = false,
}: DrawerGeneralProps) => {
  return (
    <Drawer hideBackdrop={hideBackdrop} anchor="right" open={open} onClose={onClose}>
      <Box width="620px" height="100%">
        <Box padding="60px 40px 0 40px" sx={{ position: 'relative' }}>
          {isBackButton && (
            <IconButton onClick={onClose} sx={{ ...buttonStyles, left: '40px' }}>
              <ArrowBackOutlined />
            </IconButton>
          )}
        </Box>
        {children}
      </Box>
    </Drawer>
  );
};
