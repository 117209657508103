import { IContractUser } from '@app/types';
import { ContractRole, ScrapexGrayText } from '@common/components';
import { Box, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { calculateEstimatedShipmentWeight } from 'src/accountManager/contract/utlis/calculateEstimatedShipmentWeight';
//TODO: move it to common
interface IContractRowData {
  value: string | ReactNode;
  sx?: SxProps;
}

export const generateContractRowData = (contract: IContractUser | null): IContractRowData[] => {
  const estimatedShipmentWeight = calculateEstimatedShipmentWeight(
    contract?.product_weight,
    contract?.total_shipments,
  );
  const availableShipmentsForHedge =
    (contract?.total_shipments || 0) -
    (contract?.shipments_in_orders || 0) -
    (contract?.hedged_shipments || 0) -
    (contract?.request_shipments || 0);
  return [
    {
      value: <ContractRole role={contract?.customer_role} />,
    },
    {
      value: (
        <Box>
          <h6>{contract?.product}</h6>
          <ScrapexGrayText>{contract?.metal}</ScrapexGrayText>
        </Box>
      ),
    },
    {
      value: contract?.formula,
    },
    {
      value: contract?.product_weight,
    },
    {
      value: (
        <Box>
          <Typography>{contract?.total_shipments} Shipments</Typography>
          <ScrapexGrayText>{estimatedShipmentWeight} = 1 shipment</ScrapexGrayText>
        </Box>
      ),
    },
    {
      value: (
        <Box>
          <Typography>{contract?.hedged_shipments}</Typography>
          <ScrapexGrayText>
            {contract?.shipments_in_orders} in orders, {contract?.request_shipments} in request
          </ScrapexGrayText>
        </Box>
      ),
    },
    {
      value: `${availableShipmentsForHedge} Shipments`,
    },

    {
      value: (
        <Box>
          <Typography>{contract?.completed_shipments}</Typography>
          <ScrapexGrayText>{contract?.shipments_delivery_in_progress} in progress</ScrapexGrayText>
        </Box>
      ),
    },
  ];
};
