import { AppDispatch, RootState } from '@app/store';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getShipmentThunk } from 'src/accountManager/reducer/shipments/thunks';
import { getHedgeByIdThunk } from 'src/hedgeAdmin/reducer/hedging/thunk';
import { actions } from 'src/hedgeAdmin/reducer/hedging/slice';

export const useGetCurrentHedge = (hedgeId?: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const hedge = useSelector((state: RootState) => state.hedgingAdmin.hedging.currentHedge);

  useEffect(() => {
    dispatch(actions.resetCurrentHedge());

    const fetchHedge = async () => {
      setLoading(true);
      setError(null);

      try {
        if (hedgeId) {
          await dispatch(getHedgeByIdThunk(hedgeId));
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setLoading(false);
      }
    };

    fetchHedge();
  }, [hedgeId]);

  return { hedge, loading, error };
};
