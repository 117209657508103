import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IListOfCompaniesResponse } from '../../../types/api/companies';

interface ICompanySidebarCompanyInformation {
  id: string;
  name: string;
}

interface ILayoutState {
  companiesList: ICompanySidebarCompanyInformation[] | null;
}

const initialState: ILayoutState = {
  companiesList: null,
};

const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState,
  reducers: {
    setCompanySidebarInformation(state, { payload }: PayloadAction<IListOfCompaniesResponse[]>) {
      state.companiesList = payload.map((company) => {
        return { id: company.id, name: company.name };
      });
    },
    reset(): ILayoutState {
      return initialState;
    },
  },
});

export const { reducer, actions } = layoutSlice;
export default layoutSlice;
