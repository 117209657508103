const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export function formatDate(inputDate: string) {
  const date = new Date(inputDate);

  const day = date.getDate().toString().padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export function formatTime(inputDate: string) {
  const date = new Date(inputDate);

  const offset = extractGMTOffset(date.toString());

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes} GMT ${offset}`;
}

export function extractGMTOffset(inputDate: string) {
  const regex = /GMT([-+]\d{2})(\d{2})/;
  const match = regex.exec(inputDate);
  if (match && match[1] && match[2]) {
    return `${match[1]}:${match[2]}`; // Separate offset with :
  } else {
    return null;
  }
}

export function formatTimeWithoutGMT(inputDate: string) {
  const date = new Date(inputDate);

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes} `;
}
