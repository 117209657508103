import { HedgingStatus, IHedgingDetails } from '@app/types';
import { formatDate, hedgeTypeMap } from '@app/utils';
import { ScrapexColorEnum } from '@common/colors';
import {
  ScrapexBodyTextMedium,
  ScrapexGrayText,
  ScrapexSidebarMediumTextH2,
  StyledTooltip,
} from '@common/components';
import { hedgeStatusMap } from '@common/components/display/hedging/status';
import { HedgeNumberColored } from '@common/components/display/text';
import { CheckCircle } from '@mui/icons-material';
import { Box } from '@mui/system';

//TODO: move it to common and refactor

export const renderDoneIconOrNone = (value: string) =>
  value ? <CheckCircle color="success" /> : <ScrapexGrayText>None</ScrapexGrayText>;

export const renderValueOrNone = (value: string | number | null) => {
  if (value === null || value === undefined) {
    return <ScrapexGrayText>None</ScrapexGrayText>;
  }
  if (typeof value === 'number') {
    return Number.isInteger(value) ? value : value.toFixed(2);
  }

  return value;
};

export const renderDateOrNone = (value: string) =>
  value ? formatDate(value) : <ScrapexGrayText>None</ScrapexGrayText>;

export const renderQuantityOrNone = (value: string[]) =>
  value && value.length > 0 ? value.length : <ScrapexGrayText>None</ScrapexGrayText>;

export const renderEstimatedOrFinalWeight = (
  finalWeight: number | undefined,
  estimatedWeight: number,
) => {
  const formatWeight = (weight: number) => {
    return Number.isInteger(weight) ? weight.toString() : weight.toFixed(3);
  };

  if (finalWeight) {
    return <ScrapexBodyTextMedium>{formatWeight(finalWeight)}</ScrapexBodyTextMedium>;
  }

  return (
    <Box display="flex" flexDirection="column">
      <ScrapexBodyTextMedium>{formatWeight(estimatedWeight)}</ScrapexBodyTextMedium>
      <ScrapexGrayText>Estimated</ScrapexGrayText>
    </Box>
  );
};

export const renderEstimatedOrFinalWeightInDrawer = (
  finalWeight: number | undefined,
  estimatedWeight: number,
) => {
  if (finalWeight) {
    return <ScrapexSidebarMediumTextH2>{finalWeight}</ScrapexSidebarMediumTextH2>;
  }
  return (
    <Box display="flex" flexDirection="column">
      <ScrapexSidebarMediumTextH2>{estimatedWeight}</ScrapexSidebarMediumTextH2>
      <ScrapexGrayText>Estimated</ScrapexGrayText>
    </Box>
  );
};

export const renderValueOrNoneInDrawer = (value: string | number) => {
  if (typeof value === 'number') {
    return Number.isInteger(value) ? (
      <ScrapexSidebarMediumTextH2>{value}</ScrapexSidebarMediumTextH2>
    ) : (
      <ScrapexSidebarMediumTextH2>{value.toFixed(2)}</ScrapexSidebarMediumTextH2>
    );
  }
  return <ScrapexSidebarMediumTextH2>{value}</ScrapexSidebarMediumTextH2>;
};

export const renderDateOrNoneInDrawer = (value: string) =>
  value ? (
    <ScrapexSidebarMediumTextH2>{formatDate(value)}</ScrapexSidebarMediumTextH2>
  ) : (
    <ScrapexGrayText>None</ScrapexGrayText>
  );

export const renderHedgingDetails = (hedgingDetails: IHedgingDetails) => {
  if (!hedgingDetails) {
    return (
      <Box display="flex" alignItems={'center'} gap={1}>
        <span
          style={{
            color: ScrapexColorEnum.FILL_TEXT_COLOR,
            fontWeight: 400,
            fontSize: '20px',
          }}
        >
          •
        </span>
        <ScrapexGrayText>No hedging</ScrapexGrayText>
      </Box>
    );
  }
  const { hedge_number, hedge_type, hedge_status } = hedgingDetails;
  const normalizedStatus = hedgeStatusMap[hedge_status as HedgingStatus];

  return (
    <Box display="flex" alignItems="center">
      <span
        style={{ textAlign: 'center', fontSize: '20px', color: ScrapexColorEnum.FILL_TEXT_COLOR }}
      >
        •
      </span>
      <Box>
        <StyledTooltip title={`${hedgeTypeMap[hedge_type]}`}>
          <Box display="flex" gap={0.5} alignItems={'center'}>
            <HedgeNumberColored hedgeNumber={hedge_number} status={hedge_status as HedgingStatus} />
            <ScrapexGrayText>{hedge_type.toUpperCase()}</ScrapexGrayText>
          </Box>
        </StyledTooltip>
        <ScrapexGrayText>{normalizedStatus}</ScrapexGrayText>
      </Box>
    </Box>
  );
};

export const renderHedgingDetailsDrawer = (hedgingDetails: IHedgingDetails) => {
  if (!hedgingDetails) {
    return (
      <Box display="flex" alignItems={'center'} gap={1}>
        <ScrapexGrayText>No hedging</ScrapexGrayText>
      </Box>
    );
  }
  const { hedge_number, hedge_status } = hedgingDetails;
  const normalizedStatus = hedgeStatusMap[hedge_status as HedgingStatus];

  return (
    <Box display="flex" alignItems="start" flexDirection={'column'}>
      <HedgeNumberColored hedgeNumber={hedge_number} status={hedge_status as HedgingStatus} />
      <ScrapexGrayText>{normalizedStatus}</ScrapexGrayText>
    </Box>
  );
};
