import { Box, SxProps } from '@mui/material';
import { StyledTooltip } from '../../tooltip';

export const ContractRole = ({ role }: { role: string | undefined }) => {
  const roleFirstLetter = role?.substring(0, 1).toUpperCase();
  const isBuyer = roleFirstLetter === 'B';
  const roleStyles: SxProps = {
    borderRadius: '50%',
    fontSize: '12px',
    fontWeight: '500',
    padding: '6px 8px',
    lineHeight: '12px',
    display: 'inline-block',
    color: isBuyer ? 'var(--blue-text-color)' : 'var(--purple-500)',
    backgroundColor: isBuyer ? 'var(--light-blue-color)' : 'var(--purple-50)',
  };
  return (
    <StyledTooltip title={role as string}>
      <Box sx={roleStyles}>{roleFirstLetter}</Box>
    </StyledTooltip>
  );
};
