import { TableHeader } from '../../../common/tableHeader';
import { DrawerType } from '@app/types/common';

import { useGetContracts } from './hooks/useGetContracts';
import { Button, TableContainer } from '@mui/material';
import { ScrapexColorEnum } from '@common/colors';
import { DataGridPremium, GridRowParams } from '@mui/x-data-grid-premium';
import { DrawerGeneral } from '@common/components';
import { ContractInfoDrawer } from '../contractInfoDrawer';
import { useState } from 'react';
import { IContractAdmin } from '@app/types';
import { useOpenDrawer } from '@app/hooks';
import { SaveAlt } from '@mui/icons-material';
import { hostname } from '@app/utils/hostname';

export default function ContractsTable() {
  const { columns, rows } = useGetContracts();
  const { isOpen, open, close } = useOpenDrawer();

  const [selectedContract, setSelectedContract] = useState<IContractAdmin | null>(null);

  const handleRowClick = (params: GridRowParams) => {
    setSelectedContract(params.row);
    open();
  };

  const exportLink = `https://${hostname}/api/admin/deals/export`;

  return (
    <TableContainer sx={{ background: ScrapexColorEnum.WHITE_COLOR, maxHeight: '100vh' }}>
      <div style={{ height: '85vh' }}>
        <DataGridPremium
          sx={{
            border: 'none',
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
              outline: 'none',
            },

            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-columnHeaders': {
              color: '#8592A8',
              background: '#F9FAFB',
              border: 'none',
            },
          }}
          columnHeaderHeight={40}
          slots={{ toolbar: TableHeader }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'contract_date', sort: 'desc' }],
            },
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              title: 'Contracts',
              buttonText: 'Create new contract',
              drawerTitle: 'Create new contract',
              drawerType: DrawerType.Contract,
              showButton: true,
              withExport: true,
              exportTrigger: (
                <Button size="small" startIcon={<SaveAlt fontSize="small" />} href={exportLink}>
                  Export
                </Button>
              ),
            },
          }}
          sortingOrder={['desc', 'asc']}
          rows={rows}
          columns={columns}
          // disableColumnFilter
          hideFooter
          onRowClick={handleRowClick}
          disableColumnMenu
          // disableRowSelectionOnClick
        />
      </div>
      <DrawerGeneral onClose={close} title="Contract Info" open={isOpen}>
        {isOpen && selectedContract && (
          <ContractInfoDrawer contractId={selectedContract.id} onClose={close} />
        )}
      </DrawerGeneral>
    </TableContainer>
  );
}
