import {
  createCompany,
  deleteCompany,
  getCompanies,
  getCompanyById,
  updateCompany,
} from '@api/companies';
import { ICompanyInfo } from '@app/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getCompaniesThunk = createAsyncThunk('getCompanies', async () => {
  try {
    const res = await getCompanies();
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

export const createCompanyThunk = createAsyncThunk(
  'createCompany',
  async (companyData: ICompanyInfo) => {
    try {
      const res = await createCompany(companyData);
      return res.data;
    } catch (error) {
      console.error('Error creating company:', error);
      throw error;
    }
  },
);

export const updateCompanyThunk = createAsyncThunk(
  'updateCompany',
  async ({ id, updatedData }: { id: string; updatedData: ICompanyInfo }, thunkAPI) => {
    try {
      const res = await updateCompany(id, updatedData);
      thunkAPI.dispatch(getCompanyByIdThunk({ id }));
      return res.data;
    } catch (error) {
      console.error('Error updating company:', error);
      throw error;
    }
  },
);

export const getCompanyByIdThunk = createAsyncThunk(
  'company/getCompany',
  async ({ id }: { id: string }) => {
    try {
      const response = await getCompanyById(id);
      return response.data;
    } catch (error) {
      console.error('Error getting company by company ID:', error);
      throw error;
    }
  },
);

export const deleteCompanyThunk = createAsyncThunk(
  'companies/deleteCompany',
  async (companyId: string) => {
    try {
      await deleteCompany(companyId);
    } catch (error) {
      console.error('Error deleting company:', error);
      throw error;
    }
  },
);
