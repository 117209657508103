import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assocPath, pipe } from 'ramda';
import { ICompanyByIdResponse } from '../../../app/types/api/companies';
import { IBankDetails, IMainInfo } from '../../../app/types/companies';
import { ICompanyAccountState } from '../../../app/types/companyAccount';

const initialState: ICompanyAccountState = {
  bank_details: {
    address: {
      address1: '',
      city: '',
      country: '',
      postal: '',
      state: '',
      address2: '',
    },
    account_number: '',
    country: '',
    details: '',
    name: '',
  },
  id: '',
  mainInfo: {
    name: '',
    registration_code: '',
    vat_id: '',
    address: {
      address1: '',
      city: '',
      country: '',
      postal: '',
      state: '',
      address2: '',
    },
    contact: {
      email: '',
      phone: '',
      phone2: '',
    },
  },
  approved: false,
  published: false,
  kyc: {
    documents: [],
  },
};

const companySlice = createSlice({
  name: 'companySlice',
  initialState,
  reducers: {
    setCompany(state, { payload }: PayloadAction<ICompanyByIdResponse>) {
      if (payload) {
        state.approved = payload.approved;
        state.published = payload.published;
        state.mainInfo = pipe<any, IMainInfo, IMainInfo, IMainInfo, IMainInfo, IMainInfo>(
          assocPath(['name'], payload.name),
          assocPath(['registration_code'], payload.registration_code),
          assocPath(['vat_id'], payload.vat_id),
          assocPath(['address'], payload.address),
          assocPath(['contact'], payload.contact),
        )(state.mainInfo);
        if (payload.bank) {
          state.bank_details = pipe<
            any,
            IBankDetails,
            IBankDetails,
            IBankDetails,
            IBankDetails,
            IBankDetails
          >(
            assocPath(['address'], payload.bank.address),
            assocPath(['account_number'], payload.bank.account_number),
            assocPath(['country'], payload.bank.country),
            assocPath(['details'], payload.bank.details),
            assocPath(['name'], payload.bank.name),
          )(state.bank_details);
        }
        if (payload.kyc) {
          state.kyc.documents = payload.kyc.documents || [];
        }
        state.id = payload.id;
      }
    },
    reset(): ICompanyAccountState {
      return initialState;
    },
  },
});

export const { reducer, actions } = companySlice;
export default companySlice;
