import { useOpenDrawer } from '@app/hooks';

export const useOpenCompanyRowDrawer = () => {
  const info = useOpenDrawer();
  const edit = useOpenDrawer();

  const openEditHandler = () => edit.open();
  const closeEditHandler = () => edit.close();

  const openDrawerHandler = () => info.open();

  const closeAllHandler = () => {
    info.close();
    edit.close();
  };

  return { info, edit, closeAllHandler, openEditHandler, closeEditHandler, openDrawerHandler };
};
