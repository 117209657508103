import httpClient from '@app/client/httpClient';
import { INotification } from '@app/types/api';

const API_NOTIFICATIONS = '/api/user/notifications';

export const getNotificationsHistory = () => {
  return httpClient.get<INotification[]>(`${API_NOTIFICATIONS}/history`);
};

export const getHedgeNotifications = () => {
  return httpClient.get<INotification[]>(`${API_NOTIFICATIONS}/unread/hedge`);
};

export const getOtherNotifications = () => {
  return httpClient.get<INotification[]>(`${API_NOTIFICATIONS}/unread/backoffice`);
};

export const toggleRead = (notificationId: string) => {
  return httpClient.post(`${API_NOTIFICATIONS}/${notificationId}/switch`);
};

export const markAllAsRead = (notificationsType: string) => {
  return httpClient.post(`${API_NOTIFICATIONS}/read/${notificationsType}`);
};
