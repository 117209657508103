import { Box, Button, Divider, List, Typography } from '@mui/material';
import { HedgingStatus, IHedgeShipment } from '@app/types';
import { useEffect, useState } from 'react';
import {
  CollapsableDataListItem,
  CommentCard,
  DataListItem,
  DrawerFooter,
  LoadingSkeleton,
  ScrapexGrayText,
  ScrapexSidebarTextH1,
  ShipmentCard,
} from '@common/components';
import { HedgeRequestModal } from './components/hedgeRequestModal';
import { HedgeRequestTabs } from './components/hedgeRequestTabs';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import {
  getHedgeByIdThunk,
  createHedgeThunk,
  setAnswerToQuotationThunk,
  rejectOrderThunk,
} from 'src/account/reducer/hedging/thunk';
import { HedgingStatusIndicator } from '@common/components/display/hedging/status';
import { HedgeRejectModal } from './components/hedgeRejectModal';
import { ICreateHedge } from '@app/types/api';
import { HedgeRequestContent } from './components/hedgeRequestTabs/hedgeRequestContent';
import { dropActiveHedge, getActiveHedge } from '@api/hedging';
import { HedgeTabType, HedgeType } from './helpers';
import { useGetCurrentHedge } from '../../hooks/useGetCurrentHedge';
import { HedgeRequestDrawerFooter } from './components/hedgeDrawerFooter';
import dayjs, { Dayjs } from 'dayjs';
import { renderDateOrNoneInDrawer } from '@app/utils';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { ScrapexWidget } from '@common/components/layout/components/widget';

interface IHedgeRequestDrawerProps {
  shipmentsIds?: string[] | null;
  hedgeId?: string | null;
  onClose?: () => void;
}

export const HedgeRequestDrawer = ({
  shipmentsIds,
  hedgeId,
  onClose,
}: IHedgeRequestDrawerProps) => {
  //TODO: Refactor component
  const dispatch = useDispatch<AppDispatch>();

  const { hedge, loading, error } = useGetCurrentHedge(hedgeId || null, shipmentsIds || null);
  const [hedgeRequestModalOpen, setHedgeRequestModalOpen] = useState(false);
  const [hedgeRejectModalOpen, setHedgeRejectModalOpen] = useState(false);

  const [isShipmentsCardsOpen, setIsShipmentsCardsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(HedgeTabType.ACM);
  const [orderType, setOrderType] = useState<HedgeType>(HedgeType.TakeProfit);

  const [tpExchangeLevel, setTpExchangeLevel] = useState<string>('');
  const [ringDate, setRingDate] = useState<Dayjs | null>(dayjs(null));

  const handleTimerExpired = async () => {
    try {
      const activeHedge = await getActiveHedge();
      if (activeHedge.status === 200) {
        try {
          await dropActiveHedge();
        } catch (e) {
          console.log('Error dropping active hedge: ', e);
        }
      }
    } catch (e) {
      console.log('Error fetching active hedge:', e);
    } finally {
      await dispatch(getHedgeByIdThunk(hedge?.id as string));
    }
  };

  const handleTpExchangeLevelChange = (value: string) => {
    setTpExchangeLevel(value);
  };

  const handleRingDateChange = (date: Dayjs) => {
    setRingDate(date as Dayjs);
  };

  const handleOrderTypeChange = (type: HedgeType) => {
    setOrderType(type);
  };

  const sendRequestQuote = () => {
    const requestPayload: ICreateHedge = {
      shipments_id: shipmentsIds!,
      hedge_type: 'acm',
    };
    dispatch(createHedgeThunk(requestPayload));
  };

  const placeOrder = () => {
    let requestPayload: ICreateHedge = {
      shipments_id: shipmentsIds!,
      hedge_type: orderType,
    };

    if (orderType === HedgeType.TakeProfit) {
      requestPayload = {
        ...requestPayload,
        tp_exchange_level: parseFloat(tpExchangeLevel),
      };
    } else if (orderType === HedgeType.OLME) {
      requestPayload = {
        ...requestPayload,
        olme_date: ringDate?.format('YYYY-MM-DD'),
      };
    }
    dispatch(createHedgeThunk(requestPayload));
  };

  const handleHedgeRequestModal = () => {
    if (selectedTab === HedgeTabType.ACM) {
      sendRequestQuote();
    } else {
      placeOrder();
    }
    setHedgeRequestModalOpen(false);
  };

  const handleQuotationAccept = () => {
    dispatch(setAnswerToQuotationThunk({ response: 'accept' }));
  };

  const handleQuotationReject = () => {
    setHedgeRejectModalOpen(false);
    dispatch(setAnswerToQuotationThunk({ response: 'reject' }));
  };

  const handleRejectOrder = () => {
    setHedgeRejectModalOpen(false);
    dispatch(rejectOrderThunk(hedge?.id as string));
  };

  const hedgeTypeForModal = selectedTab === HedgeTabType.TakeProfit ? orderType : HedgeType.ACM;

  const footerProps = {
    hedge,
    selectedTab,
    orderType,
    tpExchangeLevel,
    ringDate,
    onClose,
    setHedgeRequestModalOpen,
    setHedgeRejectModalOpen,
    handleTimerExpired,
    handleQuotationAccept,
    handleQuotationReject,
    handleRejectOrder,
  };
  return (
    <>
      {loading && <LoadingSkeleton />}
      {error && <p>Error: {error.message}</p>}
      {!loading && !error && hedge && (
        <>
          <Box padding="0px 40px 125px 40px">
            <Box
              sx={{
                position: 'sticky',
                top: 0,
                background: '#fff',
                zIndex: 1000,
              }}
            >
              <ScrapexSidebarTextH1>Hedge Request</ScrapexSidebarTextH1>
              <ScrapexGrayText>Hedge Number: </ScrapexGrayText>
              {hedge.hedge_number}
              <DataListItem title="Status">
                <HedgingStatusIndicator status={hedge.hedge_status} />
              </DataListItem>
            </Box>
            <List sx={{ mb: '20px' }}>
              <DataListItem title="Product/Metal">
                {hedge.product}/{hedge.metal}
              </DataListItem>
              <DataListItem title="Formula">{hedge.formula}</DataListItem>
              <Box sx={{ p: '14px 0' }}>
                <ScrapexWidget />
              </Box>
              <Divider sx={{ borderColor: 'var(--light-white-color)' }} />
              <DataListItem title="Hedging Shipments" input={hedge.hedge_shipments.length}>
                <Box display="flex" alignItems="center" gap={3} justifyContent="flex-end">
                  <Typography>{hedge.hedge_shipments.length}</Typography>
                  <Button
                    variant="outlined"
                    color="warning"
                    endIcon={isShipmentsCardsOpen ? <ExpandLess /> : <ExpandMore />}
                    onClick={() => setIsShipmentsCardsOpen((prev) => !prev)}
                  >
                    View all shipments
                  </Button>
                </Box>
              </DataListItem>
              {isShipmentsCardsOpen && (
                <>
                  {hedge.hedge_shipments.map((shipment: IHedgeShipment) => (
                    <ShipmentCard key={shipment.id} shipment={shipment} />
                  ))}
                </>
              )}
              <DataListItem title="Hedging Weight"> {hedge.weight.toFixed(3)}</DataListItem>
              <DataListItem title="Nearest Limit Fixation Date">
                {renderDateOrNoneInDrawer(hedge.limit_fixation_date)}
              </DataListItem>
              {hedge.message && (
                <CollapsableDataListItem title="Comment">
                  <CommentCard comment={hedge.message} />
                </CollapsableDataListItem>
              )}
            </List>
            {hedge.hedge_status === HedgingStatus.None ? (
              <HedgeRequestTabs
                selectedTab={selectedTab}
                onTabChange={setSelectedTab}
                onHandleUpdate={handleTpExchangeLevelChange}
                tpExchangeLevel={tpExchangeLevel}
                ringDate={ringDate}
                onHandleDate={handleRingDateChange}
                onHandleOrderTypeChange={handleOrderTypeChange}
                orderType={orderType}
              />
            ) : (
              <HedgeRequestContent />
            )}
          </Box>
          <DrawerFooter>
            <HedgeRequestDrawerFooter {...footerProps} />
          </DrawerFooter>

          <HedgeRequestModal
            open={hedgeRequestModalOpen}
            onClose={() => setHedgeRequestModalOpen(false)}
            onConfirm={handleHedgeRequestModal}
            hendgeNumber={hedge.hedge_number}
            hedgeType={hedgeTypeForModal}
          />
          <HedgeRejectModal
            open={hedgeRejectModalOpen}
            onClose={() => setHedgeRejectModalOpen(false)}
            onConfirm={
              hedge.hedge_type === HedgeType.ACM ? handleQuotationReject : handleRejectOrder
            }
            hendgeNumber={hedge.hedge_number}
            hedgeType={hedge.hedge_type as HedgeType}
            type={hedge.hedge_type === HedgeType.ACM ? 'Offer' : 'Order'}
          />
        </>
      )}
    </>
  );
};
