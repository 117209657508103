import { IShipment } from '@app/types';
import { GridRowsProp } from '@mui/x-data-grid-premium';

export const generateShipmentRows = (shipments: IShipment[]) => {
  const rows: GridRowsProp = shipments.map((shipment) => ({
    ...shipment,
    id: shipment.id,
  }));

  return { rows };
};
