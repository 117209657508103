import * as React from 'react';
import dayjs, { type Dayjs } from 'dayjs';
import { Button, IconButton } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  FieldSection,
} from '@mui/x-date-pickers/models';
import { CalendarMonth, DeleteOutlineOutlined } from '@mui/icons-material';
import { formatDate } from '@app/utils';

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  handleResetDate: () => void;
}

function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    label,
    id,
    disabled,
    handleResetDate,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;
  return (
    <Button
      variant="outlined"
      color="warning"
      id={id}
      disabled={disabled}
      ref={ref}
      sx={{ pr: 1 }}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      startIcon={<CalendarMonth />}
    >
      {label ? (
        <>
          Date: {label}
          <IconButton
            sx={{ p: 0, ml: 1 }}
            onClick={(e) => {
              e.stopPropagation();
              handleResetDate();
            }}
          >
            <DeleteOutlineOutlined fontSize="small" />
          </IconButton>
        </>
      ) : (
        'Pick a date'
      )}
    </Button>
  );
}

export const ButtonDatePicker = (
  props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'> & {
    handleResetDate: () => void;
  },
) => {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      slots={{ field: ButtonField as any, ...props.slots }}
      slotProps={{ field: { setOpen, handleResetDate: props.handleResetDate } as any }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
};

interface PickerWithButtonFieldProps {
  onHandleUpdateShipment: (date: string) => void;
  initialDate?: string;
}
export const PickerWithButtonField = ({
  onHandleUpdateShipment,
  initialDate,
}: PickerWithButtonFieldProps) => {
  const [value, setValue] = React.useState<Dayjs | null>(initialDate ? dayjs(initialDate) : null);

  const handleResetDate = () => {
    setValue(null);
    return onHandleUpdateShipment('0001-01-01'); //reset the date, backend can't process null
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ButtonDatePicker
        label={value == null ? null : formatDate(value.toString())}
        value={value}
        onChange={(newValue: Dayjs | null) => {
          setValue(newValue);
          const formattedDate = newValue?.format('YYYY-MM-DD');
          onHandleUpdateShipment(formattedDate as string);
        }}
        handleResetDate={handleResetDate}
        // disablePast
      />
    </LocalizationProvider>
  );
};
