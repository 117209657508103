import { ICompany, ICompanyInfo, IGetCompanyByIdResponse } from '@app/types';
import httpClient from '../../client/httpClient';
const API_COMPANIES = '/api/admin/companies';

export const getCompanies = () => {
  return httpClient.get<ICompany[]>(API_COMPANIES);
};

export const createCompany = (companyData: ICompanyInfo) => {
  return httpClient.post(API_COMPANIES, companyData);
};

export const updateCompany = (companyId: string, companyData: ICompanyInfo) => {
  const API_UPDATE_COMPANY = `/api/admin/companies/${companyId}`;
  return httpClient.put(API_UPDATE_COMPANY, companyData);
};

export const getCompanyById = (id: string) => {
  return httpClient.get<IGetCompanyByIdResponse>(`${API_COMPANIES}/${id}`);
};

export const deleteCompany = (companyId: string) => {
  const API_DELETE_COMPANY = `/api/admin/companies/test/${companyId}`;
  return httpClient.delete(API_DELETE_COMPANY);
};
