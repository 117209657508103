import { TableContainer } from '@mui/material';

import { DrawerGeneral, LoadingSkeleton } from '@common/components';
import { useOpenDrawer } from '@app/hooks';
import { DataGridPremium, DataGridPremiumProps, GridRowParams } from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useState } from 'react';
import { IHedge } from '@app/types';
import { ScrapexColorEnum } from '@common/colors';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import { getAtWorkHedgesThunk } from 'src/hedgeAdmin/reducer/hedging/thunk';
import { HedgeRequestDrawer } from 'src/hedgeAdmin/hedging/hedgeRequests/components/hedgeRequestDrawer';
import { DetailPanelContent } from 'src/hedgeAdmin/hedging/shared/table/collapsibleRow';
import {
  generateHedgeRequestsColumns,
  generateHedgeRequestsRows,
} from 'src/hedgeAdmin/hedging/hedgeRequests/components/table/data';
import {
  CollapseIcon,
  ExpandIcon,
} from 'src/hedgeAdmin/hedging/shared/table/collapsibleRow/components/Icons';
import { updateShipmentThunk } from 'src/accountManager/reducer/shipments/thunks';
import { TableHeader } from 'src/accountManager/common';
import { filterPanelProps } from 'src/hedgeAdmin/hedging/shared';

export const HedgeAtWorkTable = () => {
  const hedges = useSelector((state: RootState) => state.hedgingAdmin.hedging.atWorkHedges);
  const isLoading = useSelector((state: RootState) => state.hedgingAdmin.hedging.isLoading);
  const hedgeAtWorkHeaderConfig = {
    title: `At work (${hedges.length})`,
  };

  const { columns } = generateHedgeRequestsColumns();
  const { rows } = generateHedgeRequestsRows(hedges);

  const [selectedHedge, setSelectededHedge] = useState<IHedge | null>(null);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getAtWorkHedgesThunk());
  }, []);
  const { isOpen, open, close } = useOpenDrawer();

  const handleRowClick = (params: GridRowParams) => {
    setSelectededHedge(params.row);
    open();
  };

  const handleUpdateLFD = async (shipmentId: string, value: string, field: string) => {
    await dispatch(updateShipmentThunk({ shipmentId, data: { [field]: value } }));
    dispatch(getAtWorkHedgesThunk());
  };

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(({ row }) => <DetailPanelContent row={row} onHandleUpdateLFD={handleUpdateLFD} />, []);

  return (
    <>
      <TableContainer sx={{ background: ScrapexColorEnum.WHITE_COLOR }}>
        <div style={{ height: '85vh' }}>
          {isLoading && <LoadingSkeleton />}
          {hedges && !isLoading && (
            <DataGridPremium
              sx={{
                border: 'none',
                '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },

                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeaders': {
                  color: '#8592A8',
                  background: '#F9FAFB',
                  border: 'none',
                },
              }}
              columnHeaderHeight={40}
              rows={rows}
              columns={columns}
              disableColumnFilter
              hideFooter
              onRowClick={handleRowClick}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={({ row }) => 'auto'}
              sortingOrder={['desc', 'asc']}
              slots={{
                detailPanelExpandIcon: ExpandIcon,
                detailPanelCollapseIcon: CollapseIcon,
                toolbar: TableHeader,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  ...hedgeAtWorkHeaderConfig,
                },
                filterPanel: {
                  ...filterPanelProps,
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'request_time', sort: 'desc' }],
                },
              }}
              disableColumnMenu
            />
          )}
        </div>

        <DrawerGeneral onClose={close} open={isOpen}>
          {isOpen && selectedHedge && <HedgeRequestDrawer hedge={selectedHedge} onClose={close} />}
        </DrawerGeneral>
      </TableContainer>
    </>
  );
};
