import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/store';
import type { ILogInSliceState } from '../../../app/types/auth';

interface IAuthState {
  id: string | null;
  firstname: string | null;
  lastname: string | null;
  company_name?: string | null;
  email: string | null;
  role: string | null;
}

export interface IAuthResponse {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  company_name?: string;
}

const initialState: IAuthState = {
  id: null,
  firstname: null,
  lastname: null,
  email: null,
  role: null,
  company_name: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, { payload }: PayloadAction<IAuthResponse>) {
      state.id = payload.id;
      state.email = payload.email;
      state.firstname = payload.firstname;
      state.company_name = payload.company_name;
      state.lastname = payload.lastname;
      state.role = payload.role;
    },
    reset(): IAuthState {
      return initialState;
    },
  },
});

export const { actions, reducer } = authSlice;
export default authSlice.reducer;
