import { createSlice } from '@reduxjs/toolkit';
import {
  getHedgeNotificationsThunk,
  getNotificationsHistoryThunk,
  getOtherNotificationsThunk,
  readAllHedgeNotificationsThunk,
  readAllOtherNotificationsThunk,
} from './thunk';
import { INotification } from '@app/types/api';

interface NotificationsState {
  otherNotifications: INotification[];
  hedgeNotifications: INotification[];
  notificationsHistory: INotification[];
  isLoading: boolean;
}

const initialState: NotificationsState = {
  otherNotifications: [],
  hedgeNotifications: [],
  notificationsHistory: [],
  isLoading: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOtherNotificationsThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.otherNotifications = payload;
      }
    });

    builder.addCase(getHedgeNotificationsThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.hedgeNotifications = payload;
      }
    });
    builder.addCase(getNotificationsHistoryThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.notificationsHistory = payload;
      }
    });
    builder.addCase(readAllHedgeNotificationsThunk.fulfilled, (state) => {
      state.hedgeNotifications = [];
    });
    builder.addCase(readAllOtherNotificationsThunk.fulfilled, (state) => {
      state.otherNotifications = [];
    });
  },
});

export const { reset } = notificationsSlice.actions;
export default notificationsSlice.reducer;
