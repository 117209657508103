import { SxProps } from '@mui/system';

export const outlinedInputStyles = (formError?: boolean): SxProps => {
  return {
    width: '100%',
    height: '36px',
    input: {
      lineHeight: '20px',
      height: 'auto',
      padding: '8px !important',
    },
    fieldset: {
      border: `1px solid ${formError ? 'var(--red-color)' : 'var(--other-300)'}`,
    },
  };
};
