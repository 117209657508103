import { createSlice } from '@reduxjs/toolkit';
import {
  getShipmentThunk,
  getShipmentsThunk,
  updateShipmentsThunk,
  updateShipmentsWithHedgeThunk,
} from './thunks';
import { IShipmentUser } from '@app/types';

interface IShipmentsSlice {
  shipments: IShipmentUser[];
  currentShipment: IShipmentUser | null;
  isLoading: boolean;
  isShipmentLoading: boolean;
}

const initialState: IShipmentsSlice = {
  shipments: [],
  currentShipment: null,
  isLoading: false,
  isShipmentLoading: false,
};

const shipmentsSlice = createSlice({
  name: 'userShipmentsSlice',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    resetCurrentShipment(state) {
      state.currentShipment = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShipmentsThunk.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.shipments = payload.shipments;
        }
      })
      .addCase(getShipmentsThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getShipmentThunk.fulfilled, (state, { payload }) => {
        state.isShipmentLoading = false;
        if (payload) {
          state.currentShipment = payload;
        }
      })
      .addCase(getShipmentThunk.pending, (state) => {
        state.isShipmentLoading = true;
      })
      .addCase(updateShipmentsWithHedgeThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          const updatedShipments = state.shipments.map((shipment) =>
            shipment.id === payload.id ? { ...payload } : shipment,
          );
          state.shipments = updatedShipments;
        }
      })
      .addCase(updateShipmentsThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          const updatedShipments = state.shipments.map((shipment) =>
            shipment.id === payload.id ? { ...payload } : shipment,
          );
          state.shipments = updatedShipments;
          state.currentShipment = payload;
        }
      });
  },
});

export const { actions, reducer } = shipmentsSlice;

export default shipmentsSlice.reducer;
