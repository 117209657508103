import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

export const AlertModal = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <Dialog open={isOpen} disableEscapeKeyDown>
      <DialogTitle>Application Problem Detected</DialogTitle>
      <DialogContent>
        <Typography variant="body1">Please contact support for further assistance.</Typography>
      </DialogContent>
    </Dialog>
  );
};
