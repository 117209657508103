import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import {
  getHedgeNotificationsThunk,
  getOtherNotificationsThunk,
  getNotificationsHistoryThunk,
} from 'src/account/reducer/notifications/thunk';

export const useGetNotifications = (selectedTab: number) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        dispatch(getHedgeNotificationsThunk());
        break;
      case 1:
        dispatch(getOtherNotificationsThunk());
        break;
      case 2:
        dispatch(getNotificationsHistoryThunk());
        break;
      default:
        break;
    }
  }, [dispatch, selectedTab]);

  const { hedgeNotifications, otherNotifications, notificationsHistory } = useSelector(
    (state: RootState) => state.account.notifications,
  );

  const unreadHedgeCount = hedgeNotifications?.length || 0;
  const unreadOtherCount = otherNotifications?.length || 0;

  return {
    hedgeNotifications,
    otherNotifications,
    notificationsHistory,
    unreadHedgeCount,
    unreadOtherCount,
  };
};
