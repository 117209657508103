import ConfirmationDialog from '@common/components/dialog/confirmationDialog';

interface DeleteCompanyModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  companyName: string;
}

const DeleteCompanyModal: React.FC<DeleteCompanyModalProps> = ({
  open,
  onClose,
  onConfirm,
  companyName,
}) => {
  const title = 'Remove Company';
  const subtitleItems = [{ key: 'Company name:', value: companyName }];
  const content = 'Do you really want to remove the company? This action is irreversible';

  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={title}
      content={content}
      onConfirm={handleConfirm}
      subtitleItems={subtitleItems}
    />
  );
};

export default DeleteCompanyModal;
