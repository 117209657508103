import React, { useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useUploadShipmentFiles } from '@app/hooks';
import { FileRow } from 'src/accountManager/contract/components/shipments/components/shipmentInfoDrawer/fileRow';
import { AddOutlined, DownloadOutlined, DownloadingOutlined } from '@mui/icons-material';
import { useGetShipmentFiles } from '@app/hooks/useGetShipmentFiles';
import { DialogTitleModal, ScrapexGrayTextRegular } from '@common/components';

interface FileModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  content?: string;
  dealId: string;
  folder: string;
  onFilesSelect: (filesIds: string[]) => void;
  fileIdsProp: string[];
}

export const FileModal: React.FC<FileModalProps> = ({
  open,
  onClose,
  title = 'Files',
  dealId,
  folder,
  onFilesSelect,
  fileIdsProp,
}) => {
  const { upload } = useUploadShipmentFiles();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileIds, setFileIds] = useState<string[]>(fileIdsProp || []);
  const [dragging, setDragging] = useState(false);
  const [fileIdToReplace, setFileIdToReplace] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const { link } = useGetShipmentFiles(dealId, folder);

  const handleFilesUpload = async (files: FileList | null) => {
    setLoading(true);
    if (files && files.length > 0) {
      try {
        const uploadedIds = await Promise.all(
          Array.from(files).map((file) => upload(file, dealId, folder)),
        );

        if (fileIdToReplace) {
          const updatedFileIds = fileIds.map((fileId) =>
            fileId === fileIdToReplace ? uploadedIds[0] : fileId,
          );
          onFilesSelect(updatedFileIds);
          setFileIds(updatedFileIds);
          setFileIdToReplace(null);
        } else {
          setFileIds((prevFileIds) => [...prevFileIds, ...uploadedIds]);
          onFilesSelect([...fileIds, ...uploadedIds]);
        }
      } catch (err) {
        console.error('Upload failed:', err);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleReloadFile = (item: string) => {
    setFileIdToReplace(item);
    fileInputRef?.current?.click();
  };

  const handleRemoveFile = (item: string) => {
    const updatedFileIds = fileIds.filter((fileId) => fileId !== item);

    setFileIds(updatedFileIds);
    onFilesSelect(updatedFileIds);
  };

  //TODO: move drag'n'drop into hook
  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);

    const files = event.dataTransfer.files;
    handleFilesUpload(files);
  };

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    handleFilesUpload(files);
    event.target.value = '';
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 800,
          width: 800,
          height: 539,
        },
      }}
    >
      <DialogTitleModal title={title} onClose={onClose} />
      {fileIds && fileIds.length > 0 ? (
        <>
          <DialogContent
            sx={{
              px: '24px',
            }}
          >
            {fileIds.map((item, index) => (
              <FileRow
                key={item}
                fileId={item}
                dealId={dealId}
                hasDownloadFile
                onReuploadClick={() => handleReloadFile(item)}
                onClearClick={() => handleRemoveFile(item)}
                sx={{
                  justifyContent: 'space-between',
                  backgroundColor: index % 2 !== 0 ? '#FAFAFA' : '#fff',
                }}
              />
            ))}
          </DialogContent>
          <DialogActions sx={{ p: '24px', gap: 2 }}>
            <Button
              onClick={() => fileInputRef?.current?.click()}
              variant="outlined"
              color="warning"
              startIcon={<AddOutlined />}
            >
              Add new
            </Button>
            <Button variant="outlined" startIcon={<DownloadingOutlined />} href={link}>
              Download All
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          {loading ? (
            <DialogContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <ScrapexGrayTextRegular>Files uploading</ScrapexGrayTextRegular>
              <CircularProgress />
            </DialogContent>
          ) : (
            <DialogContent
              sx={{
                textAlign: 'center',
                border: dragging ? '2px dashed #000' : '2px dashed transparent',
                mt: 17,
              }}
              onDragEnter={handleDragEnter}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <Typography variant="subtitle1" color="textSecondary">
                No files have been uploaded yet
              </Typography>
              <Button
                variant="outlined"
                onClick={() => fileInputRef?.current?.click()}
                sx={{ mt: 2 }}
                startIcon={<DownloadOutlined />}
              >
                Upload
              </Button>
            </DialogContent>
          )}
        </>
      )}
      <input
        ref={fileInputRef}
        hidden
        multiple
        type="file"
        id="file"
        name="file"
        accept=".doc,.docx,.xlsm,.xlsx,.pdf"
        onChange={handleFileInputChange}
      />
    </Dialog>
  );
};
