import { TableContainer } from '@mui/material';
import { CommentModal, DrawerGeneral, LoadingSkeleton } from '@common/components';
import { useOpenDrawer } from '@app/hooks';
import { DataGridPremium, DataGridPremiumProps, GridRowParams } from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useState } from 'react';
import { IHedge } from '@app/types';
import { ScrapexColorEnum } from '@common/colors';
// import { DetailPanelContent } from './collapsibleRow';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import { getHedgeHistoryThunk } from 'src/hedgeAdmin/reducer/hedging/thunk';
import { HedgeRequestDrawer } from 'src/hedgeAdmin/hedging/hedgeRequests/components/hedgeRequestDrawer';
import { DetailPanelContent } from 'src/hedgeAdmin/hedging/shared/table/collapsibleRow';
import {
  CollapseIcon,
  ExpandIcon,
} from 'src/hedgeAdmin/hedging/shared/table/collapsibleRow/components/Icons';
import { generateHedgeHistoryColumns } from './data/hedgeHistoryColumns';
import { generateHedgeHistoryRows } from './data/hedgeHistoryRowsData';
import { TableHeader } from 'src/accountManager/common';
import { filterPanelProps } from 'src/hedgeAdmin/hedging/shared';

export const HedgeHistoryTable = () => {
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const hedges = useSelector((state: RootState) => state.hedgingAdmin.hedging.hedgeHistory);
  const isLoading = useSelector((state: RootState) => state.hedgingAdmin.hedging.isLoading);

  const hedgeHistoryHeaderConfig = {
    title: `Hedge History (${hedges.length})`,
    showSearchInput: true,
  };

  const handleCommentIconClick = (event: React.MouseEvent, hedge: IHedge) => {
    event.stopPropagation();
    setSelectededHedge(hedge);
    setIsCommentModalOpen(true);
  };

  const { columns } = generateHedgeHistoryColumns({
    onHandleCommentIconClick: handleCommentIconClick,
  });
  const { rows } = generateHedgeHistoryRows(hedges);

  const [selectedHedge, setSelectededHedge] = useState<IHedge | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getHedgeHistoryThunk());
  }, []);
  const { isOpen, open, close } = useOpenDrawer();

  const handleRowClick = (params: GridRowParams) => {
    setSelectededHedge(params.row);
    open();
  };

  const handleCommentModalClose = () => {
    setIsCommentModalOpen(false);
    setSelectededHedge(null);
  };

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(({ row }) => <DetailPanelContent row={row} isItHistory />, []);

  return (
    <>
      <TableContainer sx={{ background: ScrapexColorEnum.WHITE_COLOR }}>
        <div style={{ height: '85vh' }}>
          {isLoading && <LoadingSkeleton />}
          {hedges && !isLoading && (
            <DataGridPremium
              sx={{
                border: 'none',
                '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },

                '& .MuiDataGrid-row:hover': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeaders': {
                  color: '#8592A8',
                  background: '#F9FAFB',
                  border: 'none',
                },
              }}
              columnHeaderHeight={40}
              rows={rows}
              columns={columns}
              hideFooter
              onRowClick={handleRowClick}
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={({ row }) => 'auto'}
              sortingOrder={['desc', 'asc']}
              slots={{
                detailPanelExpandIcon: ExpandIcon,
                detailPanelCollapseIcon: CollapseIcon,
                toolbar: TableHeader,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  ...hedgeHistoryHeaderConfig,
                },
                filterPanel: {
                  ...filterPanelProps,
                },
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'filled_time', sort: 'desc' }],
                },
              }}
              disableColumnMenu
              disableColumnFilter
            />
          )}
        </div>
        <DrawerGeneral onClose={close} open={isOpen}>
          {isOpen && selectedHedge && <HedgeRequestDrawer hedge={selectedHedge} onClose={close} />}
        </DrawerGeneral>
        {isCommentModalOpen && (
          <CommentModal
            open={isCommentModalOpen}
            hedge={selectedHedge as IHedge}
            onClose={handleCommentModalClose}
          />
        )}
      </TableContainer>
    </>
  );
};
