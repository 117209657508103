import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { ScrapexColorEnum } from '@common/colors';

export interface SubtitleItem {
  key: string;
  value?: string | ReactNode;
}

interface ModalSubtitleProps {
  subtitleItems: SubtitleItem[];
}

const ModalSubtitle: React.FC<ModalSubtitleProps> = ({ subtitleItems }) => {
  return (
    <Stack direction="row" paddingLeft="24px">
      {subtitleItems.map((item, index) => (
        <Stack key={index} direction="row" alignItems="center" gap={1}>
          <Typography sx={{ color: ScrapexColorEnum.FILL_TEXT_COLOR }}>{item.key}</Typography>
          <Typography variant="subtitle1">{item.value}</Typography>
          {index < subtitleItems.length - 1 && (
            <span style={{ marginRight: '8px', color: ScrapexColorEnum.FILL_TEXT_COLOR }}>•</span>
          )}
        </Stack>
      ))}
    </Stack>
  );
};

export default ModalSubtitle;
