import { RootState } from '@app/store';
import { DataListItem, ScrapexSidebarTextH1 } from '@common/components';
import { useSelector } from 'react-redux';
import { OperatingTimeInfo } from './OperatingTimeInfo';
import { HedgeType } from '../../../helpers';
import { HedgingStatus } from '@app/types';
import { formatDate, renderValueOrNone, renderValueOrNoneInDrawer } from '@app/utils';

export const HedgeRequestContent = () => {
  const hedge = useSelector((state: RootState) => state.account.hedging.currentHedge);
  return (
    <>
      {hedge && (
        <>
          {hedge.hedge_type === HedgeType.ACM && (
            <>
              <ScrapexSidebarTextH1>At the current market (ACM)</ScrapexSidebarTextH1>
              <OperatingTimeInfo />
              <DataListItem title="Current LME Offer, USD/mt">
                {renderValueOrNone(hedge.quotation)}
              </DataListItem>
              <DataListItem title="Fixed LME, USD/mt">
                {renderValueOrNone(hedge.fixed_exchange)}
              </DataListItem>
              <DataListItem title="Final Unit Price, USD/mt">
                {renderValueOrNone(hedge.final_product_price)}
              </DataListItem>
            </>
          )}
          {hedge.hedge_type === HedgeType.TakeProfit && (
            <>
              <ScrapexSidebarTextH1>Take Profit Order</ScrapexSidebarTextH1>
              <DataListItem title="Order Type">Take Profit (TP)</DataListItem>
              <DataListItem title="Take Profit Exchange Level, USD/mt">
                {renderValueOrNoneInDrawer(hedge.tp_exchange_level)}
              </DataListItem>
              <DataListItem title="Date">GTC</DataListItem>
              {hedge.hedge_status === HedgingStatus.FilledTP ? (
                <>
                  <DataListItem title="Fixed LME, USD/mt">
                    {renderValueOrNoneInDrawer(hedge.fixed_exchange)}
                  </DataListItem>
                  <DataListItem title="Final Unit Price, TP, USD/mt">
                    {renderValueOrNoneInDrawer(hedge.final_product_price)}
                  </DataListItem>
                </>
              ) : (
                <DataListItem title="Estimated Product Price, TP, USD/mt">
                  {renderValueOrNoneInDrawer(hedge.tp_estimated_product_price)}
                </DataListItem>
              )}
            </>
          )}
          {hedge.hedge_type === HedgeType.OLME && (
            <>
              <ScrapexSidebarTextH1>Order</ScrapexSidebarTextH1>
              <DataListItem title="Order Type">Official LME (OLME)</DataListItem>
              <DataListItem title="Date">{formatDate(hedge?.olme_date)}</DataListItem>
              {hedge.hedge_status === HedgingStatus.FilledOLME && (
                <>
                  <DataListItem title="Fixed LME, USD/mt">
                    {renderValueOrNoneInDrawer(hedge.fixed_exchange)}
                  </DataListItem>
                  <DataListItem title="Final Unit Price, OLME, USD/mt">
                    {renderValueOrNoneInDrawer(hedge.final_product_price)}
                  </DataListItem>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
