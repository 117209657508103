import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assocPath, pipe } from 'ramda';
import {
  IColleague,
  IColleagueRole,
  ICompanyRegistrationState,
} from '../../../app/types/companies';

const initialState: ICompanyRegistrationState = {
  mainInfoForm: {
    name: '',
    registration_code: '',
    vat_id: '',
    address: {
      country: '',
      state: '',
      city: '',
      postal: '',
      address1: '',
      address2: '',
    },
    contact: {
      email: '',
      phone: '',
      phone2: '',
    },
  },
  bankDetailsForm: {
    name: '',
    country: '',
    address: {
      country: '',
      state: '',
      city: '',
      postal: '',
      address1: '',
      address2: '',
    },
    account_number: '',
    details: '',
  },
  teamForm: {
    members: [],
    documents: ' ',
  },
  emailTeamInput: '',
  KYC: [],
};

export const enum MakeStateView {
  NEXT = 'NEXT',
  BACK = 'BACK',
}

export const companyRegistrationSlice = createSlice({
  name: 'companyRegistration',
  initialState,
  reducers: {
    setColleagueRole(state, { payload }: PayloadAction<IColleague>) {
      state.teamForm.members.map((colleague) => {
        if (colleague.email === payload.email) {
          colleague.role = payload.role;
        }
        return colleague;
      });
    },
    setCompany(state, { payload }) {
      if (payload) {
        state = pipe<
          any[],
          ICompanyRegistrationState,
          ICompanyRegistrationState,
          ICompanyRegistrationState,
          ICompanyRegistrationState,
          ICompanyRegistrationState
        >(
          assocPath(['mainInfoForm', 'address'], payload.address),
          assocPath(['mainInfoForm', 'name'], payload.name),
          assocPath(['mainInfoForm', 'registration_code'], payload.registration_code),
          assocPath(['mainInfoForm', 'vat_id'], payload.vat_id),
          assocPath(['mainInfoForm', 'contact'], payload.contact),
        )(state);
        if (!payload.address.address2) {
          state.mainInfoForm.address.address2 = '';
        }
        if (payload.bank) {
          state = pipe<
            any,
            ICompanyRegistrationState,
            ICompanyRegistrationState,
            ICompanyRegistrationState,
            ICompanyRegistrationState,
            ICompanyRegistrationState
          >(
            assocPath(['bankDetailsForm', 'name'], payload.bank.name),
            assocPath(['bankDetailsForm', 'country'], payload.bank.country),
            assocPath(['bankDetailsForm', 'address'], payload.bank.address),
            assocPath(['bankDetailsForm', 'account_number'], payload.bank.account_number),
            assocPath(['bankDetailsForm', 'details'], payload.bank.details),
          )(state);
          if (!payload.bank.address.address2) {
            state.bankDetailsForm.address.address2 = '';
          }
        }
        if (payload.team) {
          state = pipe<any, ICompanyRegistrationState>(
            assocPath(['teamForm', 'members'], payload.team.members),
          )(state);
        }
        if (payload.kyc) {
          state.KYC = payload.kyc.documents || [];
        }
        if (!payload.team || !payload.team.members || payload.team.members.length === 0) {
          state = pipe<any, ICompanyRegistrationState>(assocPath(['teamForm', 'members'], []))(
            state,
          );
        }
      }
      return state;
    },
    setEmailInput(state, { payload }: PayloadAction<string>) {
      state.emailTeamInput = payload;
      return state;
    },
    addColleague(state) {
      state.teamForm.members.push({ email: state.emailTeamInput, role: IColleagueRole.TRADER });
      state.emailTeamInput = '';
    },
    removeColleague(state, { payload }: PayloadAction<IColleague>) {
      const index = state.teamForm.members.findIndex(
        (colleague) => payload.email === colleague.email,
      );
      state.teamForm.members.splice(index, 1);
      return state;
    },
    addKyc(state, { payload }: PayloadAction<string>) {
      state.KYC.push(payload);
    },
    removeFile(state, { payload }: PayloadAction<string>) {
      state.KYC = state.KYC.filter((file) => file !== payload);
    },
    reset(): ICompanyRegistrationState {
      return initialState;
    },
  },
});

export const {
  addColleague,
  removeColleague,
  reset,
  setColleagueRole,
  setCompany,
  setEmailInput,
  addKyc,
  removeFile,
} = companyRegistrationSlice.actions;
export default companyRegistrationSlice.reducer;
