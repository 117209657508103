import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { PdfFileSelector } from 'src/accountManager/contract/components/shipments/components/shipmentInfoDrawer/pdfFileSelector';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  addInvoiceThunk,
  getShipmentThunk,
  getShipmentsThunk,
} from 'src/accountManager/reducer/shipments/thunks';
import { InvoiceInputLabel } from './shared/InvoiceInputLabel';
import { ICreateShipmentInvoice } from '@app/types';
import { DialogTitleModal, FormErrorSubtitle, OutlinedSelect } from '@common/components';

interface FileModalProps {
  open: boolean;
  onClose: () => void;
  dealId: string;
  shipmentId: string;
  role: string;
}

const outlinedInputStyles = () => {
  return {
    input: {
      lineHeight: '20px',
      height: 'auto',
      padding: '8px !important',
    },
    fieldset: {
      border: `1px solid 'var(--other-300)'`,
    },
  };
};

const validationSchema = Yup.object({
  invoice_type: Yup.string().required('Required'),
  date: Yup.date().required('Required'),
  doc: Yup.string().required('Required'),
});

//TODO: move menulist(for edit invoice and companies too), validationSchema, and outlinedinputstyles outside
const menuList = [
  { value: 'invoice', label: 'Invoice' },
  { value: 'proforma', label: 'Proforma' },
  { value: 'provisional', label: 'Provisional' },
  { value: 'final', label: 'Final' },
  { value: 'debit_note', label: 'Debit note' },
  { value: 'credit_note', label: 'Credit note' },
];

export const InvoiceModal: React.FC<FileModalProps> = ({
  open,
  onClose,
  dealId,
  shipmentId,
  role,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const initialValues = {
    invoice_type: menuList[0].value,
    date: '',
    doc: '',
    shipment_id: shipmentId,
  };
  const onSubmit = async (values: ICreateShipmentInvoice) => {
    try {
      const postData: ICreateShipmentInvoice = {
        invoice_type: values.invoice_type,
        date: values.date,
        doc: values.doc,
        shipment_id: shipmentId,
        who: role,
      };
      await dispatch(addInvoiceThunk(postData));
      await dispatch(getShipmentThunk({ shipmentId }));
      await dispatch(getShipmentsThunk(dealId));
      onClose();
    } catch (err) {
      console.error('Upload failed:', err);
    }
  };

  const { handleSubmit, handleChange, values, setFieldValue, errors, touched, handleBlur } =
    useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      onSubmit,
    });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 588,
          width: 588,
          minHeight: 360,
        },
      }}
    >
      <DialogTitleModal title="Add invoice" onClose={onClose} />

      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ paddingTop: 0 }}>
          <InvoiceInputLabel text="Invoice Type" />
          <OutlinedSelect
            sx={outlinedInputStyles}
            placeholder="Select type"
            menuList={menuList}
            onBlur={handleBlur}
            id={''}
            label={''}
            name={'invoice_type'}
            value={values.invoice_type}
            onChange={handleChange}
          />

          <FormErrorSubtitle errors={errors.invoice_type} touched={touched.invoice_type} />

          <InvoiceInputLabel text="Invoice Date" />
          <TextField
            sx={outlinedInputStyles}
            type="date"
            name="date"
            fullWidth
            value={values.date}
            onChange={handleChange}
            onBlur={handleBlur}
            InputLabelProps={{ shrink: true }}
            error={touched.date && Boolean(errors.date)}
          />
          <FormErrorSubtitle errors={errors.date} touched={touched.date} />

          <InvoiceInputLabel text="Selected Document" />
          <PdfFileSelector
            onPdfIdSelect={(pdfId) => {
              setFieldValue('doc', pdfId);
            }}
            dealId={dealId}
            folder={`${shipmentId}-${role}_invoices`}
            hasRemoveFile={false}
          />
          <FormErrorSubtitle errors={errors.doc} touched={touched.doc} />
        </DialogContent>

        <DialogActions sx={{ p: '24px' }}>
          <Button type="submit" variant="contained">
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
