import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { Box } from '@mui/material';

import { LoginForm } from './components/loginForm';

import LoginImage from '../../assets/img/login.png';
import { actions } from '../reducer/auth/slice';

export const Login = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.reset());
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '50px',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${LoginImage})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          width: { xs: '100%', sm: '40%' },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'calc(100% - 588px)',
        }}
      >
        <LoginForm />
      </Box>
    </Box>
  );
};
