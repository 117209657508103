import {
  DataListItem,
  DrawerFooter,
  ScrapexGrayText,
  ScrapexSidebarTextH1,
} from '@common/components';
import { Box, Button, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { ICreateAdminHedge } from '@app/types/api';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  createHedgeThunk,
  getUnhedgedThunk,
  initHedgeThunk,
} from 'src/hedgeAdmin/reducer/hedging/thunk';
import { useEffect, useState } from 'react';
import { CopyIcon } from 'src/hedgeAdmin/hedging/hedgeRequests/components/hedgeRequestDrawer/components/copyIcon';
import { HedgeRequestDrawerInfo } from 'src/hedgeAdmin/hedging/hedgeRequests/components/hedgeRequestDrawer/components/hedgeRequestDrawerInfo';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { actions } from 'src/hedgeAdmin/reducer/hedging/slice';

interface IHedgeRequestDrawerProps {
  shipmentId: string;
  companyId: string;
  onClose: () => void;
}

export const HedgeCreateDrawer = ({ shipmentId, companyId, onClose }: IHedgeRequestDrawerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const hedge = useSelector((state: RootState) => state.hedgingAdmin.hedging.currentHedge);
  const [date, setDate] = useState<Dayjs | null>(dayjs(null));

  useEffect(() => {
    const initPayload: ICreateAdminHedge = {
      shipments_id: [shipmentId],
      company_id: companyId,
    };
    dispatch(initHedgeThunk(initPayload));
    return () => {
      dispatch(actions.resetCurrentHedge());
    };
  }, []);

  const createHedge = () => {
    const formattedDate = date?.format('YYYY-MM-DD');
    const hedgeData: ICreateAdminHedge = {
      shipments_id: [shipmentId],
      company_id: companyId,
      hedge_type: 'olme',
      olme_date: formattedDate,
    };
    dispatch(createHedgeThunk(hedgeData));
    onClose();
  };

  const brokerMessage = `Lorem ipsum`;

  return (
    <>
      {hedge && (
        <>
          <Box padding="34px 40px 100px 40px">
            <ScrapexSidebarTextH1>Hedge Create</ScrapexSidebarTextH1>
            <ScrapexGrayText>Hedge Number: </ScrapexGrayText>
            <span style={{ color: '#f44336' }}>{hedge.hedge_number}</span>
            <HedgeRequestDrawerInfo hedge={hedge} />
            <ScrapexSidebarTextH1>Order </ScrapexSidebarTextH1>
            <DataListItem title="Pure Metal, mt">{hedge.pure_metal}</DataListItem>
            <DataListItem title="Order Type, mt">Official LME (OLME)</DataListItem>
            <DataListItem title="Date">
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  value={date}
                  format="DD/MM/YYYY"
                  onChange={(newValue) => setDate(newValue)}
                  disablePast
                />
              </LocalizationProvider>
            </DataListItem>
            <DataListItem title="Broker's message">
              <Box display="flex" alignItems="center" justifyContent="end">
                <Typography>{brokerMessage}</Typography>
                <CopyIcon textToCopy={brokerMessage} />
              </Box>
            </DataListItem>
          </Box>
          <DrawerFooter>
            <Button
              variant="contained"
              onClick={createHedge}
              disabled={!date || !date.isValid() || date.isBefore(dayjs(), 'day')}
              endIcon={<ArrowForward />}
            >
              Add Hedge
            </Button>
          </DrawerFooter>
        </>
      )}
    </>
  );
};
