import { Box, Divider, IconButton, Link, ListItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DownloadingOutlined } from '@mui/icons-material';
import { ScrapexGrayText, ScrapexSidebarMediumTextH2 } from '@common/components';
import { useGetShipmentFiles } from '@app/hooks/useGetShipmentFiles';
import { formatDate } from '@app/utils';
import { IShipmentInvoice } from '@app/types';

interface InvoiceFieldProps {
  dealId?: string;
  invoice: IShipmentInvoice;
  hideDivider?: boolean;
}

export const InvoiceField: React.FC<InvoiceFieldProps> = ({
  dealId = '',
  invoice,
  hideDivider,
}) => {
  const { link, getFileName } = useGetShipmentFiles(dealId, invoice?.doc || '');
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (invoice?.doc) {
      getFileName().then((name) => setFileName(name));
    }
  }, [invoice?.doc]);

  return (
    <>
      <ListItem sx={{ padding: '16px 0', display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex" flexDirection="column">
          <ScrapexSidebarMediumTextH2>{invoice.type}</ScrapexSidebarMediumTextH2>
          <ScrapexGrayText>{formatDate(invoice.date)}</ScrapexGrayText>
        </Box>
        <Link href={link} target="_blank" underline="none">
          {fileName}
        </Link>
        <IconButton href={link}>
          <DownloadingOutlined fontSize="small" />
        </IconButton>
      </ListItem>
      {!hideDivider && <Divider sx={{ borderColor: 'var(--light-white-color)' }} />}
    </>
  );
};
