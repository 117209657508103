import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addContractAsync,
  getContractsThunk,
  getContractThunk,
  updateContractThunk,
} from './thunks';
import { IContractAdmin } from '@app/types';

interface IContractsState {
  contracts: IContractAdmin[];
  currentContract: IContractAdmin | null;
  isLoading: boolean;
}

const initialState: IContractsState = {
  contracts: [],
  currentContract: null,
  isLoading: false,
};

const contracts = createSlice({
  name: 'contractsSlice',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    addContract(state, action: PayloadAction<IContractAdmin>) {
      state.contracts.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContractsThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.contracts = payload.contracts;
      }
    });
    builder.addCase(updateContractThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentContract = payload;
      }
    });
    builder
      .addCase(addContractAsync.fulfilled, (state, { payload }) => {
        state.contracts.push(payload);
      })
      .addCase(getContractThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContractThunk.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.currentContract = payload;
        }
      });
  },
});

export const { actions, reducer } = contracts;
export default contracts.reducer;
