import { Box, Button } from '@mui/material';
import React, { useRef, useState } from 'react';
import { FileRow } from '../../fileRow';
import { AddOutlined } from '@mui/icons-material';
import { noFileId } from '@app/utils/isFileExists';
import { useUploadFiles } from '@app/hooks';

interface PdfFileSelectorProps {
  onPdfIdSelect: (pdfId: string) => void;
  isFileExists?: boolean;
  fileIdProp?: string;
}

export const PdfFileSelector: React.FC<PdfFileSelectorProps> = ({
  onPdfIdSelect,
  fileIdProp = noFileId,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileId, setFileId] = useState<string | undefined>(fileIdProp);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { upload } = useUploadFiles();

  const handleReloadFile = () => {
    setSelectedFile(null);
    fileInputRef?.current?.click();
  };

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      try {
        const uploadedId = await upload(files[0]);
        setSelectedFile(files[0]);
        onPdfIdSelect(uploadedId);
        setFileId(uploadedId);
      } catch (err) {
        console.error('Upload failed:', err);
      }
    }
  };

  const handleRemoveFileClick = () => {
    setSelectedFile(null);
    setFileId(noFileId);
    onPdfIdSelect(noFileId);

    const fileInput = fileInputRef.current;

    if (fileInput) {
      fileInput.value = '';
    }
  };

  return (
    <>
      {fileId !== noFileId ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <FileRow
            fileId={fileId}
            onReuploadClick={handleReloadFile}
            onClearClick={handleRemoveFileClick}
          />
        </Box>
      ) : (
        <>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => fileInputRef?.current?.click()}
            startIcon={<AddOutlined />}
          >
            Add PDF Contract
          </Button>
        </>
      )}
      <input
        ref={fileInputRef}
        hidden
        multiple={false}
        type="file"
        id="file"
        name="file"
        accept="application/pdf"
        onChange={handleFileInputChange}
      />
    </>
  );
};
