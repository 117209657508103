import { HedgingStatus } from '@app/types';
import { ScrapexBodyTextMedium, ScrapexGrayText } from '@common/components';
import { Box } from '@mui/material';
import classes from './styles.module.scss';

interface TextWithSubtitleProps {
  title: string;
  subtitle: string;
}

export const TextWithSubtitle = ({ title, subtitle }: TextWithSubtitleProps) => (
  <Box display="flex" flexDirection="column">
    <ScrapexBodyTextMedium>{title}</ScrapexBodyTextMedium>
    <ScrapexGrayText>{subtitle}</ScrapexGrayText>
  </Box>
);

export const HedgeNumberColored = ({
  hedgeNumber,
  status,
}: {
  hedgeNumber: string;
  status: HedgingStatus;
}) => {
  return <span className={`${classes[status]}`}>{hedgeNumber}</span>;
};
