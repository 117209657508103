import { Box, Button, Divider, IconButton, Link, ListItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AddOutlined, EditOutlined } from '@mui/icons-material';
import { LoadingSkeleton, ScrapexGrayText, ScrapexSidebarMediumTextH2 } from '@common/components';
import { useGetShipmentFiles } from '@app/hooks/useGetShipmentFiles';
import { formatDate } from '@app/utils';
import { IShipmentInvoice, InvoiceType } from '@app/types';
import { EditInvoiceModal } from './invoiceModal/editInvoiceModal';
import { InvoiceModal } from './invoiceModal';

interface InvoiceFieldProps {
  dealId?: string;
  shipmentId: string;
  invoice?: IShipmentInvoice;
  role: string;
}

const invoiceTypeLabels: Record<InvoiceType, string> = {
  invoice: 'Invoice',
  proforma: 'Proforma',
  provisional: 'Provisional',
  final: 'Final',
  debit_note: 'Debit note',
  credit_note: 'Credit note',
};

export const InvoiceField: React.FC<InvoiceFieldProps> = ({
  dealId = '',
  shipmentId,
  role,
  invoice,
}) => {
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { link, getFileName, loading } = useGetShipmentFiles(dealId, invoice?.doc || '');
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (invoice?.doc) {
      getFileName().then((name) => setFileName(name));
    }
  }, [invoice?.doc]);

  return (
    <>
      {invoice ? (
        <>
          {loading ? (
            <LoadingSkeleton size={1} />
          ) : (
            <>
              <ListItem
                sx={{ padding: '16px 0', display: 'flex', justifyContent: 'space-between' }}
              >
                <Box display="flex" flexDirection="column">
                  <ScrapexSidebarMediumTextH2>
                    {invoiceTypeLabels[invoice.type as InvoiceType]}
                  </ScrapexSidebarMediumTextH2>
                  <ScrapexGrayText>{formatDate(invoice.date)}</ScrapexGrayText>
                </Box>
                <Link href={link} target="_blank" underline="none">
                  {fileName}
                </Link>
                <IconButton onClick={() => setEditModalOpen(true)}>
                  <EditOutlined fontSize="small" />
                </IconButton>
              </ListItem>
              <Divider sx={{ borderColor: 'var(--light-white-color)' }} />
            </>
          )}
        </>
      ) : (
        <>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => setFileModalOpen(true)}
            startIcon={<AddOutlined />}
          >
            Add
          </Button>
        </>
      )}

      {fileModalOpen && (
        <InvoiceModal
          open={fileModalOpen}
          onClose={() => setFileModalOpen(false)}
          shipmentId={shipmentId}
          dealId={dealId}
          role={role}
        />
      )}
      {editModalOpen && invoice && (
        <EditInvoiceModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          invoice={invoice}
          shipmentId={shipmentId}
          dealId={dealId}
        />
      )}
    </>
  );
};
