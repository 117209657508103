import { useOpenDrawer } from '@app/hooks';

export const useOpenShipmentsDrawer = () => {
  const shipmentInfo = useOpenDrawer();
  const hedgeRequest = useOpenDrawer();

  const openShipmentInfoHandler = () => shipmentInfo.open();
  const closeShipmentInfoHandler = () => shipmentInfo.close();

  const openHedgeRequestHandler = () => hedgeRequest.open();
  const closeHedgeRequestHandler = () => hedgeRequest.close();

  const closeAllHandler = () => {
    shipmentInfo.close();
    hedgeRequest.close();
  };

  return {
    shipmentInfo,
    hedgeRequest,
    openShipmentInfoHandler,
    closeShipmentInfoHandler,
    openHedgeRequestHandler,
    closeHedgeRequestHandler,
    closeAllHandler,
  };
};
