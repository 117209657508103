import { combineReducers } from '@reduxjs/toolkit';
import requests from './auditCompanies/slice';
import auditCompany from './auditCompany/slice';
import companies from './companies/slice';
import contracts from './contracts/slice';
import shipments from './shipments/slice';

export const reducer = combineReducers({
  requests,
  auditCompany,
  companies,
  contracts,
  shipments,
});
