import { AppDispatch, RootState } from '@app/store';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'src/account/reducer/hedging/slice';
import { getHedgeByIdThunk, initHedgeThunk } from 'src/account/reducer/hedging/thunk';

export const useGetCurrentHedge = (hedgeId: string | null, shipmentsIds: string[] | null) => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    dispatch(actions.resetCurrentHedge());

    const fetchHedge = async () => {
      setLoading(true);
      setError(null);

      try {
        if (hedgeId) {
          await dispatch(getHedgeByIdThunk(hedgeId));
        } else if (shipmentsIds) {
          await dispatch(initHedgeThunk({ shipments_id: shipmentsIds }));
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      } finally {
        setLoading(false);
      }
    };

    fetchHedge();
  }, [hedgeId]);

  const hedge = useSelector((state: RootState) => state.account.hedging.currentHedge);

  return { hedge, loading, error };
};
