import { createAsyncThunk } from '@reduxjs/toolkit';
import httpClient from '../../../app/client/httpClient';
import { IAuditCompanyResponce } from '../../../app/types/api/audit';
import { actions } from './slice';

export const fetchGetCompanyById = createAsyncThunk('fetchGetCompanyById', async (id: string) => {
  let data;
  try {
    const res = await httpClient.get<IAuditCompanyResponce>(`api/audit/companies/${id}/`);
    data = res.data;
  } catch (error) {
    console.log(error);
  }
  return data;
});

export const fetchAproveCompany = createAsyncThunk(
  'fetchAproveCompany',
  async (id: string, { dispatch }) => {
    let data;
    try {
      const res = await httpClient.patch<IAuditCompanyResponce>(`api/audit/companies/${id}`, {
        approved: true,
        comment: '',
      });
      data = res.data;
    } catch (error) {
      console.log(error);
    }
    return data;
  },
);
