export interface IInput {
  value: string;
  isValid: boolean;
}

export interface IScrapexPasswordInput {
  value: string;
}

export enum DrawerType {
  Contract = 'contract',
  Company = 'company',
}
