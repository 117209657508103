import React from 'react';
import { Box } from '@mui/material';
import { ScrapexBodyText, ScrapexSidebarMediumTextH2 } from '@common/components';

interface CommentCardProps {
  comment: string;
}

export const CommentCard: React.FC<CommentCardProps> = ({ comment }) => {
  return (
    <Box sx={{ backgroundColor: '#F9FAFB', my: 1 }}>
      <Box display="flex" flexDirection="column" p="16px 40px" gap={2}>
        <Box display="flex" flexDirection="column" gap={2}>
          <ScrapexBodyText>Comment Owner: Admin</ScrapexBodyText>
          <ScrapexSidebarMediumTextH2>{comment}</ScrapexSidebarMediumTextH2>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}></Box>
      </Box>
    </Box>
  );
};
