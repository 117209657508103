import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@app/store';

import classes from './styles.module.scss';
import { Box, Stack } from '@mui/material';
import { LayoutProfile, LayoutSettings } from './components';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/ReactToastify.min.css';
import { NotificationCenter } from 'src/account/notifications';
import { ScrapexWidget } from '../widget';

export const Header = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const role = useSelector((state: RootState) => state.auth.auth.role);

  return (
    <header className={classes.header}>
      {role === 'trader' && (
        <Box sx={{ width: '620px', height: '100%', background: '#fff', padding: '20px 40px' }}>
          <ScrapexWidget />
        </Box>
      )}
      <ToastContainer
        style={{
          width: '400px',
        }}
      />
      <div className={classes.controls}>
        <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {role === 'trader' && <NotificationCenter />}
          <LayoutProfile />
          <LayoutSettings />
        </Stack>
      </div>
    </header>
  );
};
