import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface InputGeneralProps {
  children: ReactNode | ReactNode[];
}

export const InputContainer = ({ children }: InputGeneralProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& p': { color: 'var(--red-color)', mt: '12px' },
        '& label': { mb: '8px', fontSize: '16px', fontWeight: 700 },
        '& input': { p: '22px 16px' },
      }}
    >
      {children}
    </Box>
  );
};
