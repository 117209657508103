import { useState } from 'react';
import classes from './styles.module.scss';
import { Add } from '@mui/icons-material';

export const FAQ = () => {
  const [openQuestion, setOpenQuestion] = useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqs = [
    {
      question: 'Is ScrapEX a Broker?',
      answer: `No! ScrapEX isn't a Broker. ScrapEX is like a Trader who offers only hedging services and charges only for hedging services. All deal margin is yours.`,
    },
    {
      question: 'Why do I need ScrapEX if my buyer or seller offers me a price fixation option?',
      answer:
        'If buyer or seller offers you a price fixation option, it usually means that you are getting not the best price you can get from the deal. By hedging with ScrapEX you have a power to negotiate a better deal with your seller or buyer.',
    },
    {
      question: 'What is the minimum hedging lot?',
      answer: 'The minimum hedging lot for Copper is 1 MT',
    },
  ];

  return (
    <section className={classes.faq} id="faq">
      <div className={classes.title}>
        {' '}
        <h2>Frequently Asked Questions</h2>
      </div>

      <div className={classes.list}>
        {faqs.map((faq, index) => (
          <div key={index} className={classes.item}>
            <div className={classes.question} onClick={() => toggleQuestion(index)}>
              <h3>{faq.question}</h3>
              <span className={`${classes.icon} ${openQuestion === index ? classes.open : ''}`}>
                <Add fontSize="large" color="action" />
              </span>
            </div>
            <div
              className={`${classes.answerWrapper} ${openQuestion === index ? classes.open : ''}`}
            >
              <p className={classes.answer}>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
