export const contractColumns = [
  'Role',
  'Product',
  'Price',
  'Contract Weight, mt',
  'Shipments',
  'Hedged Shipments',
  'Available For Hedging',
  'Completed Shipments',
];
