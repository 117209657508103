import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  seller: yup.string().required('Seller is required'),
  buyer: yup.string().required('Buyer is required'),
  seller_contract_number: yup.string().required('Contract number is required'),
  contract_date: yup
    .date()
    .max(new Date(), 'Contract date cannot be in the future')
    .required('Contract date is required'),
  product: yup.string().required('Product is required'),
  metal: yup.string().required('Metal is required'),
  formula: yup.string().required('Formula is required'),
  product_weight: yup
    .number()
    .required('Product weight is required')
    .positive('Product weight must be greater than 0'),
  total_shipments: yup
    .number()
    .required('Number of shipments  is required')
    .positive('Number of shipments must be greater than 0'),
});
