import { InputLabel, SxProps } from '@mui/material';

interface InvoiceInputLabelProps {
  text: string;
  sx?: SxProps;
}

export const InvoiceInputLabel: React.FC<InvoiceInputLabelProps> = ({ text, sx }) => {
  return (
    <InputLabel sx={{ margin: '14px 0 4px 0', fontSize: '14px', fontWeight: 500, ...sx }}>
      {text}
    </InputLabel>
  );
};
