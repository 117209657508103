import { useEffect, useState } from 'react';
import { Box, IconButton, Typography, Popover } from '@mui/material';
import { Notifications } from '@mui/icons-material';

import { ScrapexGrayText } from '@common/components/display';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  getHedgeNotificationsThunk,
  getOtherNotificationsThunk,
} from '../reducer/notifications/thunk';
import { NotificationUnreadInfo } from './notificationCenter/components/notificationInfo';
import { formatDate } from '@app/utils';
import { NotificationTabs } from './notificationCenter/components/notificationTabs';
import { CustomBadge } from './notificationCenter/components/notificationIcon';
import { useGetNotifications } from './notificationCenter/hooks/useGetNotifications';
import { NotificationTabsContent } from './notificationCenter/components/notificationTabs/NotificationTabsContent';

export const NotificationCenter = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [tabValue, setTabValue] = useState(0);

  const {
    hedgeNotifications,
    otherNotifications,
    notificationsHistory,
    unreadHedgeCount,
    unreadOtherCount,
  } = useGetNotifications(tabValue);

  const getNotifications = () => {
    try {
      dispatch(getHedgeNotificationsThunk());
      dispatch(getOtherNotificationsThunk());
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const handleChange = (event: React.SyntheticEvent<unknown>, newValue: number) => {
    setTabValue(newValue);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const toggleNotificationCenter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box>
      <Box
        sx={{ margin: '8px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        onClick={toggleNotificationCenter}
      >
        <IconButton size="large" color="inherit">
          <CustomBadge mr="-3px" color="#ff9800" invisible={!unreadOtherCount}>
            <CustomBadge mr="3px" color="#FF0000" invisible={!unreadHedgeCount}>
              <Notifications color="inherit" />
            </CustomBadge>
          </CustomBadge>
        </IconButton>

        <Box display="flex" flexDirection="column" gap={0.5}>
          {!!unreadHedgeCount && (
            <NotificationUnreadInfo bg="#FF0000" quantity={unreadHedgeCount} type="HEDGE" />
          )}
          {!!unreadOtherCount && (
            <NotificationUnreadInfo bg="#ff9800" quantity={unreadOtherCount} type="OTHER" />
          )}
        </Box>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            background: '#fff',
            padding: '25px',
            borderRadius: '10px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              mb: 4,
              flexDirection: 'column',
            }}
          >
            <Typography variant="h6">Notifications</Typography>
            <ScrapexGrayText>Today, {formatDate(new Date().toISOString())} </ScrapexGrayText>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <NotificationTabs
              tabValue={tabValue}
              handleChange={handleChange}
              unreadHedgeCount={unreadHedgeCount}
              unreadOtherCount={unreadOtherCount}
            />
          </Box>

          <NotificationTabsContent
            tabValue={tabValue}
            hedgeNotifications={hedgeNotifications}
            otherNotifications={otherNotifications}
            notificationsHistory={notificationsHistory}
          />
        </Box>
      </Popover>
    </Box>
  );
};
