import { formatDate } from './date';

interface DateValue {
  isoDate: string;
  displayDate: string;
}

export const getDateValue = (dateString?: string): DateValue | null => {
  return dateString
    ? {
        isoDate: dateString,
        displayDate: formatDate(dateString),
      }
    : null;
};
