import { AppDispatch, RootState } from '@app/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getContractsThunk } from 'src/accountManager/reducer/contracts/thunks';
import { contractsRowData, generateContractsColumns } from '../data';

export const useGetContracts = () => {
  const contracts = useSelector((state: RootState) => state.accountManager.contracts.contracts);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getContractsThunk());
  }, []);

  const { columns } = generateContractsColumns();
  const { rows } = contractsRowData(contracts);

  return { contracts, columns, rows };
};
