import { ICompany } from '@app/types';

export const companiesRowData = (companies: ICompany[]) => {
  const rows: any = companies.map((company) => ({
    ...company,
    id: company.id,
  }));

  return { rows };
};
