export const enum ScrapexColorEnum {
  WHITE_COLOR = 'var(--white-color)',
  MAIN_TEXT_COLOR = 'var(--main-text-color)',
  FILL_TEXT_COLOR = 'var(--fill-text-color)',
  BLUE_TEXT_COLOR = 'var(--blue-text-color)',
  RED_COLOR = 'var(--red-color)',
  ORANGE_COLOR = 'var(--orange-color)',
  VIOLET_COLOR = 'var(--violet-color)',
  RED_SECONDARY_COLOR = 'var(--red-secondary-color)',
  GREEN_COLOR = 'var(--green-color)',
  BLUE_COLOR = 'var(--blue-color)',
  LIGHT_WHITE_COLOR = 'var(--light-white-color)',
  LIGHT_GRAY_PRIMARY_COLOR = 'var(--light-gray-primary-color)',
  LIGHT_GRAY_SECONDARY_COLOR = 'var(--light-gray-secondary-color)',
  LIGHT_BLUE_COLOR = 'var(--light-blue-color)',
  LIGHT_VIOLET_COLOR = 'var(--light-violet-color)',
  LIGHT_GREEN_COLOR = 'var(--light-green-color)',
  LIGHT_RED_COLOR = 'var(--light-red-color)',
  PRIMARY_COLOR = 'var(--primary-color)',
  PRIMARY_COLOR_100 = 'var(--primaty-color-100)',
  PRIMARY_COLOR_400 = 'var(--primary-color-400)',
  PRIMARY_COLOR_800 = 'var(--primary-color-800)',
  COMPLEMENTARY_COLOR = 'var(--complementary-color)',
}
