import { Typography } from '@mui/material';

interface FormErrorProps {
  errors?: string[] | string;
  touched?: boolean;
}

export const FormErrorSubtitle = ({
  errors,
  touched = false,
}: FormErrorProps): JSX.Element | null => {
  if (!touched) {
    return null;
  }

  const error = errors ? (Array.isArray(errors) ? errors.join(', ') : errors) : '';

  return <Typography sx={{ fontSize: '12px', color: 'var(--red-color)' }}>{error}</Typography>;
};
