import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getContractsThunk, getContractThunk } from './thunks';
import { IContractUser } from '../../../app/types/contracts';

interface IContractsState {
  contracts: IContractUser[];
  currentContract: IContractUser | null;
  isLoading: boolean;
}

const initialState: IContractsState = {
  contracts: [],
  currentContract: null,
  isLoading: false,
};

const contractsSlice = createSlice({
  name: 'userContractsSlice',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContractsThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.contracts = payload.contracts;
        }
      })
      .addCase(getContractThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getContractThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.currentContract = payload;
        }
        state.isLoading = false;
      });
  },
});

export const { actions, reducer } = contractsSlice;
export default contractsSlice.reducer;
