import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box } from '@mui/material';

interface TabData {
  title: string;
  content: React.ReactNode;
}

interface CommonTabsProps {
  tabs: TabData[];
  selectedTab: number;
  onTabChange: (newValue: number) => void;
}

export const CommonTabs: React.FC<CommonTabsProps> = ({ tabs, selectedTab, onTabChange }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    onTabChange(newValue);
  };

  return (
    <Box>
      <Tabs value={selectedTab} onChange={handleChange} variant="fullWidth">
        {tabs.map((tab, index) => (
          <Tab key={index} sx={{ textTransform: 'none' }} label={tab.title} />
        ))}
      </Tabs>
      {tabs[selectedTab].content}
    </Box>
  );
};
