import { combineReducers } from '@reduxjs/toolkit';
// import { reducer as login } from "./login/slice";
import { reducer as signup } from './signup/slice';
import companyRegistration from './company_registration/slice';
import { reducer as auth } from './auth/slice';
import login from './login/slice';

export const reducer = combineReducers({
  login,
  signup,
  companyRegistration,
  auth,
});
