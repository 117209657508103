import copy from 'clipboard-copy';
import { IconButton } from '@mui/material';
import { CopyAllOutlined } from '@mui/icons-material';

export const CopyIcon = ({ textToCopy }: { textToCopy: string }) => {
  const handleCopyClick = (textToCopy: string) => {
    copy(textToCopy);
  };
  return (
    <IconButton onClick={() => handleCopyClick(textToCopy)}>
      <CopyAllOutlined />
    </IconButton>
  );
};
