import { Box, Button, List, OutlinedInput, SxProps, Typography } from '@mui/material';
import {
  DataListItem,
  DrawerFooter,
  FormErrorSubtitle,
  ScrapexSidebarTextH1,
} from '@common/components';
import { CompanyStatusIndicator } from '../status';
import { CompanyStatus, ICompanyInfo } from '@app/types';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { createCompanyThunk, getCompaniesThunk } from 'src/accountManager/reducer/companies/thunks';
import { AppDispatch } from '@app/store';
import { validationSchema } from './validationSchema';

const formValuesMap = {
  name: 'name',
  email: 'email',
};

interface AddCompanyDrawerProps {
  onClose: () => void;
}

const outlinedInputStyles = (formError: boolean): SxProps => {
  return {
    width: '100%',
    input: {
      lineHeight: '20px',
      height: 'auto',
      padding: '8px !important',
    },
    fieldset: {
      border: `1px solid ${formError ? 'var(--red-color)' : 'var(--other-300)'}`,
    },
  };
};

export const AddCompanyDrawer = ({ onClose }: AddCompanyDrawerProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const initialValues: ICompanyInfo = { name: '', email: '', invitation_date: '' };

  const onSubmit = async (data: ICompanyInfo) => {
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split('T')[0];

      const completeData = { ...data, invitation_date: formattedDate };
      await dispatch(createCompanyThunk(completeData));
      await dispatch(getCompaniesThunk());
      onClose();
    } catch (error) {
      console.error('Error creating company:', error);
    }
  };

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <form onSubmit={handleSubmit} id="company">
        <Box padding="34px 40px 0 40px">
          <ScrapexSidebarTextH1>Add a new company</ScrapexSidebarTextH1>
          <List>
            <DataListItem title="Company Status">
              <CompanyStatusIndicator status={CompanyStatus.none} />
            </DataListItem>
            <DataListItem
              title="Company Name*"
              subtitle={<FormErrorSubtitle errors={errors.name} touched={touched.name} />}
            >
              <OutlinedInput
                name={formValuesMap.name}
                value={values.name}
                onChange={handleChange}
                placeholder="Enter company name"
                sx={outlinedInputStyles(!!errors.name && !!touched.name)}
              />
            </DataListItem>
            <DataListItem
              title="Company Email*"
              subtitle={<FormErrorSubtitle errors={errors.email} touched={touched.email} />}
            >
              <OutlinedInput
                type="email"
                name={formValuesMap.email}
                value={values.email}
                placeholder="Enter company email"
                sx={outlinedInputStyles(!!errors.email && !!touched.email)}
                onChange={handleChange}
              />
            </DataListItem>
          </List>
        </Box>
      </form>
      <DrawerFooter justifyContent="space-between">
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" type="submit" form="company">
          Add Company
        </Button>
      </DrawerFooter>
    </>
  );
};
