import { CloseOutlined } from '@mui/icons-material';
import { DialogTitle, IconButton } from '@mui/material';
import React from 'react';

interface DialogTitleModalProps {
  title: string;
  onClose: () => void;
}

export const DialogTitleModal: React.FC<DialogTitleModalProps> = ({ title, onClose }) => {
  return (
    <DialogTitle
      sx={{
        paddingBottom: '8px',
        paddingTop: '24px',
        fontWeight: 700,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {title}
      <IconButton onClick={onClose}>
        <CloseOutlined />
      </IconButton>
    </DialogTitle>
  );
};
