import { IContractAdmin } from '@app/types';
import { ScrapexGrayText } from '@common/components';
import { Box, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { calculateEstimatedShipmentWeight } from 'src/accountManager/contract/utlis/calculateEstimatedShipmentWeight';

interface IContractRowData {
  value: string | ReactNode;
  sx?: SxProps;
}

export const generateContractRowData = (contract: IContractAdmin | null): IContractRowData[] => {
  const estimatedShipmentWeight = calculateEstimatedShipmentWeight(
    contract?.product_weight,
    contract?.total_shipments,
  );
  return [
    {
      value: (
        <Box>
          <h6>{contract?.product}</h6>
          <ScrapexGrayText>{contract?.metal}</ScrapexGrayText>
        </Box>
      ),
    },
    {
      value: contract?.formula,
    },
    {
      value: contract?.product_weight,
    },
    {
      value: (
        <Box>
          <Typography>{contract?.total_shipments} Shipments</Typography>
          <ScrapexGrayText>{estimatedShipmentWeight} = 1 shipment</ScrapexGrayText>
        </Box>
      ),
    },
    {
      value: (
        <Box>
          <Typography>{contract?.seller?.name}</Typography>
          <ScrapexGrayText>{contract?.seller?.email}</ScrapexGrayText>
        </Box>
      ),
    },
    {
      value: (
        <Box>
          <Typography>{contract?.buyer?.name}</Typography>
          <ScrapexGrayText>{contract?.buyer?.email}</ScrapexGrayText>
        </Box>
      ),
    },
    {
      value: (
        <Box>
          <Typography>{contract?.completed_shipments}</Typography>
          <ScrapexGrayText>{contract?.shipments_delivery_in_progress} in progress</ScrapexGrayText>
        </Box>
      ),
    },
  ];
};
