import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

export const Terms = () => {
  return (
    <div className={styles.container}>
      <h3>The Scrapex platform </h3>
      <h2>Terms and Conditions</h2>
      <h3>1. Preamble</h3>
      <p>
        1.1. The Scrapex platform (hereinafter referred to as the "Platform") is an online platform
        for trading commodities, hedging and other related services. <br />
        1.2. These Terms and Conditions (hereinafter referred to as the “T&Cs”) are a public offer
        which is addressed to any legal entity intending to use the services of the Platform
        (hereinafter referred to as the "Client" or “User”). <br />
        1.3. These T&Cs govern the rights and obligations of the Platform and Users (as defined
        below, jointly “Parties”) with respect to accessing the Platform and the services offered
        thereover in accordance with the T&Cs set forth herein. <br />
        1.4. Acceptance of these T&Cs by the Client is carried out by ticking the “I agree with the
        Terms and Conditions”-field and clicking on the “Sign in”-button in the registration form on
        the login page of the Platform ({' '}
        <a href="https://scrapex.app/login" target="_blank" rel="noopener noreferrer">
          https://scrapex.app/login
        </a>{' '}
        or{' '}
        <a href="https://scrapex.ae/login" target="_blank" rel="noopener noreferrer">
          https://scrapex.ae/login
        </a>
        ), which signifies the Client's full and unconditional agreement to all its terms and
        conditions altogether with the{' '}
        <Link to="/policy" target="_blank">
          Privacy Policy
        </Link>
        , which is the integral part of the T&Cs. Acceptance of the T&Cs creates contractual
        obligations between Scrapex DMCC and the Client (hereinafter referred to as the "Agreement”)
        on the terms specified in these T&Cs. The Client also gives consent to the processing of his
        personal data by accepting the T&C’s.
      </p>
      <h3>2. Definitions</h3>
      <p>
        2.1. Platform – an online software system designed for executing trading commodities and
        hedging transactions. Platform is operated by Scrapex DMCC, a company with head office at:
        2906, HDS Tower, Cluster F, JLT Dubai, United Arab Emirates, Reg.No. DMCC197766, on the
        basis of the license agreement with the rightful owner of the Platform - Scrapex Ltd, a
        company with head office at: Cyprus, Makariou III, 228, Agios Pavlos Block A, Flat/Office
        113, Limassol 3030, Reg.No. HE430110. <br />
        2.2. Client, User – legal person registered on the Platform and accepted these T&Cs. <br />
        2.3. Trading Transaction - the activity of buying and selling commodities in between two
        parties, namely Buyer and Seller. <br />
        2.4. Hedging Transaction – a financial operation aimed at mitigating the risks associated
        with price fluctuations in metals and currencies. <br />
        2.5. Back Office – the Scrapex DMCC department responsible for manually entering the
        Client’s contract data. <br />
        2.6. Platform Administrator - Scrapex DMCC or it’s department or manager responsible for
        supporting clients’ transactions.
        <br />
        2.7. Listing - means requests for offers or bids for commodities. <br />
        2.8. TP (take profit) – an order in which a customer stipulates the maximum/minimum price of
        LME or other exchange acceptable. <br />
        2.9. GTC (good till cancelled) – an order at a specified price, which is valid at any time
        during market hours until executed or the order is cancelled. <br />
        2.10. OLME (London Metal Exchange Official Price) – an order that will be executed against
        official LME prices on a specific date. <br />
        2.11. Official LME prices - the prices quoted in the second Ring of the morning LME session.{' '}
        <br />
        2.12. ACM – (at the current market) - an order without regard to a specific price. The
        company will execute a market order at the best price available after receiving the order.
      </p>
      <h3>3. Registration and Verification</h3>
      <p>
        3.1. Platform provides services solely for entrepreneurs. An entrepreneur means a legal
        person or a legal partnership who or which, when entering into a legal transaction, acts in
        exercise of their trade, business or profession. Any natural person acting for or on behalf
        of a legal entity will not become Client or User himself but acts as the agent for the
        respective legal entity who registers on the Platform. <br />
        3.2. To access the Platform's services, the Client must complete the registration process,
        providing accurate data and documents for identity verification. Registration requires the
        Client to provide the information which Platform requests from the Client during the
        registration process. This may include, but is not limited to, the company name and address,
        email address, phone number, registration and VAT number (tax ID) and copy of certificate of
        incorporation, as well as the name and position of the person registering the Client. The
        Client must provide Platform with true, accurate and complete information when registering
        and maintain and promptly update that information from time to time as required. Documents
        should be sent to email <a href="mailto:info@scrapex.ae">info@scrapex.ae</a> for their
        further manual processing. The Client gives consent to the processing of his personal data
        by sending any of his personal data to the email above. <br />
        3.3. Back Office is performing sanction checks on every party registering on the Platform by
        accessing third party databases and will only enable registration to the extent and as long
        as such party is sanction free according to these third-party databases. Platform
        Administrator reserves the right to reject any registration in whole or in part to anyone at
        any time and for any reason, especially if an entrepreneur trying to register fails the
        sanction check set out in this Section. <br />
        3.4. After completing the registration process, the Client is provided with a login and
        password to access the Platform to his email address. Platform Administrator reserves the
        right to refuse registration or verification without explanation. <br />
        3.5. By registering on the Platform and providing the Platform Administrator with his email
        address, the Client expressly agrees to receive any information and notifications related to
        the operation of the Platform, including marketing information, to the specified email
        address.
      </p>
      <h3>4. Terms of Platform Use</h3>
      <p>
        4.1. The Client agrees to use the Platform only for its own business activities and solely
        for conducting hedging transactions as stipulated by these T&Cs. Any use of the Platform by
        the Client that is in excess of the agreed scope (over-use), constitutes a material breach
        of the T&Cs. Use of the Platform by or making it available to third parties other than the
        Client’s managers is not allowed. In particular, the Client is not permitted to reproduce or
        sell the Platform, or parts thereof to third parties. <br />
        4.2. The Client is not entitled to examine the mode of operation of the Platform by way of
        so-called "reverse engineering", redesign, modify, edit, transfer, decompile, and further
        develop the Platform. <br />
        4.3. The Client's access to and use of the Platform is subject to its compliance with the
        following usage requirements: <br /> - provide correct and complete data required and keep
        them up to date at all times during the these T&Cs are legally binding; <br /> - keep the
        login data to the User account for the Platform safe and keep it secure from unauthorized
        access by third parties; <br /> - carry out transactions via the Platform in compliance with
        all applicable laws; <br /> - not violate the T&Cs and/or applicable law relevant to the use
        of the Platform; <br /> - do not take any actions that may disrupt the Platform's operations
        or attempt unauthorized access to its resources, do not use any virus, spyware, malware or
        other computer code, file or program that may interfere with the operation and/or
        availability of the Platform; <br /> - do not use the Platform to distribute illegal content
        and/or content that infringes any applicable law and/or the rights of any third parties.{' '}
        <br />
        4.4. The Client guarantees to follow these T&Cs as well as the law and in particular not to
        use or misuse the Platform to violate the law. Platform Administrator is entitled to apply
        suitable monitoring and security mechanisms to detect, investigate and sanction violations
        by the Client.
      </p>
      <h3>5. Hedging Transactions</h3>
      <p>
        5.1. The Client has the right to create requests for hedging transactions, specifying all
        necessary transaction parameters required by the Platform. <br />
        5.2. Back Office will review and manually enter the contract data, after which the Client
        confirms the transaction. <br />
        5.3. All transactions are considered concluded upon confirmation by both Platform
        Administrator and the Client. <br />
        5.4. When conducting hedging transactions through the Platform the Client must:
        <br />
        5.4.1. When acting as a Seller: <br /> - be the legal owner of the goods and to be
        authorised to sell such goods, <br /> - be able to transfer legal title to such goods free
        from any encumbrances, claims or liens, <br /> - ensure that the Listing on the Platform
        offering the goods is complete, accurate and not misleading, <br /> - ensure that the goods
        comply with any applicable laws, are safe, and packaged appropriately, <br /> - fully
        perform any contract entered into with a Buyer.
        <br />
        5.4.2. When acting as a Buyer: <br /> - comply with any applicable laws, <br /> - fully
        perform any contract entered into with a Seller, <br /> - ensure that the Listing on the
        Platform with the intention to buy the goods is complete, accurate and not misleading.{' '}
        <br /> In case the Client violates the requirements of this Section 5.4, the provisions of
        Section 6.3 apply.
      </p>
      <h3>6. Rights and Obligations of the Parties</h3>
      <p>
        6.1. The Client has the right to access the Platform's services and receive information of
        the status of their transactions. <br />
        6.2. Platform Administrator undertakes to provide services in accordance with the T&Cs,
        ensuring the confidentiality and protection of the Client's personal data. It is not a
        breach of the Platform’s availability if the Client is unable to use the Platform due to:{' '}
        <br /> - downtimes caused by maintenance services; <br /> - downtimes caused by disruptions
        beyond the control of Platform Administrator (force majeure, in particular unforeseeable
        hardware failures, strikes, natural events, etc.); <br /> - downtimes caused by virus or
        hacker attacks, provided that Platform Administrator has taken technical protective measures
        in line with good industry practice; <br /> - downtimes required for the installation of
        urgently required security patches; <br /> - downtimes caused by a User, in particular due
        to problems arising from their software, hardware or other technology or equipment, or
        problems with their access to the internet, or unavailability of the Client partner's
        systems or due to other interruptions caused by the Client’s Partner (e.g. failure of the
        Client’s Partner to cooperate); <br /> - downtimes caused by third parties or third party
        systems over which Platform Administrator does not have any influence (e.g. unavailability
        of public telecommunication networks). <br />
        Platform Administrator will endeavor to give notice of any unavailability of any services
        and functionalities of the Platform within reasonable time prior to any such unavailability
        to the Client. <br />
        6.3. Platform Administrator is entitled to limit or suspend the Client's access to the
        Platform temporarily or permanently if the Client fails to comply with the requirements set
        forth in the T&Cs, in particular should the Client fail to pay any fees or other amounts due
        hereunder as and when due, Platform Administrator shall provide written notice to the Client
        by email. Platform Administrator will reinstate the suspended access authorizations if the
        Client has proven to Platform Administrator in a suitable form and to Platform
        Administrator's satisfaction that the violation in question has ceased and the Client has
        taken appropriate precautions to prevent such violations for the future. A suspension of the
        Clients’ access shall not be deemed as termination of the T&Cs, unless explicitly stated
        otherwise by Platform Administrator. Any suspension shall also not affect payment
        obligations hereunder. <br />
        6.4. The Platform is subject to constant technological progress and, therefore, Platform
        Administrator is entitled to modify the Platform by replacing, cancelling, adding, enhancing
        or further developing the functionalities and services of the Platform without notice at any
        time and at its sole discretion. Platform Administrator shall notify the Client only if any
        essential functionalities or services of the Platform are either added, or replaced or
        cancelled.
      </p>
      <h3>7. Remuneration; Payment Terms</h3>
      <p>
        7.1. The Platform is currently operated in trial mode and therefore no fees will be charged
        and the T&Cs provisions regarding payment will not apply. <br />
        7.2. Platform Administrator may at any time unilaterally introduce a fee for the Platform
        services by adding to these T&CSs with the relevant provisions and notifying the Clients
        about this via mail or in the Client’s personal account.
      </p>

      <h3>8. Liability</h3>
      <p>
        8.1. Platform Administrator shall only be liable if any loss or damage is cause by an
        infringement of a fundamental contractual duty. In such case, Platform Administrator’s
        liability shall be limited to the amount of the typically foreseeable damage. A fundamental
        contractual duty in the aforementioned sense is a duty which is essential for duly execution
        of the T&Cs and the achievement of the T&Cs’ purpose and on whose compliance the Parties
        regularly rely on. The typically foreseeable damage is any damage which occurs as typical
        effect in a normal course of a damaging event. The Parties agree that such typically
        foreseeable damage does not exceed the remuneration paid by the Client to Platform
        Administrator for provision of the services under the T&Cs in the three months preceding the
        damaging event. Otherwise, Platform Administrator’s liability shall be excluded. Neither
        party to the T&Cs shall be held responsible for the performance of any obligations under the
        T&Cs if such performance is hindered or prevented by any circumstances of Force Majeure.
        However, the foregoing does not excuse the Client from the payment of all amounts owing
        hereunder as and when due.
      </p>
      <h3>9. Confidentiality and Data Protection</h3>
      <p>
        9.1. Platform Administrator complies with the requirements of the Platform Administrator’s
        Privacy Policy regarding the processing and storage of the Client's personal data. <br />
        9.2. The Client agrees to the processing of their personal data, including the use of
        cookies, for the purpose of providing the Platform's services. <br />
        9.3. Platform Administrator will use any company, product and/or price information provided
        by the Client solely for the purpose of providing and improving services according to these
        T&Cs. Platform Administrator will not use or share such information with any third party for
        other purposes than providing the services in any format or form that would enable
        allocation of such information to the relevant Client. <br />
        9.4. The Client agrees to be identified as a customer of Platform and that Platform
        Administrator may refer to the Client by name, trade name and trademark, if applicable, and
        may briefly describe the Client’s business in Platform Administrator’s marketing materials,
        on Platform Administrator’s web site, or other public documents. <br />
        9.5. Any information, findings, results, data and documents disclosed between the Parties or
        obtained by one Party in connection with the T&Cs or its execution ("Confidential
        Information") shall be subject to confidentiality, regardless of how the Confidential
        Information is embodied or obtained (e.g. by unencrypted email) or whether it is expressly
        marked as requiring confidentiality (e.g. "confidential" or "secret"). The Parties shall
        undertake appropriate technical and organizational measures to prevent Confidential
        Information from being disclosed to or becoming accessible by third parties.
      </p>
      <h3>10. Risk Notification</h3>
      <p>
        10.1. The Client is informed of the potential financial risks associated with hedging
        transactions and agrees that Platform Administrator is not responsible for any losses
        incurred by the Client as a result of such transactions.
      </p>

      <h3>11. Jurisdiction and Dispute Resolution</h3>

      <p>
        11.1. These T&Cs are governed by the laws of the United Arab Emirates. <br />
        11.2. All disputes arising from the execution of these T&Cs shall be resolved in a court of
        competent jurisdiction in the UAE.
      </p>
      <h3>12. Term, Termination of the Agreement</h3>

      <p>
        12.1. The Agreement comes into effect with the first login on the Platform made by Client
        and remains in effect until being terminated by the Parties or by Platform Administrator
        solely. In case of any changes made to the T&Cs by Platform Administrator, the Client agrees
        and accepts the updated version of the T&Cs by the very first next login to the Platform, as
        described in Section 1.4. <br />
        12.2. Platform Administrator is entitled to terminate the Agreement for due cause, in
        particular if the Client materially or repeatedly breached its obligations under the
        Agreement. Any notice of termination must be in writing. The date of receipt of the letter
        of termination or notification in the Client’s personal account shall be decisive for
        compliance with the deadline.
      </p>
    </div>
  );
};
