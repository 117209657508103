import ConfirmationDialog from '@common/components/dialog/confirmationDialog';

interface ResetPasswordModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  companyName: string;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
  open,
  onClose,
  onConfirm,
  companyName,
}) => {
  const title = 'Reset Password';
  const content = 'Do you really want to reset the password? This action is irreversible';
  const subtitleItems = [{ key: 'Company name:', value: companyName }];

  const handleConfirm = () => {};

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={title}
      subtitleItems={subtitleItems}
      content={content}
      onConfirm={handleConfirm}
    />
  );
};

export default ResetPasswordModal;
