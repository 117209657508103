import React from 'react';
import { Tabs, Tab, Typography } from '@mui/material';
import { TabTitle } from '../tabTitle';

interface NotificationTabsProps {
  tabValue: number;
  handleChange: (event: React.SyntheticEvent<unknown>, newValue: number) => void;
  unreadHedgeCount: number;
  unreadOtherCount: number;
}

export const NotificationTabs: React.FC<NotificationTabsProps> = ({
  tabValue,
  handleChange,
  unreadHedgeCount,
  unreadOtherCount,
}) => {
  return (
    <Tabs value={tabValue} onChange={handleChange}>
      <Tab
        sx={{
          '& .MuiTab-label': {
            padding: 0,
          },
        }}
        label={<TabTitle title="HEDGE NOTIFICATIONS" count={unreadHedgeCount} />}
      />
      <Tab label={<TabTitle title="OTHER NOTIFICATIONS" count={unreadOtherCount} />} />
      <Tab label={<Typography fontSize={14}>HISTORY</Typography>} />
    </Tabs>
  );
};
