import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assoc, assocPath, pipe } from 'ramda';
import { IAuditCompanyResponce } from '../../../app/types/api/audit';
import { fetchAproveCompany, fetchGetCompanyById } from './thunks';

// interface IAuditComapnyState extends IAuditCompanyResponce {}

const initialState: { data: IAuditCompanyResponce } = {
  data: {
    published: false,
    validation: {
      bank_info: false,
      company_info: false,
      kyc_info: false,
      team_info: false,
    },
    approved: false,
    id: '',
    name: '',
    registration_code: '',
    vat_id: '',
    bank: {
      name: '',
      country: '',
      address: {
        country: '',
        state: '',
        city: '',
        postal: '',
        address1: '',
        address2: '',
      },
      account_number: '',
      details: '',
    },
    owner: null,
    contact: {
      email: '',
      phone: '',
      phone2: '',
    },
    address: {
      country: '',
      state: '',
      city: '',
      postal: '',
      address1: '',
      address2: '',
    },
    kyc: {
      documents: [],
    },
  },
};

const auditCompanySlice = createSlice({
  name: 'auditCompanySlice',
  initialState,
  reducers: {
    setCompany(state, { payload }: PayloadAction<IAuditCompanyResponce>) {
      if (payload) {
        state.data = payload;
      }
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetCompanyById.fulfilled, (state, { payload }) => {
        if (payload) {
          state.data = payload;
          state.data.kyc = state.data.kyc || { documents: [] };
          state.data.kyc.documents = payload.kyc.documents || [];
        }
      })
      .addCase(fetchAproveCompany.fulfilled, (state, { payload }) => {
        if (payload) {
          state.data = payload;
          state.data.kyc = state.data.kyc || { documents: [] };
          state.data.kyc.documents = payload.kyc.documents || [];
        }
      });
  },
});

export const { actions, reducer } = auditCompanySlice;
export default auditCompanySlice.reducer;
