import { HedgeType } from './api';
import { IContractAdmin } from './contracts';
import { IMarketMessage } from './market';
import { IShipment, ShipmentStatus } from './shipments';

export enum HedgingStatus {
  None = 'none',
  //acm
  Filled = 'acm_filled',
  Quotation = 'acm_waiting_quotation_reaction',
  Waiting = 'acm_waiting_quotation',
  Confirmed = 'acm_confirmed',
  Timeout = 'acm_timeout',
  AdminTimeout = 'acm_admin_timeout',
  AdminTimeoutConfirmed = 'acm_admin_timeout_admin_signed',
  UserTimeout = 'acm_user_timeout',
  UserRejectedQuotation = 'acm_user_rejected_quotation',
  AdminConfirmedRejectedQuotation = 'acm_user_rejected_quotation_admin_signed',
  AdminRejected = 'acm_admin_rejected',
  AdminRejectedTimeout = 'acm_user_timeout_admin_signed',
  //tp
  WaitingTP = 'tp_request',
  AtWork = 'tp_order',
  FilledTP = 'tp_filled',
  AdminRejectedTP = 'tp_request_admin_rejected_admin_signed',
  AdminRejectedOrderTP = 'tp_order_admin_rejected_admin_signed',
  AdminConfirmedRejectRequestTP = 'tp_request_user_rejected_admin_signed',
  AdminConfirmedRejectOrderTP = 'tp_order_user_rejected_admin_signed',

  UserRejectedRequest = 'tp_request_user_rejected',
  UserRejectedOrder = 'tp_order_user_rejected',
  //olme
  WaitingOLME = 'olme_request',
  AtWorkOLME = 'olme_order',
  FilledOLME = 'olme_filled',
  UserRejectedRequestOLME = 'olme_request_user_rejected',
  UserRejectedOrderOLME = 'olme_order_user_rejected',
  AdminRejectedOLME = 'olme_order_admin_rejected_admin_signed',
  AdminRejectedRequestOLME = 'olme_request_admin_rejected_admin_signed',

  AdminConfirmedRejectRequestOLME = 'olme_request_user_rejected_admin_signed',
  AdminConfirmedRejectOrderOLME = 'olme_order_user_rejected_admin_signed',
}

export enum HegdgeEvent {
  InitHedgeRequest = 'InitHedgeRequest',
  InitHedgeResponse = 'InitHedgeResponse',
}

export interface IHedgeShipment {
  eta: string;
  shipment_status: ShipmentStatus;
  counterparty_name: string;
  estimated_weight: number;
  final_weight: number;
  id: string;
  ordinal_number: number;
  shipment_number: number;
  pure_metal: number;
  limit_fixation_date: string;
}

export interface IHedge {
  id: string;
  hedge_number: string;
  hedge_status: HedgingStatus;
  company_name: string;
  market: IMarketMessage;
  product: string;
  metal: string;
  formula: string;
  weight: number;
  pure_metal: number;
  hedge_type: HedgeType;
  tp_exchange_level: number;
  limit_fixation_date: string;
  deadline: string;
  quotation_deadline: string;
  quotation: number;
  fixed_exchange: number;
  final_product_price: number;
  request_time: string;
  filled_time: string;
  hedge_shipments: IHedgeShipment[];
  contract_number: string;
  contract: string;
  olme_date: string;
  tp_estimated_product_price: number;
  message?: string;
}

export interface IUnhedgedShipment {
  deal?: IContractAdmin;
  shipment: IShipment;
  seller_hedge?: IHedge;
  buyer_hedge?: IHedge;
}

export interface IGetMarketToken {
  sid: string;
}
