import { formatDate, renderDateOrNone, renderValueOrNone } from '@app/utils';
import { dateSortComparator } from '@app/utils/sortComparators';
import { ContractRole, ContractStatusIndicator, TextWithSubtitle } from '@common/components';
import { GridColDef } from '@mui/x-data-grid-premium';

export const generateContractsColumns = () => {
  const columns: GridColDef[] = [
    {
      field: 'deal_status',
      headerName: 'Status',
      width: 120,

      renderCell: (params) => <ContractStatusIndicator status={params.value} />,
    },

    {
      field: 'customer_role',
      headerName: '',
      renderCell: (params) => <ContractRole role={params.value} />,
    },
    {
      field: 'contract_number',
      headerName: 'Contract No.',
    },
    {
      field: 'counterparty',
      width: 160,
      headerName: 'Counterparty',
    },
    {
      field: 'contract_date',
      headerName: 'Date',
      width: 110,
      valueGetter: (params) => {
        return formatDate(params.value);
      },
      sortComparator: dateSortComparator,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'product',
      headerName: 'Product/Metal',
      renderCell: (params) => <TextWithSubtitle title={params.value} subtitle={params.row.metal} />,
    },
    {
      field: 'formula',
      width: 160,
      headerName: 'Price',
    },
    {
      field: 'limit_fixation_date',
      width: 160,
      align: 'right',
      headerAlign: 'right',
      headerName: 'Nearest Limit Fix. date',
      renderCell: (params) => renderDateOrNone(params.value),
    },
    {
      field: 'product_weight',
      width: 160,
      headerName: 'Contract Weight, mt',
      align: 'right',
    },
    {
      field: 'total_shipments',
      width: 160,
      headerName: 'Total Shipments',
      align: 'right',
    },
    {
      field: 'hedged_shipments',
      width: 160,
      headerName: 'Hedged Shipments',
      align: 'right',
      renderCell: (params) => renderValueOrNone(params.value),
    },
    {
      field: 'shipments_in_orders',
      width: 160,
      headerName: 'Shipments in orders',
      align: 'right',
      renderCell: (params) => renderValueOrNone(params.value),
    },
    {
      field: 'request_shipments',
      width: 200,
      headerName: 'Shipments in hedge request',
      align: 'right',
      renderCell: (params) => renderValueOrNone(params.value),
    },
  ];
  return { columns };
};
