import React, { useEffect, useState } from 'react';
import { Box, ButtonGroup, CircularProgress, IconButton, SxProps, Typography } from '@mui/material';
import { DeleteOutlineOutlined, DownloadingOutlined } from '@mui/icons-material';
import { useGetShipmentFiles } from '@app/hooks/useGetShipmentFiles';
import { ScrapexGrayText } from '@common/components';
import { getFileUrl } from '@app/utils';

interface ImageSquareProps {
  fileId: string;
  dealId?: string;
  onClearClick?: () => void;
  hasDownloadFile?: boolean;
  hasRemoveFile?: boolean;
  sx?: SxProps;
  onImageClick: () => void;
}

export const ImageSquare: React.FC<ImageSquareProps> = ({
  fileId,
  dealId = '',
  onClearClick,
  hasDownloadFile,
  hasRemoveFile = true,
  sx,
  onImageClick,
}) => {
  const { link, loading, error, getFileName } = useGetShipmentFiles(dealId, fileId);
  const [isHovered, setIsHovered] = useState(false);
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    getFileName().then((name) => setFileName(name));
  }, [fileId]);

  const handleDownloadFile = async () => {
    const link = await getFileUrl(dealId, fileId, 'admin');
    window.open(link, '_self');
  };

  const handleImageClick = () => {
    onImageClick && onImageClick();
  };

  const containerStyles: SxProps = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '144px',
    height: '144px',
    overflow: 'hidden',
    ...sx,
    '&:hover': {
      cursor: 'pointer',
    },
  };

  const overlayStyles: SxProps = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.4)',
  };

  const buttonGroupStyles: SxProps = {
    position: 'absolute',
    opacity: isHovered ? 1 : 0,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'opacity 0.3s ease',
    display: 'flex',
    gap: '8px',
  };

  const iconButtonStyles: SxProps = {
    background: '#f5f5f5',
    '&:hover': { background: '#f5f5f5' },
  };

  return (
    <>
      {error && <p>Error: {error.message}</p>}
      {!error && (
        <>
          {fileId ? (
            <Box
              sx={containerStyles}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {loading ? (
                <CircularProgress sx={{ margin: '0 auto' }} />
              ) : (
                <Box onClick={handleImageClick}>
                  <img src={link} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                  {isHovered && <Box sx={overlayStyles} />}
                  <Box
                    sx={{
                      visibility: isHovered ? 'visible' : 'hidden',
                      position: 'absolute',
                      bottom: 16,
                      left: 8,
                      width: '90%',
                    }}
                  >
                    <Typography
                      noWrap
                      color="#fff"
                      sx={{ fontSize: '12px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                      {fileName}
                    </Typography>
                  </Box>
                </Box>
              )}
              <ButtonGroup sx={buttonGroupStyles}>
                {hasDownloadFile && (
                  <IconButton href={link} sx={iconButtonStyles}>
                    <DownloadingOutlined fontSize="small" color="secondary" />
                  </IconButton>
                )}
                {hasRemoveFile && (
                  <IconButton onClick={onClearClick} sx={iconButtonStyles}>
                    <DeleteOutlineOutlined color="error" fontSize="small" />
                  </IconButton>
                )}
              </ButtonGroup>
            </Box>
          ) : (
            <Box>
              <ScrapexGrayText>None</ScrapexGrayText>
            </Box>
          )}
        </>
      )}
    </>
  );
};
