import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../../app/store';
import { IFetchStateSettings } from '../../../app/types/api/fetchSettings';
import type { ILogInSliceState } from '../../../app/types/auth';

const initialState: ILogInSliceState & IFetchStateSettings = {
  login_data: '',
  isPhoneInput: false,
  isLoginInitialized: false,
  fetchError: null,
  isFetching: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    proceedLoginProcess(state, { payload }: PayloadAction<string>) {
      state.login_data = payload;
      state.isPhoneInput = /^[\d ()+-]+$/.test(payload);
      state.isLoginInitialized = true;
    },
    initializeLogin(state) {
      state.isLoginInitialized = false;
    },
    setPhoneInput(state, { payload }: PayloadAction<string>) {
      state.isPhoneInput = payload.charAt(0) === '+';
    },
    setIsFetching(state, { payload }: PayloadAction<boolean>) {
      state.isFetching = payload;
    },
    reset(): ILogInSliceState & IFetchStateSettings {
      return initialState;
    },
  },
});

export const { actions } = loginSlice;
export const initializeLoginAction = loginSlice.actions.initializeLogin;
export const setPhoneInputAction = loginSlice.actions.setPhoneInput;
export const selectIsPhoneInput = (state: RootState) => state.auth.login.isPhoneInput;
export const selectLogInData = (state: RootState) => state.auth.login.login_data;
export const selectIsLoginInitialized = (state: RootState) => state.auth.login.isLoginInitialized;
export const selectIsLoginFetching = (state: RootState) => state.auth.login.isFetching;

export const { initializeLogin, proceedLoginProcess, reset, setIsFetching, setPhoneInput } =
  loginSlice.actions;
export default loginSlice.reducer;
