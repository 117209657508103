import { Badge } from '@mui/material';
import React from 'react';

interface CustomBadgeProps {
  children: JSX.Element;
  mr: string;
  color: string;
  invisible: boolean;
}
export const CustomBadge: React.FC<CustomBadgeProps> = ({ children, mr, color, invisible }) => {
  return (
    <Badge
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiBadge-badge': {
          borderRadius: '100%',
          height: 10,
          width: 10,
          backgroundColor: color,
          mr: mr,
          border: '1px solid var(--other-900)',
        },
      }}
      overlap="circular"
      variant="dot"
      invisible={invisible}
    >
      {children}
    </Badge>
  );
};
