import { outlinedInputStyles } from '@common/components/input';
import { OutlinedInput } from '@mui/material';

interface DotDecimalInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}

export const DotDecimalInput = ({
  value,
  onChange,
  placeholder = 'Enter the value',
}: DotDecimalInputProps) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sanitizedValue = event.target.value
      .replace(/^\.+|[^0-9.]|\.(?=.*\.)/g, '') // Allow only digits and one dot
      .replace(/^(\d*\.\d{0,2}).*$/g, '$1'); // Allow up to two digits after dot
    onChange(sanitizedValue);
  };

  return (
    <OutlinedInput
      sx={outlinedInputStyles()}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={handleInputChange}
    />
  );
};
