import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Panel from '../../common/components/panel';
import { actions } from '../reducer/auditCompanies/slice';
import ContractsTable from './components/contractsTable';

export const Contracts = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.reset());
  }, []);

  return (
    <Box p="20px 40px">
      <ContractsTable />
    </Box>
  );
};
