export enum HedgeType {
  ACM = 'acm',
  TakeProfit = 'tp',
  OLME = 'olme',
}

export const HedgeTypeStringMap: Record<HedgeType, string> = {
  [HedgeType.ACM]: 'At the current market (ACM)',
  [HedgeType.TakeProfit]: 'Take Profit (TP)',
  [HedgeType.OLME]: 'Official LME',
};
