import { AppDispatch, RootState } from '@app/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { actions } from 'src/account/reducer/contracts/slice';
import { getContractThunk } from 'src/account/reducer/contracts/thunks';

export const useGetCurrentContract = (id?: string) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (id) dispatch(getContractThunk({ id }));
    return () => {
      dispatch(actions.reset());
    };
  }, [dispatch, id]);

  const contract = useSelector((state: RootState) => state.account.contracts.currentContract);
  const isLoading = useSelector((state: RootState) => state.account.contracts.isLoading);

  return { contract, isLoading };
};
