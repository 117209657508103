import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import ModalSubtitle, { SubtitleItem } from '../dialogSubtitle';
import { HedgeTypeStringMap } from 'src/hedgeAdmin/hedging/hedgeRequests/components/hedgeRequestDrawer/helpers';
import { IHedge } from '@app/types';

interface CommentModalProps {
  open: boolean;
  onClose: () => void;
  hedge: IHedge;
}

export const CommentModal: React.FC<CommentModalProps> = ({ open, onClose, hedge }) => {
  const subtitleItems = [
    { key: 'Hedge No.', value: hedge.hedge_number },
    { key: HedgeTypeStringMap[hedge.hedge_type] },
  ];

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: 755,
          width: 755,
          height: 360,
        },
      }}
    >
      <DialogTitle sx={{ paddingBottom: '8px', paddingTop: '24px', fontWeight: 700 }}>
        Comment
      </DialogTitle>
      <ModalSubtitle subtitleItems={subtitleItems} />
      <DialogContent>
        <Typography>{hedge.message}</Typography>
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button onClick={onClose} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
