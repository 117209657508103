import styles from '../terms/styles.module.scss';

export const Policy = () => {
  return (
    <div className={styles.container}>
      <h3>SCRAPEX DMCC </h3>
      <h2> Privacy policy </h2>
      <p>
        The website at{' '}
        <a href="https://scrapex.ae" target="_blank" rel="noopener noreferrer">
          scrapex.ae
        </a>{' '}
        and{' '}
        <a href="https://scrapex.app" target="_blank" rel="noopener noreferrer">
          scrapex.app
        </a>{' '}
        (our “Website”) is operated by Scrapex DMCC in accordance with the attached terms and
        conditions. Scrapex DMCC is a data controller of Personal Information (as deﬁned below)
        collected on the Website. Scrapex DMCC (“Scrapex”) is an entity established in 2023 as an
        international trader and operator of the online hedging platform. We are a respected member
        of the international scrap metal recycling market. <br />
        Our head office is at: 2906, HDS Tower, Cluster F, JLT Dubai, United Arab Emirates. You can
        contact us by email at <a href="mailto:vs@scrapex.app">vs@scrapex.app</a> in relation to any
        matters pertaining to this Privacy Policy. In this Privacy Policy, references to “we”, “us”
        or “our” means Scrapex DMCC. References to “you” and “your” are to users of our Website.{' '}
      </p>
      <h2>APPLICATION</h2>
      <p>
        This Privacy Policy describes how we collect, use and otherwise handle{' '}
        <strong>“Personal Information”</strong> that you provide or make available to us, or that we
        collect from you, when you use our Website, and explains the circumstances in which we may
        transfer this to others. <strong>“Personal Information”</strong> is information relating to
        you, which can be used to personally identify you (either directly or indirectly). Our
        Privacy Policy must be read together with any other legal notices or terms and conditions
        available on other pages of our Website.
      </p>
      <h2>COLLECTION AND USE OF PERSONAL INFORMATION </h2>
      <h5>1. Information that we collect </h5>
      <p>
        The types of information that we may collect from you, depending on how you use our Website
        include: <br />- your name; <br />- your email address; <br />- your address (business and
        personal); <br />- your landline and mobile telephone numbers (business and personal);{' '}
        <br />- any other information that you choose to provide to us when filling out a contact
        form on our Website; <br />- your geographic location (country/territory where you are
        living and/or working); <br />- your job title, role and the name of your employer; <br />-
        the industry sector in which you work; <br />- the country or countries, industry sector(s)
        and area(s) of our services in respect of which you wish to receive communications (where
        you have registered on our Website); <br />- any additional information that is required to
        ensure the interaction of users and our Website.{' '}
      </p>
      <h5>2. How we use your Personal Information</h5>
      <p>
        <strong>Contact Forms</strong> - we may collect your Personal Information, which you choose
        to provide when you ﬁll in forms on our Website, including the information set out in a to i
        above. We may use this Personal Information to respond to your queries, and/or provide the
        services and/or information that you have requested. <br />{' '}
        <strong>Our Business Purposes</strong> - we may also use your Personal Information for our
        business purposes such as: <br />- record keeping, statistical analysis, internal reporting
        and research purposes; <br />- to ensure network and information security; <br />- to notify
        you about changes to our services; <br />- to investigate any complaint you make; <br />- to
        provide evidence in any dispute or anticipated dispute between you and us; <br />- to
        customise various aspects of our Website to improve your experience; <br />- to host,
        maintain and otherwise support the operation of our Website;
        <br />- for the detection and prevention of fraud and other criminal oﬀences and management
        purposes; <br />- for business and disaster recovery (e.g. to create back-ups); <br />- for
        document retention/storage; <br />- for database management; - to protect the rights,
        property, and/or safety of Scrapex, its personnel and others; <br />- to ensure the quality
        of the services we provide to our users. <br />
        In addition, we may use your Personal Information for additional speciﬁc purposes made clear
        at the point of collection on particular pages of our Website. If you choose not to provide
        Personal Information requested by us, we may not be able to provide you with the information
        and/or services you have requested or otherwise fulﬁll the purpose(s) for which we have
        asked for the Personal Information. Aside from this, your visit to our Website will remain
        unaﬀected.
      </p>
      <h5> 3. Legal grounds for collection and use of Personal Information </h5>
      <p>
        You give consent to the processing of your personal data by accepting the attached T&C’s and
        (or) by sending any of your personal data to the email info@scrapex.ae. We rely upon your
        consent to process your personal information in relation to marketing information and events
        information we may send to you (where this is made clear to you at the point where you
        provide your information). In other cases, we process your personal information where we
        need to do so: <br />- to comply with our legal and regulatory obligations; <br />- for our
        legitimate interests in: <br />
        responding to your queries; <br />
        providing services and/or information to you;
        <br /> our internal business purposes, as set out in 2 above. <br />
        You can object to processing based on our legitimate interests at any time by contacting us
        at vs@scrapex.app. See also <strong>“Your Rights”</strong>
        section below. We consider the risk to your rights of data protection in connection with
        personal data that we process on the basis of our legitimate interests is not excessive or
        overly intrusive. We have also put in place protections for your rights by ensuring proper
        retention periods and security controls.
      </p>
      <h5>4. Cookies </h5>
      <p>
        A cookie is a small text ﬁle which is placed onto your device when you access our Website.
        We use cookies and other online tracking devices to make your online experience more eﬃcient
        and enjoyable. The information we obtain from our use of cookies will not usually contain
        your personal data. Although we may obtain information about your device such as your IP
        address, your browser and/or other internet log information, this will not, usually,
        identify you personally. In certain circumstances, we may collect personal information about
        you – but only where you voluntarily provide it (e.g. by completing an online form). In most
        cases, we will need your consent in order to use cookies. The exception is where the cookie
        is essential in order for us to provide you with a service you have requested. If you do not
        want to accept cookies, you can change your browser settings so that cookies are not
        accepted. If you do this, please be aware that you may lose some of the functionality of the
        Website. For further information about cookies and how to disable them please go to:
        <a href="www.aboutcookies.org" target="_blank" rel="noopener noreferrer">
          www.aboutcookies.org
        </a>{' '}
        or{' '}
        <a href="www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">
          www.allaboutcookies.org.
        </a>
      </p>
      <h5>5.Information from other sources </h5>
      <p>
        Where permissible under applicable local laws, we may combine information that you have
        provided to us with other information that we already hold about you and which we have
        collected for a compatible purpose.{' '}
      </p>
      <h2>SHARING INFORMATION WITH THIRD PARTIES </h2>
      <p>
        Some services that we provide require the involvement of third parties. We have carefully
        selected these third parties and taken steps to ensure that your Personal Information is
        adequately protected.
      </p>
      <h5>1. Sharing with Service Providers </h5>
      <p>
        When Scrapex DMCC would like to use your Personal Information for a new purpose, we will let
        you know about this ﬁrst. We share your Personal Information with our third party service
        providers based both inside and outside of the European Economic Area (“EEA”) and the United
        Arab Emirates (“UAE”) who we engage to provide support services in relation to our Website
        for the purposes of: hosting and maintaining our Website; providing data storage; assisting
        us with database management, and in order to assist us with related tasks or processes. All
        of our service providers are bound by written contracts to process personal data provided to
        them only for the purpose of providing the speciﬁc service to us and to maintain appropriate
        security measures to protect your Personal Information.
      </p>
      <h5> 2. Sharing with other third parties</h5>
      <p>
        {' '}
        We share your Personal Information with: <br />- our accountants, auditors, lawyers or
        similar advisers when we ask them to provide us with professional advice; <br />- any other
        third party if we are under a duty to disclose or share your Personal Information in order
        to comply with any legal obligation, or to protect the rights, property and/or safety of
        Scrapex, our personnel or others; <br />- any other third party for the purposes of acting
        in accordance with the requirements of a court, regulator or government agency, for example,
        complying with a search warrant or court order or acting in accordance with an applicable
        law or regulation; or <br />- investors and other relevant third parties in in the event of
        an actual potential sale or other corporate transaction related to Scrapex. <br />
        Our Website may include links to third-party websites, plug-ins and applications. Clicking
        on those links or enabling those connections may allow third parties to collect or share
        data about you. We do not control these third-party websites and are not responsible for
        their privacy statements. When you leave our Website, we encourage you to read the privacy
        notice of every website you visit.{' '}
      </p>
      <h2>INTERNATIONAL TRANSFERS </h2>
      <p>
        The transfer of your Personal Information to third parties may involve your Personal
        Information being sent outside of the UAE and EEA, to locations that may not provide the
        same level of protection as those where you ﬁrst provided the information. However, we will
        only transfer your Personal Information outside of the UAE and EEA: <br />- where the
        transfer is to a place or by a method or in circumstances that have an adequate level of
        protection for that personal information is ensured by applicable law; <br />- where we have
        put in place standard data protection clauses in accordance with applicable law setting out
        a procedure for developing such clauses; or <br />- where none of the above apply but we are
        still legally permitted to do so, for example if the transfer is necessary for the
        establishment, exercise or defense of legal claims.{' '}
      </p>
      <h2>STORING PERSONAL INFORMATION </h2>
      <p>
        It is our policy to retain your Personal Information for the length of time required for the
        speciﬁc purpose or purposes for which it was collected. However, we may be obliged to store
        some Personal Information for a longer time, taking into account factors including: <br />-
        legal obligation(s) under applicable law to retain data for a certain period of time; <br />
        - statute of limitations under applicable law(s); <br />- (potential) disputes; and <br />-
        guidelines issued by relevant data protection authorities. <br />
        Whilst we continue to process your Personal Information, we will ensure that it is treated
        in accordance with this Privacy Policy. Otherwise, we securely erase your information once
        this is no longer needed. If you would like to ﬁnd out how long we keep your Personal
        Information for a particular purpose you can contact us at vs@scrapex.app.{' '}
      </p>
      <h2>SECURITY</h2>{' '}
      <p>
        We employ appropriate security measures to help protect your Personal Information and guard
        against access by unauthorized persons. Information storage is on secure computers in a
        locked and certiﬁed information center and information is encrypted wherever possible. We
        undergo periodic reviews of our security policies and procedures to ensure that our systems
        are secure and protected. However, as the transmission of information via the Internet is
        not completely secure we cannot guarantee the security of your information transmitted to
        our Website.{' '}
      </p>
      <h2>CONFIDENTIALITY</h2>{' '}
      <p>
        {' '}
        We acknowledge that the information you provide may be conﬁdential. We do not sell, rent,
        distribute or otherwise make Personal Information commercially available to any third party,
        except that we may share information with our service providers for the purposes set out in
        this Privacy Policy. We will maintain the conﬁdentiality and protect your information in
        accordance with our Privacy Policy and all applicable laws.{' '}
      </p>
      <h2>YOUR RIGHTS</h2>{' '}
      <p>
        {' '}
        The following section explains your rights. The various rights are not absolute and each is
        subject to certain exceptions or qualiﬁcations. We will grant your request only to the
        extent that it follows from our assessment of your request that we are allowed and required
        to do so under data protection laws. Nothing in this Privacy Policy is intended to provide
        you with rights beyond or in addition to your rights as a data subject under data protection
        laws. You have the right to request access to Personal Information that we may process about
        you. If you wish to exercise this right, you should: <br />- put your request in writing (an
        email sent to vs@scrapex.app is acceptable); <br />- include proof of your identity and
        address (e.g. a copy of your driving licence or passport, and a recent utility or credit
        card bill); and <br />- specify the Personal Information you want access to. <br />
        You have the right to require us to correct any inaccuracies in your Personal Information
        free of charge. If you wish to exercise this right, you should: <br />- put your request in
        writing (an email sent to vs@scrapex.app is acceptable); <br />- provide us with enough
        information to identify you (for example, your name, include proof of your identity and
        address (e.g. a copy of your driving license or passport, and a recent utility or credit
        card bill); and
        <br /> - specify the information that is incorrect and what it should be replaced with.{' '}
        <br />
        You also have the right to ask us to stop processing your personal data for direct marketing
        purposes. If you wish to exercise this right, you should: <br />- put your request in
        writing (an email sent to vs@scrapex.app with a header that says 'Unsubscribe' is
        acceptable); <br />- provide us with enough information to identify you (for example, your
        name, include proof of your identity and address (e.g. a copy of your driving license or
        passport, and a recent utility or credit card bill); and <br />- if your objection is not to
        direct marketing in general, but to direct marketing by a particular channel (e.g. email or
        SMS), please specify the channel you are objecting to.{' '}
      </p>
      <h2>CHANGES TO THIS PRIVACY POLICY </h2>{' '}
      <p>
        This version was last updated on: July 2024 <br /> It is important that the personal data we
        hold about you is accurate and current. Please keep us informed if your personal data
        changes during your relationship with us.
      </p>
    </div>
  );
};
