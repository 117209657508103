import { Box } from '@mui/material';
import classes from './styles.module.scss';
import { ContractStatus } from '@app/types';

export const ContractStatusIndicator = ({ status }: { status: ContractStatus }) => {
  const contractStatusMap: { [key in ContractStatus]: string } = {
    active: 'Active',
    filled: 'Filled',
    canceled: 'Canceled',
  };

  return (
    <Box className={`${classes[status]} ${classes.status}`}>
      {contractStatusMap[status as ContractStatus]}
    </Box>
  );
};
