import { IContractUser } from '@app/types';
import { GridRowsProp } from '@mui/x-data-grid-premium';

export const contractsRowData = (contracts: IContractUser[]) => {
  const rows: GridRowsProp = contracts.map((contract) => ({
    ...contract,
    id: contract.id,
  }));

  return { rows };
};
