import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { ReactNode } from 'react';

interface IBreadcrumb {
  name: string | ReactNode;
  linkTo?: string;
}

interface IScrapexBreadcrumbsProps {
  breadcrumbs: IBreadcrumb[];
}

const breadcrumbStyle: SxProps = {
  fontSize: '14px',
  textDecoration: 'none',
  color: 'var(--other-500)',
};

const currentBreadcrumbStyle = {
  ...breadcrumbStyle,
  color: 'var(--other-900)',
};

export const ScrapexBreadcrumbs = ({ breadcrumbs }: IScrapexBreadcrumbsProps) => {
  const content = breadcrumbs.map((item) => (
    <Link href={item.linkTo} key={item.name as string} sx={{ textDecoration: 'none' }}>
      <Typography sx={item.linkTo ? breadcrumbStyle : currentBreadcrumbStyle}>
        {item.name}
      </Typography>
    </Link>
  ));

  return (
    <Box sx={{ width: '100%', maxWidth: '1120px' }}>
      <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
        {content}
      </Breadcrumbs>
    </Box>
  );
};
