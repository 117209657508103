import { IHedge } from '@app/types';
import Box from '@mui/material/Box';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { generateHedgeDetailColumns } from './data/hedgeDetailColumns';
import { generateHedgeDetailRows } from './data/hedgeDetailRows';

interface DetailPanelContentProps {
  row: IHedge;
  onHandleUpdateLFD?: (shipmentId: string, value: string, field: string) => void;
  isItHistory?: boolean;
}
export function DetailPanelContent({
  row: rowProp,
  onHandleUpdateLFD,
  isItHistory = false,
}: DetailPanelContentProps) {
  const { columns } = generateHedgeDetailColumns(onHandleUpdateLFD, isItHistory);
  const { rows } = generateHedgeDetailRows(rowProp);
  return (
    <Box sx={{ background: '#F9FAFB' }}>
      <DataGridPremium
        sx={{
          border: 'none',
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },

          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-columnHeaders': {
            color: '#8592A8',
            background: '#F9FAFB',
            border: 'none',
          },
        }}
        columns={columns}
        rows={rows}
        columnHeaderHeight={40}
        hideFooter
        autoHeight
        disableColumnFilter
        disableColumnMenu
      />
    </Box>
  );
}
