import { AppDispatch, RootState } from '@app/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getCompaniesThunk } from 'src/accountManager/reducer/companies/thunks';
import { companiesRowData, generateCompaniesColumns } from '../data';

export const useGetCompanies = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getCompaniesThunk());
  }, []);

  const { companies, isLoading } = useSelector(
    (state: RootState) => state.accountManager.companies,
  );
  const { columns } = generateCompaniesColumns();
  const { rows } = companiesRowData(companies);

  return { companies, isLoading, columns, rows };
};
