import { IUnhedgedShipment } from '@app/types';
import { GridRowsProp } from '@mui/x-data-grid-premium';

export const generateUnhedgedShipmentRows = (shipments: IUnhedgedShipment[]) => {
  const rows: GridRowsProp = shipments?.map((shipment) => ({
    ...shipment.shipment,
    contract_number: shipment?.deal?.seller_contract_number,
    metal: shipment?.deal?.metal,
    product: shipment?.deal?.product,
    formula: shipment?.deal?.formula,
    seller_hedge: shipment?.seller_hedge,
    seller_pure_metal: shipment?.seller_hedge?.pure_metal,
    seller_fixed_exchange: shipment?.seller_hedge?.fixed_exchange,
    seller_request_time: shipment?.seller_hedge?.request_time,
    seller_quotation: shipment?.seller_hedge?.quotation,
    seller_olme_date: shipment?.seller_hedge?.olme_date,
    seller_tp_exchange_level: shipment?.seller_hedge?.tp_exchange_level,
    buyer_hedge: shipment?.buyer_hedge,
    buyer_pure_metal: shipment?.buyer_hedge?.pure_metal,
    buyer_fixed_exchange: shipment?.buyer_hedge?.fixed_exchange,
    buyer_request_time: shipment?.buyer_hedge?.request_time,
    buyer_quotation: shipment?.buyer_hedge?.quotation,
    buyer_olme_date: shipment?.buyer_hedge?.olme_date,
    buyer_tp_exchange_level: shipment?.buyer_hedge?.tp_exchange_level,
    seller_id: shipment?.deal?.seller.id,
    buyer_id: shipment?.deal?.buyer.id,
    id: shipment.shipment.id,
  }));

  return { rows };
};
