import httpClient from '../../client/httpClient';
import { IContractAdmin, IContractUser, ICreateContract } from '@app/types';
const API_ADMIN_CONTRACTS = '/api/admin/deals';
const API_USER_CONTRACTS = '/api/user/deals';

export const getContracts = () => {
  return httpClient.get<IContractAdmin[]>(API_ADMIN_CONTRACTS);
};

export const createContract = (contractData: ICreateContract) => {
  return httpClient.post(API_ADMIN_CONTRACTS, contractData);
};

export const getContract = (id: string) => {
  return httpClient.get<IContractAdmin>(`${API_ADMIN_CONTRACTS}/${id}`);
};

export const updateContract = (id: string, contractData: ICreateContract) => {
  const API_UPDATE_CONTRACT = `/api/admin/deals/test/${id}`;
  return httpClient.put<IContractAdmin>(`${API_UPDATE_CONTRACT}`, contractData);
};

export const getUserContracts = () => {
  return httpClient.get<IContractUser[]>(API_USER_CONTRACTS);
};

export const getUserContract = (id: string) => {
  return httpClient.get<IContractUser>(`${API_USER_CONTRACTS}/${id}`);
};

export const deleteContract = (id: string) => {
  return httpClient.delete(`${API_ADMIN_CONTRACTS}/${id}`);
};
