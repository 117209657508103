import { useState, useEffect } from 'react';

interface CountdownTimerProps {
  deadline: string;
  onTimerExpired: () => void;
}
export const CountdownTimer = ({ deadline, onTimerExpired }: CountdownTimerProps) => {
  const calculateRemainingTime = () => {
    const now = new Date();
    const endTime = new Date(deadline);
    const difference = endTime.getTime() - now.getTime();

    if (difference <= 1000) {
      return '0m 0s';
    }

    const seconds = Math.floor((difference / 1000) % 60);
    const minutes = Math.floor((difference / 1000 / 60) % 60);

    return `${minutes}m ${seconds}s`;
  };

  const [remainingTime, setRemainingTime] = useState<string>(calculateRemainingTime());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newRemainingTime = calculateRemainingTime();
      setRemainingTime(newRemainingTime);
      if (newRemainingTime === '0m 0s') {
        onTimerExpired();
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [deadline]);

  return <span>{remainingTime}</span>;
};
