export enum HedgeType {
  ACM = 'acm',
  TakeProfit = 'tp',
  OLME = 'olme',
}

export const hedgeTypeMap: Record<string, string> = {
  [HedgeType.ACM]: 'At the current market',
  [HedgeType.TakeProfit]: 'Take Profit',
  [HedgeType.OLME]: 'Official LME',
};
