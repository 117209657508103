import { useState } from 'react';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import debounce from 'lodash/debounce';

import classes from './styles.module.scss';

const cards = [
  {
    title: 'Hedging',
    content:
      'We have more than 6 years of experience helping companies hedge their deals and protect their business. Working with LME and Ring Brokers.',
    bgColor: '#0000ff',
    textColor: '#fff',
  },
  {
    title: 'Software Development',
    content:
      'More than 20 years of software development experience for Fintech, Energy, Logistic, technology and eCommerce sector.',
    bgColor: '#d3d3d3',
    textColor: '#000',
  },
  {
    title: 'International Metal Trading',
    content:
      'Our team has more than 14 Years of successful International metal trading Experience across the Globe.',
    bgColor: '#000',
    textColor: '#fff',
  },
  {
    title: 'Financing',
    content:
      'We succeed in cooperation with international financial institutions, having deep expertise in discount letter of credit with factors deals',
    bgColor: '#7dc8f7',
    textColor: '#000',
  },
];

export const Experience = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const stepWidth = 345;
  const totalCards = cards.length;

  const visibleCards = 3;

  const extendedCards = [...cards, ...cards.slice(0, visibleCards)];

  const slideRight = debounce(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
  }, 300);

  const slideLeft = debounce(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalCards) % totalCards);
  }, 300);

  return (
    <section className={classes.experience} id="experience">
      <main>
        <div className={classes.title}>
          <h2>Our Experience</h2>
        </div>
        <div className={classes.slider}>
          <div className={classes.control}>
            <button type="button" onClick={slideLeft}>
              <KeyboardArrowLeft />
            </button>
            <button type="button" onClick={slideRight}>
              <KeyboardArrowRight />
            </button>
          </div>
          <div className={classes.container}>
            <div
              className={classes.wrapper}
              style={{
                transform: `translateX(-${stepWidth * currentIndex}px)`,
                transition: 'transform 0.5s ease-in-out',
              }}
            >
              {extendedCards.map((card, index) => (
                <figure
                  key={index}
                  className={classes.item}
                  style={{
                    backgroundColor: card.bgColor,
                  }}
                >
                  <div className={classes.textWrapper}>
                    <div className={classes.cardTitle}>
                      <h3 style={{ color: card.textColor }}>{card.title}</h3>
                    </div>
                    <p style={{ color: card.textColor }}>{card.content}</p>
                  </div>
                </figure>
              ))}
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};
