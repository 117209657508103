import ConfirmationDialog from '@common/components/dialog/confirmationDialog';
import { ContractStatus } from '@app/types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@app/store';
import {
  deleteContractThunk,
  getContractsThunk,
} from 'src/accountManager/reducer/contracts/thunks';
import { ContractStatusIndicator } from '@common/components';

interface DeleteContractModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  contractNumber: string;
  contractStatus: ContractStatus;
  contractId: string;
}

export const DeleteContractModal: React.FC<DeleteContractModalProps> = ({
  open,
  onClose,
  contractNumber,
  contractStatus,
  contractId,
}) => {
  const title = 'Remove Contract';
  const subtitleItems = [
    { key: 'Contract No.', value: contractNumber },
    { key: 'Contract status:', value: <ContractStatusIndicator status={contractStatus} /> },
  ];
  const content = 'Do you really want to delete the contract? This action is irreversible.';

  const dispatch = useDispatch<AppDispatch>();

  const handleConfirm = async () => {
    await dispatch(deleteContractThunk(contractId));
    dispatch(getContractsThunk());
    onClose();
  };

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title={title}
      subtitleItems={subtitleItems}
      content={content}
      onConfirm={handleConfirm}
    />
  );
};
