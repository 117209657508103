import { Box, Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { CreateContractDrawer } from 'src/accountManager/contracts/components/createContractDrawer';
import { AddCompanyDrawer } from 'src/accountManager/companies/components/addCompanyDrawer';
import { DrawerType } from '@app/types/common';
import { DrawerGeneral, ScrapexTitleH3 } from '@common/components';
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';

interface TableHeaderProps {
  title: string;
  buttonText?: string;
  drawerTitle?: string;
  drawerType?: DrawerType;
  showButton?: boolean;
  withExport?: boolean;
  exportTrigger?: React.ReactNode;
}

export const TableHeader: React.FC<TableHeaderProps> = ({
  title,
  buttonText,
  drawerTitle,
  drawerType,
  showButton = false,
  withExport = false,
  exportTrigger = <GridToolbarExport />,
}) => {
  const [open, setOpen] = useState(false);

  const openDrawerHandler = () => {
    setOpen(true);
  };

  const closeDrawerHandler = () => {
    setOpen(false);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '64px',
        p: '20px',
      }}
    >
      <ScrapexTitleH3>{title}</ScrapexTitleH3>
      <Stack direction="row" gap="12px">
        <GridToolbarContainer>
          <Box sx={{ flexGrow: 1, color: 'yellow' }}>
            <GridToolbarQuickFilter
              InputProps={{
                disableUnderline: true,
                sx: {
                  borderRadius: '10px',
                  background: '#F9FAFB',
                  width: '400px',
                  height: '34px',
                  padding: '9px 8px',
                },
              }}
            />
          </Box>
        </GridToolbarContainer>
        <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 2 }}>
          {showButton && (
            <>
              <Button variant="contained" sx={{ fontSize: '12px' }} onClick={openDrawerHandler}>
                {buttonText}
              </Button>
            </>
          )}
          {withExport && (
            <Button variant="outlined" sx={{ py: 0 }}>
              {exportTrigger}
            </Button>
          )}
        </Box>
      </Stack>
      <DrawerGeneral title="" open={open} onClose={closeDrawerHandler}>
        <DrawerGeneral title={drawerTitle} open={open} onClose={closeDrawerHandler}>
          {drawerType === 'company' ? (
            <AddCompanyDrawer onClose={closeDrawerHandler} />
          ) : (
            <CreateContractDrawer onClose={closeDrawerHandler} />
          )}
        </DrawerGeneral>
      </DrawerGeneral>
    </Box>
  );
};
