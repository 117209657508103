import React, { useState } from 'react';
import { Box, Divider, Grid, ListItem, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { PdfFileSelector } from '../pdfFileSelector';
import { DoneAllOutlined } from '@mui/icons-material';

const connaissementTypeItems = [
  { value: 'bl', label: 'BL' },
  { value: 'telex', label: 'Telex' },
  { value: 'swb', label: 'SWB' },
];

interface ConnaissementFieldProps {
  shipmentId: string;
  contractId?: string;
  initialConnaissementType?: string;
  connaissement: string;
  onHandleUpdateShipment: (value: string, field: string) => void;
}

export const ConnaissementField: React.FC<ConnaissementFieldProps> = ({
  shipmentId,
  contractId,
  initialConnaissementType = 'bl',
  connaissement,
  onHandleUpdateShipment,
}) => {
  const [connaissementType, setConnaissementType] = useState(initialConnaissementType);

  const handleConnaissementTypeChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setConnaissementType(newValue);
    onHandleUpdateShipment(newValue, 'connaissement_type');
  };

  return (
    <>
      <ListItem sx={{ padding: '16px 0' }}>
        <Grid container alignItems="center" sx={{ textAlign: 'left' }}>
          <Grid item xs={6}>
            <Box>
              <Select
                value={connaissementType}
                onChange={(event) => {
                  handleConnaissementTypeChange(event);
                }}
                renderValue={(selected) => {
                  return connaissementTypeItems.find((item) => item.value === selected)!.label;
                }}
                variant="standard"
                sx={{
                  boxShadow: 'none',
                  color: 'var(--other-500)',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  '&.MuiInputBase-root': {
                    '& .MuiInput-input:focus': {
                      backgroundColor: 'transparent',
                    },
                  },
                  minWidth: 'auto',
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      width: '230px',
                    },
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                }}
                disableUnderline
              >
                {connaissementTypeItems.map((type) => (
                  <MenuItem
                    key={type.value}
                    value={type.value}
                    selected={type.value === connaissementType}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {type.label}
                    {type.value === connaissementType && (
                      <DoneAllOutlined color="success" style={{ marginLeft: '8px' }} />
                    )}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'end' }}>
            <PdfFileSelector
              onPdfIdSelect={(pdfId) => {
                onHandleUpdateShipment(pdfId, 'connaissement');
              }}
              dealId={contractId}
              folder={`${shipmentId}-docs`}
              fileIdProp={connaissement}
            />
          </Grid>
        </Grid>
      </ListItem>
      <Divider sx={{ borderColor: 'var(--light-white-color)' }} />
    </>
  );
};
