import { AppDispatch } from '@app/store';
import { ILogInForm } from '@app/types/auth';
import '@assets/fonts/codec-pro.css';
import styles from './style.module.scss';

import { InputControlled, PassowrdInputControlled } from '@common/components';
import { Box, Button, FormControl } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { fetchAuthWithEmail } from 'src/auth/reducer/login/thunks';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  offerAgreement: yup.bool().required(),
});

const initialValues: ILogInForm = {
  email: '',
  password: '',
  offerAgreement: false,
};

export const LoginForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const onSubmit = (values: ILogInForm) => {
    const data = {
      email: values.email,
      password: values.password,
    };
    dispatch(fetchAuthWithEmail({ data, navigate, setErrors, setSubmitting }));
  };

  const {
    handleSubmit,
    handleChange,
    values,
    touched,
    errors,
    isSubmitting,
    setErrors,
    setSubmitting,
  } = useFormik({
    validationSchema,
    initialValues,
    onSubmit,
  });

  return (
    <Box sx={{ width: '590px' }} className={styles.codecFont}>
      <h1>Log in</h1>
      <form onSubmit={handleSubmit} id="login">
        <Box sx={{ mt: '48px', display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <InputControlled
            error={errors.email}
            touched={touched.email}
            fullWidth
            name="email"
            type="email"
            label="Email"
            value={values.email}
            onChange={handleChange}
          />
          <PassowrdInputControlled
            fullWidth
            name="password"
            label="Password"
            onClickForgotPassword={() => {}}
            error={errors.password}
            touched={touched.password}
            value={values.password}
            onChange={handleChange}
          />
          <FormControl
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              mt: '56px',
              cursor: 'pointer',
            }}
          >
            <input
              checked={values.offerAgreement}
              onChange={handleChange}
              id="offerAgreement"
              type="checkbox"
              name="offerAgreement"
            />
            <label htmlFor="offerAgreement" style={{ cursor: 'pointer' }}>
              I agree with the{' '}
              <Link to="/terms" target="_blank">
                Terms & Policies.
              </Link>
            </label>
          </FormControl>
        </Box>
      </form>

      <Button
        type="submit"
        form="login"
        sx={{ py: '22px', fontSize: '14px', mt: '16px' }}
        fullWidth
        variant="contained"
        color="primary"
        disabled={isSubmitting || !values.offerAgreement}
      >
        SIGN IN
      </Button>
    </Box>
  );
};
