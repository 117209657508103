import httpClient from '@app/client/httpClient';
import { IGetsFileInfo } from '@app/types/api';
import { getToken } from '@app/utils';
import { hostname } from '@app/utils/hostname';
import { noFileId } from '@app/utils/isFileExists';
import { useEffect, useState } from 'react';

export const useGetFileName = (dealId: string, filePath: string, role: string = 'user') => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const link =
    filePath && filePath !== noFileId ? `https://${hostname}/api/upload/${filePath}` : null;

  useEffect(() => {
    const getFileName = async () => {
      try {
        setLoading(true);
        const token = await getToken(role);
        const res = await httpClient.get<IGetsFileInfo>(
          `api/upload/${role}/deals/${dealId}/files/${filePath}/info?auth_token=${token}`,
        );
        setFileName(res.data.filename);
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
        throw err;
      } finally {
        setLoading(false);
      }
    };

    if (filePath) {
      getFileName();
    }
  }, [filePath]);

  return { loading, error, fileName, link };
};
