import { AppDispatch, RootState } from '@app/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getShipmentsThunk } from 'src/account/reducer/shipments/thunks';
import { actions, actions as shipmentActions } from 'src/account/reducer/shipments/slice';
import { actions as hedgingActions } from 'src/account/reducer/hedging/slice';

export const useGetShipments = (contractId: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(shipmentActions.reset());
      dispatch(hedgingActions.reset());
      try {
        if (contractId) {
          await dispatch(getShipmentsThunk(contractId));
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error(String(err)));
      }
    };

    fetchData();

    return () => {
      dispatch(actions.reset());
    };
  }, [contractId]);

  const shipments = useSelector((state: RootState) => state.account.shipments.shipments);
  const isLoading = useSelector((state: RootState) => state.account.shipments.isLoading);

  return { shipments, isLoading, error };
};
