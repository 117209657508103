import { NavigateFunction } from 'react-router';

export namespace Auth {
  interface IAuth {
    type: 'string';
  }
}

export const enum SignUpNavigation {
  PERSONAL_INFORMATION,
  SIGN_UP,
  EMAIL_CODE,
  PHONE_NUMBER,
  SMS_CODE,
  // LOADER,
  READY,
}

export enum SwitchInputEnum {
  LOGIN = 'LOGIN',
  PHONE_KEY = 'PHONE_KEY',
  PASSWORD = 'PASSWORD',
}

export interface ISignUpForm {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

export interface ISignupState {
  form: ISignUpForm;
  phone: string;
  stateView: SignUpNavigation;
  isPassword: boolean;
}

export interface IInitiateSignUpResponse {
  email_code: string;
  session_id: string;
}

export interface ISignUpConfirmEmailForm {
  email_code: string;
}

export interface ISignUpPhoneForm {
  phone: string;
}

export interface ISignUpConfirmPhoneForm {
  sms_code: string;
}

// LogIn Interfaces

export interface ILogInEmailOrPhoneFormikInitialValues {
  login_data: string;
}

export interface ILogInPasswordFormikInitialValues {
  password: string;
}

export interface ILogInSliceState {
  login_data: string;
  isPhoneInput: boolean;
  isLoginInitialized: boolean;
}

export interface IFetchAuthParams<T> {
  data: T;
  navigate: NavigateFunction;
  setErrors: (values: any) => void;
  setSubmitting: (values: any) => void;
}

export interface ILogInForm {
  password: string;
  email: string;
  offerAgreement: boolean;
}
