import { IHedge } from '@app/types';
import { GridRowsProp } from '@mui/x-data-grid-premium';

export const generateHedgeDetailRows = (row: IHedge) => {
  const { hedge_shipments } = row;

  const rows: GridRowsProp = hedge_shipments.map((shipment) => ({
    ...shipment,
  }));

  return { rows };
};
