import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface DrawerFooterProps {
  justifyContent?: string;
  children: ReactNode | ReactNode[];
}

export const DrawerFooter = ({ justifyContent = 'flex-end', children }: DrawerFooterProps) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        padding: '27px 40px',
        borderTop: '1px solid var(--light-white-color)',
        display: 'flex',
        justifyContent,
        width: 'inherit',
        backgroundColor: 'var(--white-color)',
      }}
    >
      {children}
    </Box>
  );
};
