import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
} from '@mui/material';

export interface IMenuItem {
  value: any;
  label: string;
}
interface IOutlinedSelectProps {
  menuList: IMenuItem[];
  id: string;
  label: string;
  name: string;
}

export const OutlinedSelect = (props: IOutlinedSelectProps & SelectProps) => {
  const { value, menuList, id, label, name, ...currentProps } = props;
  return (
    <FormControl fullWidth size="small">
      <InputLabel color="secondary" id={label}>
        {label}
      </InputLabel>
      <Select
        labelId={label}
        id={id}
        value={value}
        input={<OutlinedInput label={label} />}
        name={name}
        onChange={currentProps.onChange}
        color="secondary"
        inputProps={{ 'aria-label': 'Without label' }}
        {...currentProps}
      >
        {menuList.map((menuItem) => (
          <MenuItem key={menuItem.value} value={menuItem.value}>
            {menuItem.label}
          </MenuItem>
        ))}
      </Select>
      {/* <FormHelperText color="secondary"> </FormHelperText> */}
    </FormControl>
  );
};
