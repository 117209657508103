import { useNavigate } from 'react-router-dom';
import classes from './styles.module.scss';
import { ReactNode } from 'react';
import { Box } from '@mui/material';

export interface ISidebarNavigationItem {
  title: string;
  link: string;
  isActive?: boolean;
  icon: ReactNode;
  highlight?: boolean;
}

export const SidebarNavigationItem = ({
  title,
  link,
  isActive,
  icon,
  highlight = false,
}: ISidebarNavigationItem) => {
  const navigate = useNavigate();

  return (
    <li
      className={`${classes.item} ${isActive ? classes.activeItem : ''} ${
        highlight ? classes.highlightItem : ''
      }`}
    >
      <button onClick={() => navigate('/' + link)} type="button">
        <Box mr={4}>{icon}</Box>
        {title}
      </button>
    </li>
  );
};
