import { FilterAlt } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import {
  GridColDef,
  GridFilterInputValueProps,
  GridFilterOperator,
  useGridApiContext,
  useGridRootProps,
} from '@mui/x-data-grid-premium';
import { useEffect, useRef, useState } from 'react';

interface QuotationHeaderProps {
  colDef: GridColDef;
  field: string;
}

export const filterPanelProps = {
  disableRemoveAllButton: true,
  disableAddFilterButton: true,
  sx: {
    '& .MuiDataGrid-filterFormColumnInput, & .MuiDataGrid-filterFormOperatorInput': {
      display: 'none',
    },
  },
};

export const CurrentLMEHeader = (props: QuotationHeaderProps) => {
  const apiRef = useGridApiContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    apiRef.current.showFilterPanel(props.field);
  };

  return (
    <Box display="flex" alignItems="center">
      <span>{props.colDef.headerName}</span>
      <IconButton onClick={handleClick} size="small">
        <FilterAlt fontSize="small" />
      </IconButton>
    </Box>
  );
};

export const SelectFilterType = (props: GridFilterInputValueProps) => {
  const rootProps = useGridRootProps();
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = useRef<any>();
  const [filterValueState, setFilterValueState] = useState<string>(item.value ?? '');

  useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (type: string) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState(type);

    filterTimeout.current = setTimeout(() => {
      applyValue({ ...item, value: type });
    }, rootProps.filterDebounceMs);
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    const type = event.target.value;
    updateFilterValue(type);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 48,
        pl: '20px',
        gap: '10px',
      }}
    >
      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
        <InputLabel>Type</InputLabel>
        <Select value={filterValueState} onChange={handleFilterChange} label="Type">
          <MenuItem value="value">Value</MenuItem>
          <MenuItem value="date">Date</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export const operator: GridFilterOperator<any, number> = {
  label: 'date/value',
  value: 'dateOrValue',

  getApplyFilterFn: (filterItem) => {
    if (!filterItem.value) {
      return null;
    }
    return (value) => {
      if (!value) {
        return false;
      }
      if (filterItem.value === 'date') {
        return typeof value.value === 'string';
      }
      if (filterItem.value === 'value') {
        return typeof value.value === 'number';
      }
      return false;
    };
  },
  InputComponent: SelectFilterType,
};
