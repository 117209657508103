import { IHedge } from '@app/types';

export const getUserRole = (contractNumber: string) => {
  if (contractNumber.endsWith('B')) {
    return 'buyer';
  } else {
    return 'seller';
  }
};

export const generateBrokerMessage = (hedge: IHedge) => {
  const action = hedge.pure_metal > 0 ? 'buy' : 'sell';
  return `Please, ${action} ${hedge.pure_metal} of ${hedge.metal} at the current market`;
};
