import classes from './styles.module.scss';

export const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className={classes.footer} id="contact">
      <p>© 2022 - 2024 SCRAPEX DMCC</p>
      <nav className={`${classes.navigation}`}>
        <ul>
          <li>
            <a href="#why">Why ScrapEX</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#experience">Our Experience</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#faq">FAQ</a>
          </li>
          <li>
            <a href="#contact">Lets Talk</a>
          </li>
        </ul>
      </nav>
      <button className={classes.backToTop} onClick={scrollToTop}>
        Back to top ↑
      </button>
    </footer>
  );
};
