import classes from './styles.module.scss';

import { list, clock, puzzle, time, bell, database } from './assets';

const benefits = [
  {
    title: 'Real-Time LME Hedging',
    description: 'Real-time LME price request and fixation',
    icon: clock,
  },
  {
    title: '24/7 Digital Platform',
    description:
      '24/7 Digital Platform to manage all your contracts, shipments, and hedging including price fixation and order placement',
    icon: time,
  },
  {
    title: 'Optimized Hedging Costs',
    description: 'Hedging Lot is 1MT compared to standard 25MT for Copper',
    icon: database,
  },
  {
    title: 'Best Fit',
    description:
      'ScrapEX is designed to be the best fit for small, mid-size scrap suppliers and scrap consumers across the Globe including countries with domestic restrictions for international brokers',
    icon: puzzle,
  },
  {
    title: 'Smart Notifications',
    description:
      'Smart Notifications to keep your team up to date about your shipments and hedging',
    icon: bell,
  },
  {
    title: 'Accounting',
    description:
      "Hedging transactions aren't in your balance sheet, it's in the ScrapEX balance sheet",
    icon: list,
  },
];

export const Benefits = () => {
  return (
    <section className={classes.coreBenefits} id="benefits">
      <h2 className={classes.title}>Core Benefits</h2>
      <div className={classes.grid}>
        {benefits.map((benefit, index) => (
          <div className={classes.benefitCard} key={index}>
            <div className={classes.icon}>
              <img src={benefit.icon} />
            </div>
            <h3 className={classes.benefitTitle}>{benefit.title}</h3>
            <p className={classes.benefitDescription}>{benefit.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};
