import React, { useEffect, useState } from 'react';
import { ButtonGroup, IconButton } from '@mui/material';
import { RestartAlt, DeleteOutlineOutlined } from '@mui/icons-material';
import { useGetFileNameAndLink } from '@app/hooks';

interface FileRowProps {
  fileId?: string;
  onReuploadClick?: () => void;
  onClearClick?: () => void;
}

export const FileRow: React.FC<FileRowProps> = ({ fileId, onReuploadClick, onClearClick }) => {
  const [fileName, setFileName] = useState('');
  const { link, getFileName, loading, error } = useGetFileNameAndLink(fileId);

  useEffect(() => {
    if (fileId) {
      getFileName().then((name) => setFileName(name));
    }
  }, [fileId]);

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}
      {!loading && !error && link && (
        <>
          <a href={link} target="_blank">
            {fileName}
          </a>
          <ButtonGroup>
            <IconButton onClick={onReuploadClick}>
              <RestartAlt fontSize="small" />
            </IconButton>
            <IconButton onClick={onClearClick}>
              <DeleteOutlineOutlined color="error" fontSize="small" />
            </IconButton>
          </ButtonGroup>
        </>
      )}
    </>
  );
};
