import { AppDispatch, RootState } from '@app/store';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'src/accountManager/reducer/shipments/slice';
import { getShipmentThunk } from 'src/accountManager/reducer/shipments/thunks';

export const useGetCurrentShipment = (shipmentId?: string) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (shipmentId) dispatch(getShipmentThunk({ shipmentId }));

    return () => {
      dispatch(actions.resetCurrentShipment());
    };
  }, [dispatch, shipmentId]);

  const shipment = useSelector(
    (state: RootState) => state.accountManager.shipments.currentShipment,
  );
  const isShipmentLoading = useSelector(
    (state: RootState) => state.accountManager.shipments.isShipmentLoading,
  );

  return { shipment, isShipmentLoading };
};
