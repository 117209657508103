import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IListOfAuditCompaniesResponse } from '../../../app/types/api/audit';

interface IRequestsState {
  companies: IListOfAuditCompaniesResponse;
}

const initialState: IRequestsState = {
  companies: [],
};

const requestsSlice = createSlice({
  name: 'requestsSlice',
  initialState,
  reducers: {
    setCompanies(state, { payload }: PayloadAction<IListOfAuditCompaniesResponse>) {
      if (payload) {
        state.companies = payload;
      }
    },
    reset() {
      return initialState;
    },
  },
});

export const { actions, reducer } = requestsSlice;
export default requestsSlice.reducer;
