import { getUserShipmentById, getUserShipments } from '@api/shipments';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContractThunk } from '../contracts/thunks';

export const getShipmentsThunk = createAsyncThunk(
  'userShipments/getShipments',
  async (contractId: string) => {
    try {
      const res = await getUserShipments(contractId);
      return { shipments: res.data };
    } catch (error) {
      console.log(error);
    }
  },
);

export const getShipmentThunk = createAsyncThunk(
  'userShipments/getShipment',
  async ({ shipmentId }: { shipmentId: string }) => {
    try {
      const response = await getUserShipmentById(shipmentId);
      return response.data;
    } catch (error) {
      console.error(`Error fetching shipment with ID ${shipmentId}:`, error);
      throw error;
    }
  },
);

export const updateShipmentsThunk = createAsyncThunk(
  'userShipments/updateShipments',
  async (shipmentId: string) => {
    try {
      const res = await getUserShipmentById(shipmentId);
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateShipmentsWithHedgeThunk = createAsyncThunk(
  'userShipments/updateShipmentsWithHedge',
  async (shipmentId: string, thunkAPI) => {
    try {
      const res = await getUserShipmentById(shipmentId);
      thunkAPI.dispatch(getContractThunk({ id: res.data.deal_id }));
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);
