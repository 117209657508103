import { AppDispatch, RootState } from '@app/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { actions } from 'src/accountManager/reducer/shipments/slice';
import { getShipmentsThunk } from 'src/accountManager/reducer/shipments/thunks';

export const useGetShipments = (id?: string) => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (id) {
      dispatch(getShipmentsThunk(id));
    }

    return () => {
      dispatch(actions.reset());
    };
  }, [id]);

  const shipments = useSelector((state: RootState) => state.accountManager.shipments.shipments);
  const isLoading = useSelector((state: RootState) => state.accountManager.shipments.isLoading);

  return { shipments, isLoading };
};
