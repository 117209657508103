import httpClient from '@app/client/httpClient';
import { IGetsFileInfo } from '@app/types/api';
import { hostname } from '@app/utils/hostname';
import { useEffect, useMemo, useState } from 'react';

export const useGetShipmentFiles = (dealId: string, filePath: string, shipmentId?: string) => {
  const [loading, setLoading] = useState(false);
  //TODO: set link from getFileInfo func after backend fixes

  const [error, setError] = useState<Error | null>(null);

  const link = `https://${hostname}/api/admin/deals/${dealId}/files/${filePath}`;

  const getFileName = async () => {
    try {
      setLoading(true);
      const res = await httpClient.get<IGetsFileInfo>(
        `api/admin/deals/${dealId}/files/${filePath}/info`,
      );
      // const link = `https://${hostname}/api/admin/${res.data.path}`;
      // setLink(link);
      return res.data.filename;
    } catch (err) {
      setError(err instanceof Error ? err : new Error(String(err)));
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, link, getFileName };
};
