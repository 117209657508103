import { DrawerType } from '@app/types/common';

import { useGetCompanies } from './table/hooks/useGetCompanies';
import { TableContainer } from '@mui/material';
import { ScrapexColorEnum } from '@common/colors';
import { DataGridPremium, GridRowParams } from '@mui/x-data-grid-premium';
import { useOpenCompanyRowDrawer } from './table/companyTableRow/hooks/useOpenCompanyInfoDrawer';
import { DrawerGeneral, LoadingSkeleton } from '@common/components';
import { CompanyInfoDrawer } from '../companyInfoDrawer';
import { EditCompanyDrawer } from '../editCompanyDrawer';
import { useState } from 'react';
import { ICompany } from '@app/types';
import { TableHeader } from 'src/accountManager/common';

const companiesHeaderConfig = {
  title: 'Companies',
  buttonText: 'Add new company',
  drawerTitle: 'Add new company',
  drawerType: DrawerType.Company,
  showButton: true,
};

export default function CompaniesTable() {
  const { columns, rows, isLoading } = useGetCompanies();
  const { info, edit, closeAllHandler, openDrawerHandler, openEditHandler, closeEditHandler } =
    useOpenCompanyRowDrawer();

  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);

  const handleRowClick = (params: GridRowParams) => {
    setSelectedCompany(params.row);
    openDrawerHandler();
  };

  return (
    <>
      {isLoading && <LoadingSkeleton />}
      <TableContainer sx={{ background: ScrapexColorEnum.WHITE_COLOR, maxHeight: '100vh' }}>
        <div style={{ height: '85vh' }}>
          <DataGridPremium
            sx={{
              border: 'none',
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                outline: 'none',
              },

              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-columnHeaders': {
                color: '#8592A8',
                background: '#F9FAFB',
                border: 'none',
              },
            }}
            columnHeaderHeight={40}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            sortingOrder={['desc', 'asc']}
            slots={{ toolbar: TableHeader }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                ...companiesHeaderConfig,
              },
            }}
            autosizeOptions={{
              columns: [
                'company_status',
                'name',
                'email',
                'invitation_date',
                'registration_date',
                'number_of_contracts',
              ],
              includeOutliers: true,
              includeHeaders: true,
            }}
            rows={rows}
            columns={columns}
            // disableColumnFilter
            hideFooter
            onRowClick={handleRowClick}
            disableColumnMenu
            // disableRowSelectionOnClick
          />
        </div>
        <DrawerGeneral
          onClose={edit.isOpen ? closeEditHandler : closeAllHandler}
          isBackButton={edit.isOpen}
          title="Company Info"
          open={info.isOpen}
        >
          {!edit.isOpen && selectedCompany && (
            <CompanyInfoDrawer
              companyId={selectedCompany.id}
              onOpenEdit={openEditHandler}
              onClose={edit.isOpen ? closeEditHandler : closeAllHandler}
            />
          )}
          {edit.isOpen && selectedCompany && (
            <EditCompanyDrawer companyId={selectedCompany.id} onClose={closeAllHandler} />
          )}
        </DrawerGeneral>
      </TableContainer>
    </>
  );
}
