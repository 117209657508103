import { createSlice } from '@reduxjs/toolkit';
import { createCompanyThunk, getCompaniesThunk, getCompanyByIdThunk } from './thunks';
import { IGetCompanyByIdResponse, ICreateCompanyResponse, ICompany } from '@app/types';

interface ICompaniesState {
  companies: ICompany[];
  currentCompany: IGetCompanyByIdResponse | null;
  isLoading: boolean;
  isCompanyLoading: boolean;
}

const initialState: ICompaniesState = {
  companies: [],
  currentCompany: null,
  isLoading: false,
  isCompanyLoading: false,
};

const companies = createSlice({
  name: 'companiesSlice',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    resetCurrentCompany(state) {
      state.currentCompany = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompaniesThunk.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.companies = payload;
        }
      })
      .addCase(getCompaniesThunk.pending, (state) => {
        state.isLoading = true;
      }),
      builder.addCase(createCompanyThunk.fulfilled, (state, action) => {
        const payload = action.payload as ICreateCompanyResponse;
        state.companies.push(payload.company);
      });

    builder
      .addCase(getCompanyByIdThunk.fulfilled, (state, { payload }) => {
        state.isCompanyLoading = false;
        if (payload) {
          state.currentCompany = payload;
        }
      })
      .addCase(getCompanyByIdThunk.pending, (state) => {
        state.isCompanyLoading = true;
      });
  },
});

export const { actions, reducer } = companies;
export default companies.reducer;
