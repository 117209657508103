import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';

export const LoadingSkeleton = ({ size = 20 }: { size?: number }) => (
  <Box
    sx={{
      height: 'max-content',
    }}
  >
    {[...Array(size)].map((_, index) => (
      <Skeleton key={index} variant="rectangular" sx={{ my: 1, mx: 1, height: 51 }} />
    ))}
  </Box>
);
