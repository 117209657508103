import { createSlice } from '@reduxjs/toolkit';
import {
  getActiveHedgesThunk,
  getAtWorkHedgesThunk,
  getHedgeByIdThunk,
  getHedgeHistoryThunk,
  getUnhedgedThunk,
  initHedgeThunk,
  setFixedExchangeThunk,
  setOrderFixedExchangeThunk,
  setQuotationThunk,
  setRejectWithMessageThunk,
  setRejectOrderThunk,
} from './thunk';
import { IHedge, IUnhedgedShipment } from '@app/types';

interface IHedgeState {
  activeHedges: IHedge[];
  testHedges: IHedge[];
  atWorkHedges: IHedge[];
  hedgeHistory: IHedge[];
  unhedged: IUnhedgedShipment[];
  currentHedge: IHedge | null;
  isLoading: boolean;
}

const initialState: IHedgeState = {
  activeHedges: [],
  testHedges: [],
  atWorkHedges: [],
  hedgeHistory: [],
  unhedged: [],
  currentHedge: null,
  isLoading: false,
};

const filterHedges = (hedges: any[]) => {
  const filteredHedges = hedges.filter((hedge) => !/test/i.test(hedge.hedge_number));
  const testHedges = hedges.filter((hedge) => /test/i.test(hedge.hedge_number));

  return { filteredHedges, testHedges };
};

const updateHedges = (hedges: IHedge[], payload: IHedge) => {
  return hedges.map((hedge) => (hedge.id === payload.id ? payload : hedge));
};

const removeHedge = (hedges: IHedge[], payload: IHedge) => {
  return hedges.filter((hedge) => hedge.id !== payload.id);
};

const hedging = createSlice({
  name: 'hedgingSlice',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    resetCurrentHedge(state) {
      state.currentHedge = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initHedgeThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentHedge = payload;
      }
    });

    builder.addCase(getHedgeByIdThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.currentHedge = payload;
      }
    });
    builder.addCase(getActiveHedgesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        const { filteredHedges, testHedges } = filterHedges(payload.hedges);
        state.activeHedges = filteredHedges;
        state.testHedges = testHedges;
      }
    });
    builder.addCase(getActiveHedgesThunk.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(getAtWorkHedgesThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload?.hedges) {
        state.atWorkHedges = payload.hedges;
      }
    });
    builder.addCase(getAtWorkHedgesThunk.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(getHedgeHistoryThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.hedgeHistory = payload.hedges;
      }
    });
    builder.addCase(getHedgeHistoryThunk.pending, (state, { payload }) => {
      state.isLoading = true;
    });
    builder.addCase(getUnhedgedThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload) {
        state.unhedged = payload.hedges;
      }
    });
    builder.addCase(getUnhedgedThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setQuotationThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.activeHedges = updateHedges(state.activeHedges, payload);
        state.testHedges = updateHedges(state.testHedges, payload);
      }
    });
    builder.addCase(setRejectOrderThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.activeHedges = removeHedge(state.activeHedges, payload);
        state.testHedges = removeHedge(state.testHedges, payload);
      }
    });
    builder.addCase(setRejectWithMessageThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.activeHedges = removeHedge(state.activeHedges, payload);
        state.testHedges = removeHedge(state.testHedges, payload);
        state.atWorkHedges = removeHedge(state.atWorkHedges, payload);
      }
    });
    builder.addCase(setFixedExchangeThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.activeHedges = removeHedge(state.activeHedges, payload);
        state.testHedges = removeHedge(state.testHedges, payload);
      }
    });
    builder.addCase(setOrderFixedExchangeThunk.fulfilled, (state, { payload }) => {
      if (payload) {
        state.atWorkHedges = removeHedge(state.atWorkHedges, payload);
      }
    });
  },
});

export const { actions, reducer } = hedging;
export default hedging.reducer;
