import { Box } from '@mui/material';
import { ShipmentStatus } from '@app/types';
import classes from './styles.module.scss';

export const shipmentStatusMap: { [key in ShipmentStatus]: string } = {
  none: 'No Status',
  in_delivery: 'In Delivery',
  delivered: 'Delivered',
  loading: 'Loading',
  completed: 'Completed',
};

export const ShipmentStatusIndicator = ({ status }: { status: ShipmentStatus }) => {
  return (
    <Box className={`${classes[status]} ${classes.status}`}>
      {shipmentStatusMap[status as ShipmentStatus]}
    </Box>
  );
};
