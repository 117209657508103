import { formatDate } from '@app/utils';
import { ContractStatusIndicator, TextWithSubtitle } from '@common/components';
import { GridColDef } from '@mui/x-data-grid-premium';
import { ContractNumber } from '../components/contractNumber';
import { dateSortComparator } from '@app/utils/sortComparators';

interface IGenereateContractsColumns {}

export const generateContractsColumns = () => {
  const columns: GridColDef[] = [
    {
      field: 'deal_status',
      headerName: 'Status',
      renderCell: (params) => <ContractStatusIndicator status={params.value} />,
      minWidth: 70,
      flex: 0.5,
    },
    {
      field: 'seller_contract_number',
      headerName: 'Contract No.',
      renderCell: (params) => (
        <ContractNumber contractNumber={params.value} contractId={params.row.id} />
      ),
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'seller',
      headerName: 'Seller',
      valueGetter: (params) => params.row.seller.name,
      renderCell: (params) => (
        <TextWithSubtitle title={params.row.seller.name} subtitle={params.row.seller.email} />
      ),
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'buyer',
      headerName: 'Buyer',
      valueGetter: (params) => params.row.buyer.name,
      renderCell: (params) => (
        <TextWithSubtitle title={params.row.buyer.name} subtitle={params.row.buyer.email} />
      ),
      minWidth: 140,
      flex: 1,
    },
    {
      field: 'contract_date',
      headerName: 'Contract date',
      valueGetter: (params) => {
        return formatDate(params.value);
      },
      sortComparator: dateSortComparator,
      minWidth: 100,
      flex: 0.5,
    },

    {
      field: 'product',
      headerName: 'Product/Metal',
      renderCell: (params) => <TextWithSubtitle title={params.value} subtitle={params.row.metal} />,
      minWidth: 100,
      flex: 0.5,
    },
    { field: 'formula', headerName: 'Formula', minWidth: 140, flex: 1 },
    {
      field: 'product_weight',
      headerName: 'Product Weight, mt',
      align: 'right',
      headerAlign: 'right',
      minWidth: 100,
      flex: 0.5,
    },
    {
      field: 'total_shipments',
      headerName: 'Shipments',
      align: 'right',
      headerAlign: 'right',
      minWidth: 100,
      flex: 0.5,
    },
  ];

  return { columns };
};
