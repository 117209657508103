import React from 'react';
import { CommonTabs, DataListItem } from '@common/components';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store';
import { FileSelector } from '../fileSelector';
import { PickerWithButtonField } from '../limitFixationDate';
import { InvoiceField } from '../invoiceField';
import { renderHedgingDetailsDrawer, renderValueOrNoneInDrawer } from '@app/utils';
import { IShipment } from '@app/types';

interface ShipmentInfoTabsProps {
  onHandleUpdateShipment: (value: string[] | string, field: string) => void;
  shipment: IShipment;
  selectedTab: number;
  onTabChange: (newValue: number) => void;
}

export const ShipmentInfoTabs: React.FC<ShipmentInfoTabsProps> = ({
  onHandleUpdateShipment,
  shipment,
  selectedTab,
  onTabChange,
}) => {
  const contract = useSelector(
    (state: RootState) => state.accountManager.contracts.currentContract,
  );

  const handleChange = (newValue: number) => {
    onTabChange(newValue);
  };

  const tabs = [
    {
      title: 'Seller',
      content: (
        <>
          {shipment && (
            <>
              <DataListItem title="Company Name">{contract?.seller.name}</DataListItem>
              <DataListItem title="Company Email">{contract?.seller.email}</DataListItem>
              <DataListItem title="Contract No.">{contract?.seller_contract_number}</DataListItem>
              {shipment?.seller_invoices?.map((invoice, index) => (
                <>
                  <InvoiceField
                    key={`invoice_${index}`}
                    dealId={contract?.id}
                    shipmentId={shipment?.id}
                    role="seller"
                    invoice={invoice}
                  />
                </>
              ))}
              <DataListItem title="Invoice">
                <InvoiceField
                  key="seller"
                  dealId={contract?.id}
                  shipmentId={shipment?.id as string}
                  role="seller"
                />
              </DataListItem>
              <DataListItem title="Limit Fixation Date">
                <PickerWithButtonField
                  key="seller"
                  initialDate={shipment?.seller_limit_fixation_date}
                  onHandleUpdateShipment={(date) => {
                    onHandleUpdateShipment(date, 'seller_limit_fixation_date');
                  }}
                />
              </DataListItem>
              <DataListItem title="Other Files">
                <FileSelector
                  key="seller"
                  dealId={contract?.id}
                  folder={`${shipment.id}-seller_other_files`}
                  title="Other Files"
                  type="files"
                  fileIdsProp={shipment?.seller_other_files}
                  onHandleUpdateShipment={(filesIds) => {
                    onHandleUpdateShipment(filesIds, 'seller_other_files');
                  }}
                />
              </DataListItem>
              <DataListItem title="Hedging">
                {renderHedgingDetailsDrawer(shipment?.seller_hedge)}
              </DataListItem>
              <DataListItem title="Shipment Value, USD">
                {renderValueOrNoneInDrawer(shipment?.seller_shipment_value)}
              </DataListItem>
            </>
          )}
        </>
      ),
    },
    {
      title: 'Buyer',
      content: (
        <>
          {shipment && (
            <>
              <DataListItem title="Company Name">{contract?.buyer.name}</DataListItem>
              <DataListItem title="Company Email">{contract?.buyer.email}</DataListItem>
              <DataListItem title="Contract No.">{contract?.buyer_contract_number}</DataListItem>
              {shipment?.buyer_invoices?.map((invoice, index) => (
                <>
                  <InvoiceField
                    key={`invoice_${index}`}
                    dealId={contract?.id}
                    shipmentId={shipment?.id}
                    role="buyer"
                    invoice={invoice}
                  />
                </>
              ))}
              <DataListItem title="Invoice">
                <InvoiceField
                  key="buyer"
                  dealId={contract?.id}
                  shipmentId={shipment?.id as string}
                  role="buyer"
                />
              </DataListItem>
              <DataListItem title="Limit Fixation Date">
                <PickerWithButtonField
                  key="buyer"
                  initialDate={shipment?.buyer_limit_fixation_date}
                  onHandleUpdateShipment={(date) => {
                    onHandleUpdateShipment(date, 'buyer_limit_fixation_date');
                  }}
                />
              </DataListItem>
              <DataListItem title="Other Files">
                <FileSelector
                  key="buyer"
                  type="files"
                  dealId={contract?.id}
                  folder={`${shipment.id}-buyer_other_files`}
                  title="Other Files"
                  fileIdsProp={shipment?.buyer_other_files}
                  onHandleUpdateShipment={(filesIds) => {
                    onHandleUpdateShipment(filesIds, 'buyer_other_files');
                  }}
                />
              </DataListItem>
              <DataListItem title="Hedging">
                {renderHedgingDetailsDrawer(shipment?.buyer_hedge)}
              </DataListItem>
              <DataListItem title="Shipment Value, USD">
                {renderValueOrNoneInDrawer(shipment?.buyer_shipment_value)}
              </DataListItem>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <CommonTabs
      tabs={tabs}
      selectedTab={selectedTab}
      onTabChange={(newValue: number) => handleChange(newValue)}
    />
  );
};
