import httpClient from '@app/client/httpClient';
import { IHedge, IUnhedgedShipment } from '@app/types';
import { ICreateHedge, ISetHedgeQuotation, ISetQuotationAnswer } from '@app/types/api';

const API_ADMIN_HEDGES = '/api/admin/hedges';
const API_USER_HEDGE = '/api/user/hedge';

export const getHedgeById = (hedgeId: string) => {
  return httpClient.get<IHedge>(`${API_ADMIN_HEDGES}/${hedgeId}`);
};

export const killHedge = (hedgeId: string) => {
  return httpClient.post<IHedge>(`${API_ADMIN_HEDGES}/${hedgeId}/reject`, {});
};

export const killHedgeWithMessage = (hedgeId: string, message?: string) => {
  return httpClient.post<IHedge>(`${API_ADMIN_HEDGES}/${hedgeId}/reject`, { message });
};

export const createAdminHedge = (requestData: ICreateHedge) => {
  return httpClient.post<IHedge>(API_ADMIN_HEDGES, requestData);
};

export const getActiveHedges = () => {
  return httpClient.get<IHedge[]>(`${API_ADMIN_HEDGES}/active`);
};

export const getAtWorkHedges = () => {
  return httpClient.get<IHedge[]>(`${API_ADMIN_HEDGES}/at_work`);
};

export const getHedgeHistory = () => {
  return httpClient.get<IHedge[]>(`${API_ADMIN_HEDGES}/history`);
};
export const getUnhedged = () => {
  const API_ADMIN_UNHEDGED = 'api/admin/shipments/unhedged';
  return httpClient.get<IUnhedgedShipment[]>(API_ADMIN_UNHEDGED);
};

export const setQuotation = (quotationData: ISetHedgeQuotation, hedgeId: string) => {
  return httpClient.post<IHedge>(`${API_ADMIN_HEDGES}/${hedgeId}/acm/quotation`, quotationData);
};

export const setFixedExchange = (fixed_exchange: number, hedgeId: string) => {
  return httpClient.post<IHedge>(`${API_ADMIN_HEDGES}/${hedgeId}/fixed`, { fixed_exchange });
};

export const placeOrder = (hedgeId: string) => {
  return httpClient.post<IHedge>(`${API_ADMIN_HEDGES}/${hedgeId}/place`);
};

export const createHedge = (requestData: ICreateHedge) => {
  return httpClient.post<IHedge>(API_USER_HEDGE, requestData);
};

export const getActiveHedge = () => {
  return httpClient.get<IHedge>(`${API_USER_HEDGE}/acm/active`);
};

export const dropActiveHedge = () => {
  return httpClient.post<IHedge>(`api/user/hedge/acm/active/kill`);
};

export const getUserHedgeById = (hedgeId: string) => {
  return httpClient.get<IHedge>(`${API_USER_HEDGE}/${hedgeId}`);
};

export const setAnswerToQuotation = (answerToQuotation: ISetQuotationAnswer) => {
  return httpClient.post<IHedge>(`${API_USER_HEDGE}/acm/quotation`, answerToQuotation);
};

export const rejectOrder = (hedgeId: string) => {
  return httpClient.post<IHedge>(`${API_USER_HEDGE}/${hedgeId}/reject`);
};

export const getUserHedgeHistory = () => {
  return httpClient.get<IHedge[]>(`${API_USER_HEDGE}/history`);
};
