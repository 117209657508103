import hero from './assets/hero.png';
import classes from './styles.module.scss';

export const Hero = () => {
  return (
    <section className={`${classes.hero}`}>
      <main className={`${classes.hero__content} ${classes.content}`} id="scrapex">
        <article className={`${classes.hero__text} ${classes.text}`}>
          <h1>
            Scrap<span>EX</span>
          </h1>
          <h3>Smart Metal Hedging Solution</h3>
          <p>
            Digital metal Hedging platform for all your hedging needs. With Scrap<span>EX</span>,
            fixation option is always yours.
          </p>
          <a href="#contact">
            <button>Get in touch</button>
          </a>
        </article>
        <img src={hero} width="450" />
      </main>
    </section>
  );
};
