import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createAdminHedge,
  getActiveHedges,
  getAtWorkHedges,
  getHedgeById,
  getHedgeHistory,
  getUnhedged,
  killHedge,
  killHedgeWithMessage,
  placeOrder,
  setFixedExchange,
  setQuotation,
} from '@api/hedging';
import { ICreateHedge, ISetHedgeQuotation } from '@app/types/api';
import { RootState } from '@app/store';

export const getHedgeByIdThunk = createAsyncThunk(
  'admin/getHedgeById',
  async (hedgeId: string, thunkAPI) => {
    try {
      const res = await getHedgeById(hedgeId);

      //preventing the update of the current hedge state if another hedge action was triggered
      const currentHedgeId = (thunkAPI.getState() as RootState).hedgingAdmin.hedging.currentHedge
        ?.id;
      if (currentHedgeId === hedgeId || !currentHedgeId) {
        return res.data;
      }
    } catch (error) {
      console.log(error);
    }
  },
);
export const initHedgeThunk = createAsyncThunk(
  'admin/initHedge',
  async (initPayload: ICreateHedge) => {
    try {
      const res = await createAdminHedge(initPayload);

      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const createHedgeThunk = createAsyncThunk(
  'admin/createHedge',
  async (requestData: ICreateHedge, thunkAPI) => {
    try {
      const res = await createAdminHedge(requestData);
      thunkAPI.dispatch(getUnhedgedThunk());

      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getActiveHedgesThunk = createAsyncThunk('admin/getActiveHedges', async () => {
  try {
    const res = await getActiveHedges();
    return { hedges: res.data };
  } catch (error) {
    console.log(error);
  }
});
export const getAtWorkHedgesThunk = createAsyncThunk('admin/getAtWorkHedges', async () => {
  try {
    const res = await getAtWorkHedges();
    return { hedges: res.data };
  } catch (error) {
    console.log(error);
  }
});

export const getHedgeHistoryThunk = createAsyncThunk('admin/getHedgeHistory', async () => {
  try {
    const res = await getHedgeHistory();
    return { hedges: res.data };
  } catch (error) {
    console.log(error);
  }
});

export const getUnhedgedThunk = createAsyncThunk('admin/getUnhedged', async () => {
  try {
    const res = await getUnhedged();
    return { hedges: res.data };
  } catch (error) {
    console.log(error);
  }
});

export const setQuotationThunk = createAsyncThunk(
  'admin/setQuotation',
  async ({ quotationData, hedgeId }: { quotationData: ISetHedgeQuotation; hedgeId: string }) => {
    try {
      const response = await setQuotation(quotationData, hedgeId);
      return response.data;
    } catch (error) {
      console.error('Error setting quotation:', error);
      throw error;
    }
  },
);

export const setFixedExchangeThunk = createAsyncThunk(
  'admin/setFixedExchange',
  async ({ fixed_exchange, hedgeId }: { fixed_exchange: number; hedgeId: string }) => {
    try {
      const response = await setFixedExchange(fixed_exchange, hedgeId);
      return response.data;
    } catch (error) {
      console.error('Error setting fixed LME:', error);
      throw error;
    }
  },
);

export const setOrderFixedExchangeThunk = createAsyncThunk(
  'admin/setOrderFixedExchange',
  async ({ fixed_exchange, hedgeId }: { fixed_exchange: number; hedgeId: string }) => {
    try {
      const response = await setFixedExchange(fixed_exchange, hedgeId);
      return response.data;
    } catch (error) {
      console.error('Error setting fixed LME:', error);
      throw error;
    }
  },
);

export const setRejectOrderThunk = createAsyncThunk(
  'admin/rejectOrder',
  async (hedgeId: string) => {
    try {
      const response = await killHedge(hedgeId);
      return response.data;
    } catch (error) {
      console.error('Error rejecting order:', error);
      throw error;
    }
  },
);
export const setRejectWithMessageThunk = createAsyncThunk(
  'admin/rejectHedgeWithMessage',
  async ({ hedgeId, message = '' }: { hedgeId: string; message?: string }) => {
    try {
      const response = await killHedgeWithMessage(hedgeId, message);
      return response.data;
    } catch (error) {
      console.error('Error rejecting order:', error);
      throw error;
    }
  },
);

export const placeOrderThunk = createAsyncThunk(
  'admin/placeOrderThunk',
  async (hedgeId: string, thunkAPI) => {
    try {
      const res = await placeOrder(hedgeId);
      await thunkAPI.dispatch(getActiveHedgesThunk());
      return res.data;
    } catch (error) {
      console.log(error);
    }
  },
);
